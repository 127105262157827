<template>
  <div id="register">
    <div class="content" v-if="!is_success">
      <div class="mobile" style="margin-bottom: 15px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div class="title" style="margin-bottom: 35px;">
        IDをお忘れの方
      </div>
      <div class="f-title" style="margin-bottom: 0px;">
        登録したユーザネームを入力してください。
      </div>
      <input class="input" placeholder="ユーザーネーム" v-model="nickname"/>
      <div class="msg">{{error_message}}</div>
      <div :class="'btn' + (is_ok_btn?' ok':'')" @click="find_btn">IDを探す</div>
      <div class="link">
        <a @click="go_url('/findPw')">パスワードをお忘れの方</a>
        <span>/</span>
        <a @click="go_url('/register')">新規会員登録</a>
      </div>

    </div>
    <div class="content" v-if="is_success">
      <div class="mobile" style="margin-bottom: 15px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div class="title" style="margin-bottom: 35px;">
        IDをお忘れの方
      </div>
      <div class="f-title" style="margin-bottom: 15px;">
        お客様のIDは
      </div>
      <div class="title" style="margin-bottom: 35px;display: flex;align-items: center;justify-content: center;">
        <img src="../assets/img/google-login-icon.png" v-if="memberObj.google == 1" style="margin-right: 10px;width:30px;"/>
        <img src="../assets/img/line-login-icon.png" v-if="memberObj.line == 1" style="margin-right: 10px;width:30px;"/>
        {{memberObj.email}} です。
      </div>
      <div class="btn ok" @click="go_url('/login')">ログイン</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>IDをお忘れの方</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction } from '@/network/manage'
  import Vue from 'vue'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        nickname: '',
        error_message: '',
        is_success: false,
        memberObj: {}
      }
    },
    computed: {
      is_ok_btn: function() {
        return (this.nickname != '' && this.nickname != null && this.nickname != undefined)
      }
    },
    created() {

    },
    methods: {
      find_btn() {
        if(this.is_ok_btn) {
          this.error_message = ""
          const loading = this.$loading.service()
          const that = this
          postAction("/login/findLoginCode/" + this.nickname).then((res) => {
            loading.close()
            if (res.success) {
              this.is_success = true
              this.memberObj = res.result
            } else {
              if(res.code == 500) {
                this.error_message = "ユーザー情報が見つかりません"
              } else if(res.code == 501){
                this.error_message = "要求が多すぎる。"
              } else if(res.code == 502){
                this.error_message = "不正な要求。"
              } else {
                this.error_message = "不正な要求"
              }
            }
          }).catch(res => {
          })
        }
      },
      go_url(path) {
        this.$router.push({path:path})
      },
      go_home() {
        this.$router.push({path:'/'})
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .title {font-size:20px;}
  .f-title {font-size: 15px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 132px - 80px - 95px);padding:150px 0 0 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;margin-top:16px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:13px;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;font-size:15px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:14px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;align-items: center;justify-content: center;margin-top:20px;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:13px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content h1{font-size:18px;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;}
  .content .thirdLogin img{margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  @media (max-width: 800px) {
    .title {font-size: 18px;}
    .f-title {font-size: 14px;}
  	.content{margin:0px 16px;min-height: calc(100vh - 254px - 99px - 60px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;font-size:14px;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
