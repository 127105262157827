<template>
  <div id="register">
    <div class="content">
      <div class="title pc">
        あなたの3D作品が多くのアーティストを支えます<br/>
        気軽に出品してみましょう！
      </div>
      <div class="title mobile">
        あなたの3D作品が多くの<br/>
        アーティストを支えます<br/>
        気軽に出品してみましょう！
      </div>
      <div class="list">
        <div class="item">
          <h1>01</h1>
          <img src="../assets/img/img_upload_01.png">
          <div class="info">
            <h2 style="font-weight: normal;">作家申請</h2>
            <div>
              申請ボタンをクリックして<br/>
              作家申請をします。
            </div>
          </div>
        </div>
        <img class="jt" src="../assets/img/right_on.png"/>
        <div class="item">
          <h1>02</h1>
          <img src="../assets/img/img_upload_03.png">
          <div class="info">
            <h2 style="font-weight: normal;">利用規約同意</h2>
            <div>
              作家サービスの利用規約を<br/>
              確認し、同意します。
            </div>
          </div>
        </div>
        <img class="jt" src="../assets/img/right_on.png"/>
        <div class="item">
          <h1>03</h1>
          <img src="../assets/img/img_upload_02.png">
          <div class="info">
            <h2 style="font-weight: normal;">作品アップロード</h2>
            <div>
              商品名や価格<br/>
              作品のイメージ画像を登録します。
            </div>
          </div>
        </div>
        <img class="jt" src="../assets/img/right_on.png"/>
        <div class="item">
          <h1>04</h1>
          <img src="../assets/img/img_upload_04.png">
          <div class="info">
            <h2 style="font-weight: normal;">売り上げ金額の入金</h2>
            <div>
              毎月の販売精算書を提供します。<br/>
              精算金額は個人の財布に保管され<br/>
              出金申請が可能です。
            </div>
          </div>
        </div>
      </div>
      <div class="remark">出品するにはアーティスト申請が必要です</div>
      <div class="btn" @click="go_contract">アーティスト申請</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>申請作成者</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'


  export default {
    name: 'Apply',
    components: {
    },
    data() {
      return {

      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
    },
    created() {
      if(this.type == 1) {
        this.$router.replace('/upload').catch(err => {})
      }
    },
    methods: {
      go_contract() {
        this.$router.replace('/contract').catch(err => {})
      },
      go_home() {
        this.$router.replace('/').catch(err => {})
      }
    }
  }
</script>

<style scoped>
  *{font-size:15px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 215px);}
  .content .title{margin:60px 0;font-size:30px;}
  .content .list{width: 100%;display: flex;justify-content: space-between;margin-bottom:50px;align-items: center;}
  .content .jt{flex-shrink: 0;}
  .content .item h1{color:#38C0CE;font-size:26px;margin:6px 0;flex-shrink: 0;}
  .content .item img{width:150px;flex-shrink: 0;}
  .content .item h2{font-size:15px;}
  .content .item .info div{color:#888888;font-size:13px;margin-top:16px;height:63px;}
  .content .btn{background: #38C0CE;border-radius: 2px;width:300px;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;margin:20px 0;cursor: pointer;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  @media (max-width: 800px) {
  	.content{margin:0 16px;}
    .pc{display: none;}
    .content .title{margin:20px 0 40px 0;font-size: 22px;}
    .content .list{flex-direction: column;}
    .content .jt{align-self: flex-start;transform:rotate(90deg);margin:10px 0 10px 90px;}
    .content .item{display: flex;width:100%;align-items: center;}
    .content .item h1{font-size:18px;margin:0 10px;height:70px;}
    .content .item h2{font-size:15px;}
    .content .item img{width:80px;height:80px;margin:0 30px 0 20px;}
    .content .item .info{width:100%;text-align: left;}
    .content .item .info div{margin-top:4px;font-size:12px;}
    .content .btn{width:100%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .mobile{display: none;}
    .nav {width: 800px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:1200px;margin:auto;}
    .mobile{display: none;}
  }
</style>
