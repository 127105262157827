<template>
  <div id="register">
    <div class="content" v-if="!is_success">
      <div class="mobile" style="margin-bottom: 15px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div class="title" style="margin-bottom: 35px;">
        新規会員登録
      </div>

      <input class="input" placeholder="メールアドレス" v-model="email" @input="email_change"/>
      <div :class="'msg' + (check_email_flag?' right_ok':'')">{{error_message}}</div>
      <div :class="'btn' + (is_ok_btn?' ok':'')" @click="find_btn">メールアドレスで会員登録する</div>

      <div class="line"></div>
      <h1>外部アカウントで会員登録</h1>
      <div class="thirdLogin" @click="go_to_google_login">
        <img src="../assets/img/google-login-icon.png"/>
        Google IDで会員登録する
      </div>
      <div class="thirdLogin" @click="go_to_line_login">
        <img src="../assets/img/line-login-icon.png"/>
        LINE IDで会員登録する
      </div>

    </div>
    <div class="content" v-if="is_success">
      <div class="mobile" style="margin-bottom: 15px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 65px;font-size: 20px;">
        認証メールを送信しました
      </div>
      <div class="title pc" style="margin-bottom: 10px;font-size: 15px;width:600px;">
        以下のメールアドレス宛にメールを送信しました。
      </div>
      <div class="title pc" style="margin-bottom: 30px;font-size: 15px;width:600px;">
        メール本文のURLをクリックして会員登録を完了してください。
      </div>

      <div class="title mobile" style="margin-bottom: 10px;font-size: 15px;">
        以下のメールアドレス宛にメールを送信しました。
      </div>
      <div class="title mobile" style="margin-bottom: 30px;font-size: 15px;">
        メール本文のURLをクリックして会員登録を完了してください。
      </div>

      <div class="btn" style="background:rgba(236, 249, 251, 1);color:#000000;">{{this.email}}</div>

      <div class="title" style="margin-top: 20px;font-size: 13px;color:#888888;">
        認証URLの有効期限は30分です。
      </div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>新規会員登録</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction,getAction } from '@/network/manage'
  import Vue from 'vue'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        email: '',
        error_message: '',
        is_success: false,
        memberObj: {},
        emailReg: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
        timer: null,
        check_email_flag: false
      }
    },
    computed: {
      is_ok_btn: function() {
        return this.check_email_flag
      }
    },
    created() {

    },
    methods: {
      email_change(e) {
        this.check_email_flag = false
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.checke_email(e.target.value)
        }, 500);
      },
      async checke_email(email) {
        if(!email || (email  && !this.emailReg.test(email))) {
          this.error_message = "メールアドレスの形式が正しくありません"
          this.check_email_flag = false
        } else {
          let res = await getAction("/login/checkEmailToReginRedis?email=" + email)
          if(res.success) {
            this.error_message = "登録可能なメールアドレスです。"
            this.check_email_flag = true
          } else {
            this.error_message = "このメールアドレスはすでに登録されています。"
            this.check_email_flag = false
          }
        }
      },
      find_btn() {
        if(this.is_ok_btn) {
          this.error_message = ""
          const loading = this.$loading.service()
          const that = this
          let param = {email: this.email}
          postAction("/login/signupSendEmail",param).then((res) => {
            loading.close()
            if (res.success) {
              this.is_success = true
            } else {
              if(res.code == 500) {
                this.error_message = "このメールアドレスはすでに登録されています。"
              } else if(res.code == 501){
                this.is_success = true
              } else {
                this.error_message = "不正な要求"
              }
            }
          }).catch(res => {
          })
        }
      },
      go_url(path) {
        this.$router.push({path:path})
      },
      go_home() {
        this.$router.push({path:'/'})
      },
      go_to_google_login() {
        window.location.href = process.env.VUE_APP_GOOGLE_LOGIN_URL + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_CLIENT + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_REDIRECT + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_OTHER
      },
      go_to_line_login() {
        window.location.href = process.env.VUE_APP_LINE_LOGIN_URL
        + process.env.VUE_APP_LINE_LOGIN_PARAM_TYPE
        + process.env.VUE_APP_LINE_LOGIN_PARAM_CLIENT
        + process.env.VUE_APP_LINE_LOGIN_PARAM_REDIRECT
        + process.env.VUE_APP_LINE_LOGIN_PARAM_OTHER
        + "&nonce=" + new Date().getTime()
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .title {font-size:20px;}
  .f-title {font-size: 15px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 132px - 80px - 95px);padding:50px 0 0 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;margin-top:16px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:13px;}
  .right_ok {color: rgba(1, 56, 139, 0.89) !important;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;font-size:15px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:14px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;align-items: center;justify-content: center;margin-top:20px;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content h1{font-size:15px;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;cursor: pointer;font-size: 15px;}
  .content .thirdLogin img{margin-right:10px;width:25px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{margin:0px 16px;min-height: calc(100vh - 300px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;font-size:14px;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
    .title {font-size: 18px;}
    .f-title {font-size: 14px;}
    .content .thirdLogin {font-size: 14px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
