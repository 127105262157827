<template>
  <div id="register">
    <div class="content" v-if="!isResult">
      <div class="title">
        作家サービスの利用規約
      </div>
      <div class="con">
        第1条 目的<br/>
        本利用規約は、株式会社METAPIA(以下「当社」という)が提供する、3ditem(www.3d-item.com)サービス(以下「「本サービス」という)を利用するにあたり、当社と利用者の権利と義務、その他必要な事項を定めることを目的とする。<br/><br/>

        第2条 定義<br/>
        本規約において用いられる次の用語は、以下に定める意味で使用する。<br/>
        ①「会員」とは、利用者のうち本サービスに個人情報を提供して会員登録した会員をいい、本規約に従って当社が提供する本サービスを利用する者をいう。<br/>
        ②「ID」とは、会員識別と本サービス利用のために会員が申請し当社が承認した文字と数字の組み合わせをいう。<br/>
        ③「パスワード」とは、会員が承認されたIDと一致した会員であることを確認し、会員のパスワード保護のために会員自身が設定した文字と数字の組み合わせをいう。<br/>
        ④「作家」とは、本サービスあるいは当社に作家登録をした個人または団体で、当社が提供する本サービスを通じて3Dソースを提供する者をいう。<br/><br/>

        第3条 規約外規則<br/>
        運営者は必要な場合、別途運営ポリシーを公示し案内でき、本規約と運営ポリシーが重なる場合、運営ポリシーが優先的に適用される。<br/><br/>

        第4条 作家登録および利用申請<br/>
        ① 本サービスを利用しようとする作家は、作品登録時に当社で定めた方法で作家として申し込み、本サービスが要求するすべての情報（利用者ID、パスワード、ニックネームなど）を登録し、当社との相互協議過程を経て承認可否を作家に個別通知(本サービス経由のお知らせを含む)することによって成立する。<br/>
        ② 他人の名義やID、パスワードを盗用して利用申請をした作家のIDは削除され、関係法令により処罰を受けることができる。<br/>
        ③ 当社は第1項で成立した作家のうち、次の各号のいずれかに該当する場合には強制的に作家契約を解約することができる。<br/>
        　　1) 登録者が実名でない場合、他人の名義及び情報を利用した場合<br/>
        　　2) 虚偽情報を記載又は当社が提示する内容を提供しない場合<br/>
        　　3) その他本規約に違反する行為、違法行為または不正利用であることが確認された場合、または作家の帰責事由により当社が承諾できない場合<br/><br/>

        第5条 販売手数料および販売代金の出金申請<br/>
        ① 作家は商品を登録して取引が成立した場合、作品の販売価格に40%の料率をかけた販売手数料を当社に支払わなければならない。 当社の加盟店の場合、加盟店契約により当社が販売代金を代理受領し販売手数料を差し引く方式で徴収される。 作家はこのような方法で徴収されることに同意する。 <br/>
        ② 作家は[マイページ]から販売代金を引き出すことができる。 ただし、引き出し申請時の最小引き出し金額は1万円以上の場合のみ可能である。申請金額は申請月の翌月末日までに一括支給される。<br/>
        ③ 源泉徴収<br/>
        会員は、商品または本サービスの購入および使用に関連して発生する消費税またはその他の政府税金、手数料または関税（以下、総称して「税金」という）に対する責任がある。 当社は会員から税金を源泉徴収する義務があると判断される場合、当該税金を控除後支給する。<br/><br/>

        第6条 投稿物の著作権<br/>
        ① 作家が本サービス内に投稿した投稿物の著作権は、投稿した作家に帰属する。また、本サービスは会員の承諾なしに商業目的で投稿物を利用することはできない。ただし非営利目的の場合はこれに該当せず、また本サービス内での掲載権を有する。 <br/>
        ② 運営者は、作家が投稿または登録する本サービス内のコンテンツ、投稿内容について、第4条の各号に該当すると判断される場合、事前通知なしに削除、移動、または登録拒否を行うことがある。<br/><br/>

        第7条 その他<br/>
        本規約に定めのない事項については、サービス利用規約に従う。<br/><br/>

        初版：2023年 8月 1日<br/>
        最終改定：2024年 1月 2日<br/>
      </div>
      <div class="pc">
        <div class="check"><input type="checkbox" @click="changeCheck"/>以上の利用規約を確認し、内容に同意します。 <a>必須</a></div>
        <div :class="isCheck?'btn ok':'btn'" @click="ty">同意する</div>
      </div>
      <div class="mobile">
        <!-- <div style="color:#EC2222;margin-top:35px;text-align: right;">必須</div> -->
        <div class="check"><input type="checkbox" @click="changeCheck"/>以上の利用規約を確認し、内容に同意します。 </div>
        <div :class="isCheck?'btn ok':'btn'" @click="ty">同意する</div>
      </div>
    </div>
    <div class="content" v-if="isResult">
      <div class="title">
        作家申請が完了しました。<br/>
        作品をアップロードして3D-ITEMアーティストとして活動してください。
      </div>
      <div class="btn ok" @click="go_upload">アップロード</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>申請作成者TOP</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction } from '@/network/manage'

  export default {
    name: 'Contract',
    components: {
    },
    data() {
      return {
        isCheck:false,
        isResult:false
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
    },
    created() {
      if(this.type == 1) {
        this.$router.replace('/upload').catch(err => {})
      }
    },
    methods: {
      ...mapActions([ "GetUserInfo"]),
      changeCheck(e){
        this.isCheck = e.target.checked
      },
      go_home() {
        this.$router.replace('/').catch(err => {})
      },
      go_upload() {
        this.$router.replace('/upload').catch(err => {})
      },
      ty(){
        if(this.isCheck) {
          const loading = this.$loading.service()
          postAction("/user/member/applyBecomeCreator").then((res) => {
            loading.close()
            if (res.success) {
              //reload member info
              this.GetUserInfo()
              document.documentElement.scrollTop = 0
              document.body.scrollTop = 0
              this.isResult = true
            } else {
              loading.close()
              this.$message.error(res.message);
            }
          }).catch(res => {
            loading.close()
          })
        }
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 215px);}
  .content .title{margin:50px 0;font-size:20px;line-height: 1.6;}
  .content .con{text-align: left;}
  .content .check{display: flex;margin:40px 0 20px 0;align-items: center;font-size:15px;}
  .content .check input{width:20px;height:20px;margin-right:5px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .check a{color:#EC2222;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:300px;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;margin:20px auto;font-size:15px;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  @media (max-width: 800px) {
  	.content{margin:0 16px;min-height: calc(100vh - 323px);}
    .pc{display: none;}
    .content .title{margin:30px 0 30px 0;font-size:18px;}
    .content .btn{width:100%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .mobile{display: none;}
    .nav {width: 800px;margin: auto;}
  }
  @media(min-width: 1200px) {
  	.content{width:1200px;margin:auto;}
    .mobile{display: none;}
    .nav {width: 1200px;margin: auto;}
  }
</style>
