import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getAction } from '@/network/manage'

const cart = {
  state: {
    number: 0
  },

  mutations: {
    SET_NUMBER: (state, number) => {
      state.number = number
    }
  },

  actions: {
    UpdateCartNumber({ commit }) {
      const token = Vue.ls.get(ACCESS_TOKEN)
      if(token !== null && token !== '') {
        getAction('/user/member/searchShoppingCartNumber').then(res => {
          if(res.success) {
            commit('SET_NUMBER', res.result)
          }
        }).catch(res => res)
      }
    }
  }
}

export default cart
