<template>
  <div id="register">
    <div class="win" v-if="winForm">
      <div class="con" v-loading="w_loading">
        <div class="tls"><div>出金内訳</div><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
        <div class="remark">* 出金失敗時はadmin@3d-item.comにお問い合わせください</div>
        <div class="items">
          <div class="item header">
            <span>日付</span>
            <span>区分</span>
            <span class="pc">申請金額</span>
            <span>送金額</span>
            <span class="pc">内訳確認</span>
          </div>
          <div style="overflow: auto;height:300px;">
            <div class="item" v-for="item,index of w_datas">
              <span>{{item.requestedDatetimeShowMember}}</span>
              <span v-html="status_html(item.transactionStatus)"></span>
              <span class="pc">{{formatNumber(item.requestMoneyAmount)}} JPY</span>
              <span v-if="item.sentMoneyAmount != null">{{formatNumber(item.sentMoneyAmount)}} {{get_rate_type(item.sentMoneyCurrency)}}</span>
              <span v-else>-</span>
              <span class="pc">
                <a class="blue" v-if="item.sentReceiptUrl" @click="down_load_file(item.sentReceiptUrl)">送金確認書</a>
                <label v-if="item.sentReceiptUrl && item.taxUploadUrl">|</label>
                <a class="blue" v-if="item.taxUploadUrl" @click="down_load_file(item.sentReceiptUrl)">税金領収書</a>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="win" v-if="winForm_detail">
      <div class="con" v-loading="w_loading">
        <div class="tls"><div>販売詳細内訳</div><img @click="winForm_detail = false" src="../assets/img/close_bk.png"/></div>
        <div class="remark"></div>
        <div class="items">
          <div class="item header">
            <span>日付</span>
            <span style="width:200%">作品名</span>
            <span class="pc">売上高</span>
            <span class="pc">手数料</span>
            <span style="width:100%">精算金額</span>
          </div>
          <div style="overflow: auto;height:300px;">
            <div class="item" v-for="item,index of detailData">
              <span>{{item.soldDatetime}}</span>
              <span style="width:200%" :title="item.contentTitle">{{item.contentTitle}}</span>
              <span class="pc">{{formatNumber(item.itemPrice)}}</span>
              <span class="pc">{{formatNumber(item.serviceFeePrice)}}</span>
              <span style="width:100%" >{{formatNumber(item.netPrice)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="win" v-if="winForm2">
      <div class="con con2">
        <div class="tls"><div>出金申請</div><img @click="winForm2 = false" src="../assets/img/close_bk.png"/></div>
        <div class="flexWin">
          <span>出金可能金額</span>
          <a>{{formatNumber(sum_data.canMoney)}}</a>
          <label>JPY</label>
        </div>
        <div class="flexWin">
          <span>出金申請金額</span>
          <input placeholder="出金額入力" v-model="tx_money" @input="change_tx_money"/>
          <label>JPY</label>
        </div>
        <div class="flexWin" style="color:rgba(236, 34, 34, 1);">{{error_message}}</div>
        <div class="info">
          <div class="empty" v-if="!sum_data.userBankCountry">
            <a>入金口座情報を登録してください</a>
            <div class="btn" @click="tz('prifileEdit')">登録する</div>
          </div>
          <div class="details" v-else>
            <h1>入金口座情報</h1>
            <p>名前(口座所有者) : {{sum_data.bankAccountHolder}}</p>
            <p>国家 : {{get_bank_country(sum_data.userBankCountry)}}</p>
            <p>銀行名 : {{sum_data.bankName}}</p>
            <p v-if="sum_data.userBankCountry == 'JPN'">口座種類 : {{sum_data.bankAccountType==1?'当座預金':'普通預金'}}</p>
            <p v-if="sum_data.userBankCountry != 'JPN'">支店名 : {{sum_data.bankBranchName}}</p>
            <p v-if="sum_data.userBankCountry != 'JPN'">支店の住所 : {{sum_data.bankBranchAddress}}</p>
            <p v-if="sum_data.userBankCountry != 'JPN'">SWIFT CODE : {{sum_data.bankSwiftCode}}</p>
            <p>口座番号 : {{sum_data.bankAccountNumber}}</p>
            <p>取引通貨 : {{get_rate_type(sum_data.transactionCurrency)}}</p>
          </div>
        </div>
        <div class="remarkInfo">
          <h2>注意事項</h2>
          <div class="desc">
            <p>* 外国通貨で入金される場合、登録された口座国家の取引通貨に両替して送金し、申請日基準で月末の為替レートが適用されます。</p>
            <p>* 韓国、中国、台湾、タイ、フランス国家の銀行口座に送金される場合、租税条約に基づき源泉税(金額の10%)を差し引いて送金され、出金内訳上で税金領収書をダウンロードすることができます。</p>
            <p>* 出金申請金額は申請月基準の翌月末日付で一括支給されます。</p>
            <p>* 出金日が祝日の場合、平日を基準に事後支給されます。</p>
            <p>* 出金内訳から照会が可能です。</p>
          </div>
          <div class="checkDiv">
            <input type="checkbox" @click="changeCheck"/>
            以上の内容を確認し、出金を申請します。
          </div>
          <div :class="(tx_is_check ? 'ok' : '') + ' submit'" @click="tx_app" style="cursor: pointer;">申請する</div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="title">
        販売管理
      </div>
      <div class="con">
        <div class="menus pc">
          <span @click="tz('/salesManage')">作品管理</span>
          <span @click="tz('/salesData')">販売状況</span>
          <span @click="tz('/salesJs')" class="act">精算管理</span>
        </div>
        <div class="right">
          <div class="flex" style="align-items: flex-end;">
            <div class="tjItem">
              <span>私の財布</span>
              <div v-loading="sum_loading">
                <b>{{formatNumber(sum_data.canMoney)}}</b> JPY
              </div>
            </div>
            <div class="edit">
              <div style="display: flex;">
                <div class="rightInfo" style="cursor: pointer;" @click="show_tx_win()">
                  <img src="../assets/img/user-level-icon.png"/>
                  出金申請
                </div>
                <div class="rightInfo" style="cursor: pointer;" @click="show_widrwal_detail">
                  <img src="../assets/img/paper-list.png"/>
                  出金内訳
                </div>
              </div>
              <div style="font-size:14px;margin-top:10px;">* 毎月の販売精算書は翌月第1週に発行され、精算完了後に出金申請が可能です。</div>
            </div>
          </div>

          <div class="list">
            <div class="item header">
              <span>精算月</span>
              <span>状態</span>
              <span class="pc">売上高</span>
              <span class="pc">手数料</span>
              <span>精算金額</span>
              <span class="pc">ダウンロード</span>
            </div>
            <div class="item" v-for="item,index of datas" :key="item.settlementMonth">
              <span>{{item.settlementMonth}}</span>
              <span >精算完了</span>
              <span class="pc">{{formatNumber(item.soldTotalPrice)}}</span>
              <span class="pc">{{formatNumber(item.soldTotalServiceFee)}}</span>
              <span>{{formatNumber(item.settlementMoney)}}</span>
              <span class="blue pc">
                <a class="blue">精算書</a>
                <label style="margin:0 10px;">|</label>
                <a class="blue" @click="show_detail(item.settlementMonth)">販売詳細内訳</a>
              </span>
            </div>
            <div class="moreBtn" v-loading="listLoading" v-if="!isEnd" style="cursor: pointer;" @click="next_page">+ もっと見る</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>精算管理</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getAction,postAction } from '@/network/manage'
  import numeral from 'numeral'
  import { saveAs as FileSaver } from 'file-saver'

  export default {
    name: 'SalesJs',
    components: {
    },
    data() {
      return {
        winForm:false,
        winForm2:false,
        winForm_detail: false,
        isLogin:false,
        isChecked:false,
        isEnd: false,
        datas:[],
        sum_data:{},
        pageSize:10,
        pageNo:1,
        listLoading:false,
        sum_loading:false,
        w_datas:[],
        w_loading:false,
        tx_money_check: false,
        error_message: '',
        tx_money: undefined,
        detailData: []
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      tx_is_check() {
        return this.isChecked && this.tx_money_check && this.sum_data.userBankCountry
      },
      formatNumber() {
        return function(price) {
          return numeral(price).format('0,0')
        }
      },
    },
    created() {
      if(this.type != 1) {
        this.$router.replace('/apply').catch(err => {})
      }
      this.loadData()
      this.sum_load_data()
    },
    methods: {
      async show_detail(time) {
        this.w_loading = true
        this.winForm_detail = true
        this.detailData = []
        let res = await getAction("/user/member/searchMonthlySettlementDetail?time=" + time);
        if(res.success) {
          this.detailData = res.result
        }
        this.w_loading = false
      },
      change_tx_money() {
        if(this.tx_money) {
            if(isNaN(parseInt(this.tx_money))) {
                this.tx_money = ''
                return;
            }
            this.tx_money = parseInt(this.tx_money).toFixed(0)
            if(this.tx_money < 10000) {
              this.error_message = "10,000 JPY以上から申請が可能です。"
              this.tx_money_check = false
              return;
            }
            if(this.tx_money > this.sum_data.canMoney) {
              this.tx_money = this.sum_data.canMoney
            }
            this.error_message = ""
            this.tx_money_check = true
        } else {
          this.error_message = ""
          this.tx_money_check = false
        }
      },
      tx_app() {
        if(this.tx_is_check) {
          let that = this
          let param = {money: this.tx_money}
          const loading = this.$loading.service()
          postAction("/user/member/appWithdrawal", param).then(res => {
            if (res.success) {
              that.$message.success("申請の発行に成功しました")
              this.winForm2 = false
              that.sum_load_data()
            } else {
              that.$message.error(res.message)
            }
          }).catch(err => {
            that.$message.error(err.message)
          }).finally(() => {
            loading.close()
          })
        }
      },
      get_bank_country(country) {
        if(country == 'JPN') {
          return "日本";
        } else if(country == 'USA') {
          return "アメリカ";
        } else if(country == 'CHN') {
          return "中国";
        } else if(country == 'KOR') {
          return "韓国";
        } else if(country == 'EUR') {
          return "ヨーロッパ";
        } else {
          return "日本";
        }
      },
      show_tx_win() {
        this.tx_money = undefined
        this.tx_money_check = false
        this.error_message = ""
        this.isChecked = false
        this.winForm2 = true
      },
      down_load_file(url) {
        let filename = url.substring(url.lastIndexOf('/') + 1);
        const oReq = new XMLHttpRequest()
        const URL= url
        oReq.open('GET', URL, true)
        oReq.responseType = 'blob'
        oReq.onload = function() {
          const file = new Blob([oReq.response], {
            type: 'blob'
          })
          FileSaver.saveAs(file, filename)
        }
        oReq.send()
      },
      status_html(status) {
        if(status === 0) {
          return "<font>出金申請</font>"
        } else if(status === 1) {
          return "<font style='color:rgba(56, 192, 206, 1);'>出金完了</font>"
        } else if(status === 2) {
          return "<font style='color:rgba(236, 34, 34, 1);'>出金失敗</font>"
        }
      },
      get_rate_type(type) {
        //0:JPY,1:KRW,2:CNY,3.USD,4:EUR
        if(type == 0) {
          return "JPY";
        } else if(type == 1) {
          return "KRW";
        } else if(type == 2) {
          return "CNY";
        } else if(type == 3) {
          return "USD";
        } else if(type == 4) {
          return "EUR";
        } else {
          return "JPY";
        }
      },
      show_widrwal_detail() {
        this.winForm = true
        this.w_loading = true
        const that = this
        this.w_datas = []
        let param = {pageNo:1, pageSize:5000}
        getAction("/user/member/withdrawalRecordsList",param).then(res => {
          if (res.success) {
            this.w_datas = res.result.records
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          this.w_loading = false
        })
      },
      next_page() {
        this.pageNo = this.pageNo + 1
        this.loadData()
      },
      sum_load_data() {
        this.sum_loading = true
        const that = this
        getAction("/user/member/personalBasicInfo").then(res => {
          if (res.success) {
            this.sum_data = res.result
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          this.sum_loading = false
        })
      },
      loadData() {
        this.listLoading = true
        const that = this
        let param = {pageNo: this.pageNo,pageSize:this.pageSize}
        getAction("/user/member/monthSettlementReceiptList",param).then(res => {
          if (res.success) {
            this.datas = this.datas.concat(res.result.records)
            if(this.pageNo >= res.result.pages) {
              this.isEnd = true
            }
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          this.listLoading = false
        })
      },
      changeCheck(e){
        this.isChecked = e.target.checked
      },
      tz(url){
        this.$router.push(url)
      }
    }
  }
</script>

<style scoped>
  *{font-size:14px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{min-height: calc(100vh - 215px);}
  .content .title{margin:30px 0 10px 0;font-size:26px;font-weight: bold;}
  .content .con{display: flex;}
  .con .menus{margin-right:40px;display: flex;flex-shrink: 0;flex-direction: column;}
  .con .menus span{margin:14px 0;font-size:20px;color: rgba(1, 56, 139, 0.46);cursor: pointer;}
  .con .menus span:hover{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .menus .act{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .right{width:100%;display: flex;flex-direction: column;align-items: flex-end;}
  .con .right .rightInfo{display: flex;margin-bottom:6px;align-items: center;background: #F2F4F6;padding:4px 20px;font-size:14px;margin-left:20px;}
  .con .right .rightInfo img{margin-right:6px;width: 25px;}
  .con .right .list{width:100%;}
  .con .right .list .item{display: flex;}
  .blue{color: rgba(1, 56, 139, 0.89);cursor: pointer;}
  .blue2{color: #38C0CE;}
  .red{color: #EC2222;}
  .con .right .list .header{border-top:2px solid rgba(72, 83, 100, 0.46);border-bottom:2px solid rgba(72, 83, 100, 0.46);margin-top:10px;}
  .con .right .list .item span{width:100%;display: flex;justify-content: center;align-items: center;padding:14px 0;text-align: center;}
  .con .right .list .item span:nth-child(6){width:200%;}
  .con .right .list .item span img{margin:0 10px;cursor: pointer;}
  .moreBtn{margin:20px auto;width:130px;height:36px;background: #ECF9FB;color:#38C0CE;border-radius: 40px;display: flex;justify-content: center;align-items: center;font-size:14px;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;width:900px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px;}
  .win .con .items{width:100%;max-height:400px;overflow-y: auto;}
  .win .con .remark{color:#EC2222;text-align: left;width:100%;}
  .win .con *{font-size:14px;}
  .win .con .header{border-bottom:2px solid rgba(72, 83, 100, 0.46);}
  .win .con .header *{font-size:16px !important; font-weight: bold;}
  .win .con .item{width:100%;display: flex;padding:10px 0;}
  .win .con .item span{width:100%;align-items: center;display: flex;justify-content: center;}
  .win .con .item span:nth-child(5){width:200%;}
  .win .con .item span label{margin:0 6px;}
  .win .con .tls img{width:20px;cursor: pointer;}
  .win .con .tls{margin-bottom:20px;display: flex;justify-content: space-between;align-items: center;width:100%;}
  .win .con .tls div{font-size:20px;font-weight: bold;}
  .win .con .tls span{font-weight: normal;font-size:16px;}
  .win .con p{width:100%;}
  .win .con2{padding:30px 0 !important;width:500px !important;}
  .win .con2 .tls{padding:0 30px;}
  .win .con2 .info{background:rgba(217, 217, 217, 0.2);width:100%;padding:30px;margin:20px 0;}
  .win .con2 .info .empty{padding:30px 0;display: flex;flex-direction: column;align-items: center;}
  .win .con2 .info .empty a{color:#01388B;font-size:18px;margin-bottom:20px;}
  .win .con2 .info .empty .btn{background: #38C0CE;border-radius: 2px;width:150px;height:36px;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;}
  .win .con2 .info .details h1{color:#38C0CE;margin-bottom:10px;font-weight: bold;font-size:18px;}
  .win .con2 h2{color:#38C0CE;margin-bottom:10px;font-weight: bold;font-size:18px;text-align: center;line-height:1;}
  .win .con2 .desc p{font-size:12px;color:#01388B;padding:0 30px;}
  .win .con2 .checkDiv{display: flex;align-items: center;justify-content: center;margin:20px 0;}
  .win .con2 .checkDiv input{width:20px;height:20px;margin-right:6px;}
  .win .con2 .submit{background: #D0D0D0;border-radius: 2px;color:#ffffff;display: flex;justify-content: center;align-items: center;width:80%;height:46px;margin:auto;}
  .win .con2 .ok{background-color: #38C0CE !important;}
  .flexWin{padding:0 30px;display: flex;align-items: center;width:100%;margin-top:10px;}
  .flexWin span{color:#888888;font-weight: bold;margin-right:10px;}
  .flexWin a{width:100px;color:#01388B;font-weight: bold;display: flex;justify-content: center;}
  .flexWin input{width:100px;color:#01388B;font-weight: bold;border:none;height:30px;border-bottom:1px solid rgba(1, 56, 139, 0.46);text-align: center;}
  .flexWin input::-webkit-input-placeholder{font-weight: normal;color:rgba(1, 56, 139, 0.46);}
  .flexWin label{margin-left:10px;color:#01388B;font-weight: bold;}
  .flex{display: flex;align-items: center;width:100%;margin-bottom:20px;justify-content:space-between;}
  .edit{display: flex;align-items: flex-end;flex-direction: column;}
  .flex select{width:100%;margin-right:20px;border:none;border-bottom:1px solid #aaaaaa;height:40px;}
  .flex label{margin-right:20px;}
  .flex a{background: #38C0CE;flex-shrink: 0;border-radius: 2px;color:#ffffff;width:56px;height:30px;display: flex;align-items: center;justify-content: center;color:#ffffff;cursor: pointer;}
  .flex .tjItem{width:24%;background: #FFFFFF;border-radius: 30px;box-shadow:0px 3px 5px rgba(0, 0, 0, 0.25);padding:14px 20px;display: flex;flex-direction: column;}
  .flex .tjItem span{color:#888888;}
  .flex .tjItem div{display: flex;justify-content: flex-end;align-items: flex-end;line-height: 1;}
  .flex .tjItem div b{color:#38C0CE;font-weight: bold;font-size:30px;line-height: 1;margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  @media (max-width: 800px) {
  	.content{margin:0 16px;min-height: calc(100vh - 323px);}
    .pc{display: none !important;}
    .content .title{margin:10px 0;text-align: center;}
    .content .title{margin:30px 0 30px 0;font-size:18px;}
    .content .btn{width:100%;}
    .win .con2{padding:10px 0 !important;}
    .win .con2 .tls{padding: 0 30px;margin-bottom:10px;}
    .win .con2 .info{padding:10px 30px;margin:10px 0;}
    .win .con .remark{text-align: center;}
    .win{background-color: #ffffff;top:60px;align-items: flex-start;}
    .win .con{height: calc(100vh - 60px);overflow: auto;box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);padding:20px;}
    .con .right .list .item span{padding:6px 0;}
    .flex{flex-direction: column;align-items: center !important;}
    .flex .tjItem{width:60vw;margin-bottom:20px;}
    .edit{justify-content: center;align-items:center;}
    .rightInfo{margin-right:10px;margin-left:10px !important;display: flex;flex-direction: column;padding:10px 20px !important;border-radius:60px;color: #38C0CE;}
    .rightInfo img{margin-right:0 !important;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 800px;margin: auto;}
  }
  @media(min-width: 1200px) {
  	.content{width:1200px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 1200px;margin: auto;}
  }
</style>
