<template>
  <div id="register">
    <div class="win" v-if="winForm">
      <div class="con">
        <div class="close" style="margin-bottom: 20px;"><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
        <div class="tls">あなたのIDがブロックされています</div>
        <div class="tls">何かご要望がありましたら</div>
        <div class="tls">メールにてご連絡ください</div>
        <div class="tls" style="color:rgba(1, 56, 139, 1);text-decoration: underline;margin-top: 25px;">admin@3d-item.com</div>
        <div class="btn" @click="winForm = false">確認</div>
      </div>
    </div>
    <div class="content">
      <div class="mobile" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <input class="input" placeholder="ID（メールアドレス）" v-model="email" autocomplete="off"/>
      <input class="input" placeholder="パスワード" v-model="password" type="password" autocomplete="off"/>
      <div class="msg">{{error_message}}</div>
      <div :class="'btn' + (is_ok_btn?' ok':'')" @click="login_btn">ログイン</div>
      <div class="checkDiv">
        <input type="checkbox" v-model="is_remember"/>
        IDを保存する
      </div>
      <div class="link">
        <a @click="go_url('/findId')">IDをお忘れの方</a>
        <span>/</span>
        <a @click="go_url('/findPw')">パスワードをお忘れの方</a>
        <span>/</span>
        <a @click="go_url('/register')">新規会員登録</a>
      </div>
      <div class="line"></div>
      <h1>外部アカウントでログイン</h1>
      <div class="thirdLogin" @click="go_to_google_login">
        <img src="../assets/img/google-login-icon.png"/>
        Googleアカウントでログイン
      </div>
      <div class="thirdLogin" @click="go_to_line_login">
        <img src="../assets/img/line-login-icon.png"/>
        LINEアカウントでログイン
      </div>




    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>会員ログイン</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'
  import { USER_EMAIL } from '@/store/mutation-types'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        email: '',
        password: '',
        error_message: '',
        is_remember: false,
        winForm: false
      }
    },
    computed: {
      is_ok_btn: function() {
        return (this.email != '' && this.email != null && this.email != undefined && this.password != '' && this.password != null && this.password != undefined)
      }
    },
    created() {
      if(Vue.ls.get(USER_EMAIL)) {
        this.email = Vue.ls.get(USER_EMAIL)
        this.is_remember = true
      }
    },
    methods: {
      ...mapActions([ "Login"]),
      login_btn() {
        if(this.is_ok_btn) {
          this.error_message = ""
          const loginParams = {email: this.email, password: this.password}
          const loading = this.$loading.service()
          const that = this
          this.Login(loginParams).then((res) => {
            loading.close()
            that.loginSuccess()
          }).catch((err) => {
            loading.close()
            if(err.code == 4011){
              this.error_message = "メールアドレスもしくはパスワードが違います。"
            } else if(err.code == 40004){
              this.winForm = true
            } else if(err.code == 4004){
              this.error_message = "このメールアドレスでは登録されていません"
            } else {
              this.error_message = "メールアドレスもしくはパスワードが違います。"
            }
          });
        }
      },
      loginSuccess () {
        if(this.is_remember) {
          Vue.ls.set(USER_EMAIL, this.email)
        } else {
          Vue.ls.remove(USER_EMAIL)
        }
        if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
          this.$router.replace(this.$route.query.redirect)
        } else {
          this.$router.replace('/')
        }
      },
      go_home() {
        this.$router.push({path:'/'})
      },
      go_url(path) {
        this.$router.push({path:path})
      },
      go_to_google_login() {
        window.location.href = process.env.VUE_APP_GOOGLE_LOGIN_URL + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_CLIENT + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_REDIRECT + process.env.VUE_APP_GOOGLE_LOGIN_PARAM_OTHER
      },
      go_to_line_login() {
        window.location.href = process.env.VUE_APP_LINE_LOGIN_URL 
        + process.env.VUE_APP_LINE_LOGIN_PARAM_TYPE 
        + process.env.VUE_APP_LINE_LOGIN_PARAM_CLIENT
        + process.env.VUE_APP_LINE_LOGIN_PARAM_REDIRECT
        + process.env.VUE_APP_LINE_LOGIN_PARAM_OTHER
        + "&nonce=" + new Date().getTime()
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 132px - 80px - 95px);padding:50px 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;margin-top:16px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:13px;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;font-size: 15px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:13px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:13px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content h1{font-size:16px;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;cursor: pointer;font-size:15px;}
  .content .thirdLogin img{margin-right:10px;width:25px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px 100px;}
  .win .con .close{display: flex;justify-content: flex-end;width:100%;}
  .win .con .close img{width:20px;cursor: pointer;}
  .win .con .tls{font-size:15px;font-weight: bold;margin-top:0px;text-align: center;color:#4A4A4A;}
  .win .con p{width:100%;}
  .win .con .btn{display: flex;margin-top:30px;justify-content: center;align-items: center;color:#ffffff;width:300px;height:50px;background-color: rgba(56, 192, 206, 1);border-radius: 2px;margin-bottom:20px;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{margin:0px 16px;min-height: calc(100vh - 323px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;font-size: 14px;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
    .content .link a {font-size: 12px;}
    .content h1{font-size:15px;}
    .content .thirdLogin {font-size: 14px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
