<template>
  <div id="register">
    <div class="win" v-if="winForm">
      <div class="con">
        <div class="tls"><div>詳細内訳 <span>{{detail_time}}</span></div><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
        <div class="items">
          <div class="item header">
            <span>作品名</span>
            <span>作品価格</span>
            <span>区分</span>
          </div>
          <div class="item" v-for="item,index of detail_datas">
            <span>{{item.title}}</span>
            <span>{{(item.price!='-')?formatNumber(item.price):item.price}}</span>
            <span>{{item.type}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        販売管理
      </div>
      <div class="con">
        <div class="menus pc">
          <span @click="tz('/salesManage')">作品管理</span>
          <span @click="tz('/salesData')" class="act">販売状況</span>
          <span @click="tz('/salesJs')">精算管理</span>
        </div>
        <div class="right">
          <div class="flex pc">
            <el-date-picker
                style="width:100%;"
                  size="large"
                  v-model="value1"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="~"
                  align="center"
                  start-placeholder="開始時間を選択してください"
                  end-placeholder="終了日を選択してください"
                  :picker-options="pickerOptions"
                  @change="change_date">
                </el-date-picker>
            <a style="margin-left:10px;" @click="search_data">表示</a>
          </div>
          <div class="flex">
            <div class="tjItem">
              <span>お気に入り数</span>
              <div v-loading="sum_loading">
                <b>{{sum_data.likeCount}}</b> EA
              </div>
            </div>
            <div class="tjItem">
              <span>カートにある数</span>
              <div v-loading="sum_loading">
                <b>{{sum_data.basketSaveCount}}</b> EA
              </div>
            </div>
            <div class="tjItem">
              <span>販売数</span>
              <div v-loading="sum_loading">
                <b>{{sum_data.itemSoldCount}}</b> EA
              </div>
            </div>
            <div class="tjItem">
              <span>売上高</span>
              <div v-loading="sum_loading">
                <b>{{formatNumber(sum_data.soldPrice)}}</b> JPY
              </div>
            </div>
          </div>
          <div class="rightInfo" style="cursor: pointer;" @click="download()" v-loading="down_load">
            <img src="../assets/img/paper-list.png" />
            エクセル·ダウンロード
          </div>
          <div class="list">
            <div class="item header">
              <span>日付</span>
              <span>お気に入り</span>
              <span>カート</span>
              <span>販売</span>
              <span class="pc">売上高</span>
              <span class="pc">詳細内訳</span>
            </div>
            <div class="item" v-for="item,index in datas" :key="item.dailyDate">
              <span>{{item.dailyDate}}</span>
              <span>{{item.likeCount}}</span>
              <span>{{item.basketSaveCount}}</span>
              <span>{{item.itemSoldCount}}</span>
              <span class="pc">{{formatNumber(item.soldPrice)}}</span>
              <span class="blue pc" @click="show_detail(item.dailyDate)">内訳確認</span>
            </div>
            <div v-loading="listLoading" class="moreBtn" @click="loadData" v-if="!isEnd">+ もっと見る</div>
          </div>
        </div>
      </div>

    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>販売状況</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getAction,downFile } from '@/network/manage'
  import numeral from 'numeral'

  export default {
    name: 'SalesData',
    components: {
    },
    data() {
      return {
        value1:null,
        winForm:false,
        datas:[],
        lastTime: null,
        startTime: null,
        endTime: null,
        pageSize:10,
        isEnd: false,
        listLoading:false,
        sum_loading:false,
        sum_data:{},
        detail_datas:[],
        detail_time:'',
        down_load:false,
        pickerOptions: {
          disabledDate: time => {
            return time.getTime() > Date.now();
          },
        }
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      formatNumber() {
        return function(price) {
          return numeral(price).format('0,0')
        }
      },
    },
    created() {
      if(this.type != 1) {
        this.$router.replace('/apply').catch(err => {})
      }
      this.loadData()
      this.sum_load_data()
    },
    methods: {
      search_data() {
        this.lastTime = null
        this.isEnd = false
        this.datas = []
        this.loadData()
        this.sum_load_data()
      },
      change_date(val) {
        if(val) {
          this.startTime = val[0]
          this.endTime = val[1]
        } else {
          this.startTime = null
          this.endTime = null
        }
      },
      download() {
        if(!this.startTime || !this.endTime) {
          this.$message({
            message: 'エクスポートする時間範囲を選択してください',
            type: 'warning'
          });
          return;
        }
        let fileName = "販売情報"
        this.down_load = true
        let param = {startTime:this.startTime,endTime:this.endTime,lastTime:this.lastTime,pageSize:this.pageSize}
        downFile("/user/member/statisticalListExpor",param).then((data)=>{
          this.down_load = false
          if (!data) {
            this.$message.warning("ダウンロードエラー")
            return
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName + '.xlsx')
          } else {
            let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        })
      },
      show_detail(date) {
        this.detail_time = date
        this.detail_datas = []
        const loading = this.$loading.service()
        let param = {day: date}
        getAction("/user/member/statisticalDetails",param).then(res => {
          if (res.success) {
            this.winForm = true
            this.detail_datas = res.result
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          loading.close()
        })
      },
      sum_load_data() {
        this.sum_loading = true
        const that = this
        let param = {startTime:this.startTime,endTime:this.endTime,lastTime:this.lastTime,pageSize:this.pageSize}
        getAction("/user/member/statisticalSummary",param).then(res => {
          if (res.success) {
            this.sum_data = res.result
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          this.sum_loading = false
        })
      },
      loadData() {
        if(this.isEnd) {
          return;
        }
        this.listLoading = true
        const that = this
        let param = {startTime:this.startTime,endTime:this.endTime,lastTime:this.lastTime,pageSize:this.pageSize}
        getAction("/user/member/statisticalList",param).then(res => {
          if (res.success) {
            this.datas = this.datas.concat(res.result.datas)
            this.isEnd = res.result.isEnd
            if(!this.isEnd) {
              this.lastTime = res.result.lastTime
            } else {
              this.lastTime = null
            }
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          this.listLoading = false
        })

      },
      tz(url){
        this.$router.push(url)
      }
    }
  }
</script>

<style scoped>
  *{font-size:14px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .el-range-editor--small .el-range-input {font-size: 18px !important;}
  .content{min-height: calc(100vh - 215px);}
  .content .title{margin:30px 0 10px 0;font-size:26px;font-weight: bold;}
  .content .con{display: flex;}
  .con .menus{margin-right:40px;display: flex;flex-shrink: 0;flex-direction: column;}
  .con .menus span{margin:14px 0;font-size:20px;color: rgba(1, 56, 139, 0.46);cursor: pointer;}
  .con .menus span:hover{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .menus .act{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .right{width:100%;display: flex;flex-direction: column;align-items: flex-end;}
  .con .right .rightInfo{display: flex;margin-bottom:6px;align-items: center;background: #F2F4F6;padding:4px;font-size:14px;}
  .con .right .rightInfo img{margin-right:6px;width: 25px;}
  .con .right .list{width:100%;}
  .con .right .list .item{display: flex;}
  .blue{font-weight: bold;color: rgba(1, 56, 139, 0.89);cursor: pointer;}
  .con .right .list .header{border-top:2px solid rgba(72, 83, 100, 0.46);border-bottom:2px solid rgba(72, 83, 100, 0.46);margin-top:10px;}
  .con .right .list .item span{width:100%;display: flex;justify-content: center;align-items: center;padding:14px 0;text-align: center;font-weight: bold;}
  .con .right .list .item span img{margin:0 10px;cursor: pointer;}
  .moreBtn{margin:20px auto;width:130px;height:36px;background: #ECF9FB;color:#38C0CE;border-radius: 40px;display: flex;justify-content: center;align-items: center;font-size:14px;cursor: pointer;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;width:600px;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px;}
  .win .con .items{width:100%;max-height:400px;overflow-y: auto;}
  .win .con *{font-size:14px;}
  .win .con .header{border-bottom:2px solid rgba(72, 83, 100, 0.46);}
  .win .con .header *{font-size:16px !important; font-weight: bold;}
  .win .con .item{width:100%;display: flex;padding:10px 0;}
  .win .con .item span{width:100%;align-items: center;display: flex;justify-content: center;}
  .win .con .item span:nth-child(1){width:200%;}
  .win .con .tls img{width:20px;cursor: pointer;}
  .win .con .tls{margin-bottom:20px;display: flex;justify-content: space-between;align-items: center;width:100%;}
  .win .con .tls div{font-size:20px;font-weight: bold;}
  .win .con .tls span{font-weight: normal;font-size:16px;}
  .win .con p{width:100%;}
  .flex{display: flex;align-items: center;width:100%;margin-bottom:20px;justify-content:space-between;}
  .flex select{width:100%;margin-right:20px;border:none;border-bottom:1px solid #aaaaaa;height:40px;}
  .flex label{margin-right:20px;}
  .flex a{background: #38C0CE;flex-shrink: 0;border-radius: 2px;color:#ffffff;width:66px;height:38px;display: flex;align-items: center;justify-content: center;color:#ffffff;cursor: pointer;}
  .flex .tjItem{width:24%;background: #FFFFFF;border-radius: 30px;box-shadow:0px 3px 5px rgba(0, 0, 0, 0.25);padding:14px 20px;display: flex;flex-direction: column;}
  .flex .tjItem span{color:#888888;}
  .flex .tjItem div{display: flex;justify-content: flex-end;align-items: flex-end;line-height: 1;}
  .flex .tjItem div b{color:#38C0CE;font-weight: bold;font-size:30px;line-height: 1;margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  @media (max-width: 800px) {
  	.content{margin:0 16px;min-height: calc(100vh - 323px);}
    .pc{display: none !important;}
    .content .title{margin:10px 0;text-align: center;}
    .content .title{margin:30px 0 30px 0;font-size:18px;}
    .content .btn{width:100%;}
    .win{background-color: #ffffff;top:60px;align-items: flex-start;}
    .win .con{box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);padding:20px;}
    .con .right .list .item span{padding:6px 0;}
    .flex{flex-wrap: wrap;}
    .flex .tjItem{width:45%;margin-bottom:20px;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 800px;margin: auto;}
  }
  @media(min-width: 1200px) {
  	.content{width:1200px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 1200px;margin: auto;}
  }
</style>
