<template>
  <div>
    <div class="content">
      <div class="win" v-if="winForm">
        <div class="con">
          <div class="close"><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
          <div class="tls">会員を退会しますか？</div>
          <p>NOTE : 退会時、会員に関する全ての内容が削除され、復旧できません。</p>
          <div class="btn" @click="exit_member">退会する</div>
        </div>
      </div>
      <div class="info">
        <upload
          class="avatar-uploader"
          :action="getUploadUrl"
          :headers="headers"
          :show-file-list="false"
          :multiple="false"
          accept="image/*"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img class="head" v-if="data.profilePicture" :src="data.profilePicture"/>
          <img class="head" v-else src="../assets/img/p_upload.png"/>
        </upload>

        <div class="nickInfo" v-if="!nickForm">
          <span>{{data.nickName}}</span><img @click="nickForm = true" src="../assets/img/edit_nick.png"/>
        </div>
        <div class="nickForm" v-if="nickForm">
          <input v-model="data.nickName" @input="detect_nickname_changes"/><a @click="edit_nickname()" class="btn">確認</a>
        </div>
        <div class="error" v-if="!nickname_status">{{nickname_error}}</div>
        <div class="error" v-if="nickname_status" style="color: #38C0CE;">{{nickname_error}}</div>
      </div>
      <div class="items">
        <div v-if="data.custom == 1">
          <div class="item">
            <label>ID（メールアドレス）<span class="btn mobile" @click="winForm = true">会員退会</span></label>
            <div class="line">
              <p>{{data.email}}</p>
              <span class="btn pc" @click="winForm = true">会員退会</span>
            </div>
          </div>
          <div class="item">
            <label>パスワード<span class="btn mobile" @click="send_repassword_email" v-loading="send_email_loading">パスワード変更</span></label>
            <div class="line">
              <p>●●●●●●●●</p>
              <span class="btn pc" @click="send_repassword_email" v-loading="send_email_loading">パスワード変更</span>
            </div>
          </div>
        </div>
        <div class="item" v-if="data.google == 1 || data.line == 1">
          <label>ID（メールアドレス）<span class="btn mobile" @click="winForm = true">会員退会</span></label>
          <div class="line">
            <img src="../assets/img/google-login-icon.png" v-if="data.google == 1"/>
            <img src="../assets/img/line-login-icon.png" v-else/>
            <p>{{data.email}}</p>
            <span class="btn pc" @click="winForm = true">会員退会</span>
          </div>
        </div>
        <div v-if="type == 1">
          <div class="item" v-if="!editForm">
            <label>作家紹介文<span class="btn mobile" @click="editForm = true">紹介文変更</span></label>
            <div class="line">
              <p v-if="data.writerIntroduction">{{data.writerIntroduction}}</p>
              <p v-else style="color: rgba(1, 56, 139, 0.46);">作家プロフィールにアップロードする作家紹介を入力してください</p>
              <span class="btn pc" @click="editForm = true">紹介文変更</span>
            </div>
          </div>
          <div class="item edit" v-if="editForm">
            <label>作家紹介文</label>
            <div class="line editLine">
              <textarea v-model="data.writerIntroduction" @input="change_content" placeholder="作家プロフィールにアップロードする作家紹介を入力してください"></textarea>
              <div><span class="btn" @click="save_writer_info">変更確認</span><a>{{font_amount}}/100</a></div>
            </div>
          </div>
        </div>

        <div class="item" v-if="type == 1">
          <label></label>
          <div class="titleLine">
            <h1>口座情報</h1><span class="btn" @click="go_edit">入力する</span>
          </div>
        </div>

        <div v-if="data.userBankCountry && type == 1">

          <div class="item">
            <label>名前(口座所有者)</label>
            <div class="line">
              <p>{{data.bankAccountHolder}}</p>
            </div>
          </div>

          <div class="item">
            <label>預金主の身分書類</label>
            <div class="image">
              * 預金主が個人の場合はパスポート身分面のスキャンを、事業者の場合は現地事業者登録証をアップロードしてください
              <a :href="data.idScanUrl" target="_blank">{{data.idScanName}}</a>
            </div>
          </div>

          <div class="item">
            <label>連絡先</label>
            <div class="line">
              <p>{{data.phoneNumber}}</p>
            </div>
          </div>

          <div class="item">
            <label>銀行情報</label>
            <div class="flex">
              <select style="color: black;" v-model="data.userBankCountry" disabled>
                <option value="">銀行国家</option>
                <option value="JPN">日本</option>
                <option value="USA">米国</option>
                <option value="CHN">中国</option>
                <option value="KOR">韓国</option>
                <option value="EUR">ヨーロッパ</option>
              </select>
              <div class="flexC">
                <input v-model="data.bankName" style="color: black;" readOnly/>
              </div>
            </div>
          </div>
          <div class="item" v-if="data.userBankCountry == 'JPN'">
            <label></label>
            <div class="flex">
              <select style="color: black;" v-model="data.bankAccountType" disabled>
                <option value="">口座種類</option>
                <option value="0">普通預金</option>
                <option value="1">当座預金</option>
              </select>
              <div class="flexC">
                <input style="color: black;" v-model="data.bankAccountNumber" readOnly/>
              </div>
            </div>
          </div>

          <div class="item" v-if="data.userBankCountry != 'JPN'">
            <label></label>
            <div class="flex">
              <div style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
              <div class="flexC">
                <input style="color: black;" v-model="data.bankBranchName" readOnly/>
              </div>
            </div>
          </div>

          <div class="item" v-if="data.userBankCountry != 'JPN'">
            <label></label>
            <div class="flex">
              <div style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
              <div class="flexC">
                <input style="color: black;" v-model="data.bankBranchAddress" readOnly/>
              </div>
            </div>
          </div>

          <div class="item" v-if="data.userBankCountry != 'JPN'">
            <label></label>
            <div class="flex">
              <div style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
              <div class="flexC">
                <input style="color: black;" v-model="data.bankSwiftCode" readOnly/>
              </div>
            </div>
          </div>

          <div class="item" v-if="data.userBankCountry != 'JPN'">
            <label></label>
            <div class="flex">
              <div style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
              <div class="flexC">
                <input style="color: black;" v-model="data.bankAccountNumber" readOnly/>
              </div>
            </div>
          </div>

        </div>
        <div v-if="!data.userBankCountry && type == 1">

          <div class="item">
            <label>名前(口座所有者)</label>
            <div class="line">
              <p style="color: rgba(1, 56, 139, 0.46);">銀行口座名と同一名前の入力必須</p>
            </div>
          </div>

          <div class="item">
            <label>預金主の身分書類</label>
            <div class="image">
              * 預金主が個人の場合はパスポート身分面のスキャンを、事業者の場合は現地事業者登録証をアップロードしてください
              <a>アップロードされていません</a>
            </div>
          </div>

          <div class="item">
            <label>連絡先</label>
            <div class="line">
              <p style="color: rgba(1, 56, 139, 0.46);">国番号を含めて作成</p>
            </div>
          </div>

          <div class="item">
            <label>銀行情報</label>
            <div class="flex">
              <select disabled>
                <option>銀行国家</option>
              </select>
              <div class="flexC">
                <input value="銀行名を入力してください" readOnly/>
              </div>
            </div>
          </div>
          <div class="item">
            <label></label>
            <div class="flex">
              <select disabled>
                <option>口座種類</option>
              </select>
              <div class="flexC">
                <input value="口座番号を入力してください" readOnly/>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>プロフィール</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Upload } from 'element-ui'
  import { postAction,getAction } from '@/network/manage'
  import { deleteActionApi,getActionApi } from '@/network/manageApi'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { duplicate } from "network/user"
  export default {
    name: 'Prifile',
    components: {
      Upload
    },
    data() {
      return {
        nickForm:false,
        editForm:false,
        winForm:false,
        data: {},
        font_amount: 0,
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        isUpload: true,
        nickname: '',
        nickname_error: '',
        nickname_status: false,
        send_email_loading: false,
        timer: null,
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      getUploadUrl() {
        return process.env.VUE_APP_BASE_API + "/file-upload/upload-public"
      }
    },
    created(){
      this.sum_load_data()
    },
    methods:{
      go_edit() {
        this.$router.push("/prifileEdit")
      },
      async send_repassword_email() {
        try{
          this.send_email_loading = true
          let obj = await postAction("/user/member/findPwSendEmail")
          this.send_email_loading = false
          if(obj.success) {
            this.$message.success("メールが送信されました。メールアドレスに行ってパスワードをリセットしてください");
          } else {
            if(obj.code == 501) {
              this.$message.success("メールが送信されました。メールアドレスに行ってパスワードをリセットしてください");
            } else {
              this.$message.error("ネットワーク異常は後で再試行");
            }
          }
        } catch(e) {
          this.send_email_loading = false
          this.$message.success("ネットワーク異常は後で再試行");
        }
      },
      detect_nickname_changes(e) {
        this.nickname_status = false
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.check_nickname()
        }, 500);
      },
      async check_nickname() {
        console.log(this.nickname);
        this.nickname_status = false
        this.nickname_error = ''
        if(this.data.nickName == this.nickname) {
          this.nickname_status = true
          this.nickname_error = ''
          return true;
        }
        let reg= /^[\da-zA-Z]{1,10}$/;
        if(reg.test(this.data.nickName)) {
          let obj = await getAction("/user/member/checkNickname?nickname=" + this.data.nickName)
          if(obj.success) {
            this.nickname_status = true
            this.nickname_error = '使用可能なユーザーネームです。'
            return true;
          } else {
            this.nickname_status = false
            this.nickname_error = 'すでに使用中のユーザーネームです。'
            return false;
          }
        } else {
          this.nickname_status = false
          this.nickname_error = '英数字10文字以内、作家名としても使用されます'
          return false
        }
      },
      async edit_nickname() {
        if(this.data.nickName == this.nickname) {
          this.nickForm = false
          return;
        }
        if(this.nickname_status) {
          let param = {nickname: this.data.nickName}
          postAction("/user/member/updateNickname", param).then(res => {
            if(res.success) {
              this.$store.dispatch('GetUserInfo')
              this.nickForm = false
              this.nickname_error = ''
              this.nickname_status = false
              this.nickname = this.data.nickName
            } else {
              this.nickForm = false
            }
          })
        }
      },
      exit_member() {
        deleteActionApi("/user-management/delete-account").then(res => {
          if(res.code == 200) {
            this.$store.dispatch('Logout')
            window.location.href = "/"
          }
        })
      },
      handleAvatarSuccess(res, file) {
        this.data.profilePicture = res.data.upload_path
        postAction("/user/member/updateProfilePicture", {url: res.data.upload_path}).then(res => {
            this.$store.dispatch('GetUserInfo')
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = (file.size / 1024 / 1024) < 5;
        if (!isLt5M) {
          this.$message.error('アップロードサイズ：5MBまで！')
        }
        return isLt5M
      },
      change_content() {
        this.font_amount = this.data.writerIntroduction.length
        if(this.font_amount > 100) {
          this.data.writerIntroduction = this.data.writerIntroduction.substring(0, 100)
          this.font_amount = 100
        }
      },
      save_writer_info() {
        postAction("/user/member/updateWriterIntroduction", {desc: this.data.writerIntroduction})
        this.editForm = false
      },
      sum_load_data() {
        const that = this
        getAction("/user/member/personalBasicInfo").then(res => {
          if (res.success) {
            this.data = res.result
            this.nickname = res.result.nickName
            if(this.data.idScanUrl)
              this.data.url_name = this.data.idScanUrl.substring(this.data.idScanUrl.lastIndexOf('/') + 1);
            if(this.data.writerIntroduction) {
              this.font_amount = this.data.writerIntroduction.length
            } else {
              this.font_amount = 0
            }
          } else {
            that.$message.error("ネットワークエラー")
          }
        }).catch(err => {
          that.$message.error("ネットワークエラー")
        })
      },
    }
  }
</script>

<style scoped>
*{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
.content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 215px);}
.title{margin:30px 0;font-size: 20px;font-weight: bold;}
.title .btn{margin-left:10px;box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);cursor: pointer;flex-shrink: 0;background: #01388B;border-radius: 40px;width:130px;height:36px;display: flex;justify-content: center;align-items: center;color:#ffffff;font-size: 14px;}
.head{width:100px;height:100px;border-radius: 50%;margin-top:40px;margin-bottom:10px;border-radius: 50%;border: 1px solid #f1f1F1;}
.nickInfo{display: flex;margin-left:20px;align-items: center;line-height: 1;color: #38C0CE;}
.nickInfo img{width:16px;margin-left:4px;cursor: pointer;}
.nickForm{display: flex;align-items:flex-end;}
.nickForm input{border:none;border-bottom:1px solid #aaaaaa;margin-left:66px;height:36px;padding:0 10px;}
.nickForm .btn{background: #38C0CE;border-radius: 10px;padding:4px 16px;color:#ffffff;margin-left:6px;font-size:14px;cursor: pointer;}
.error{color:#EC2222;margin-top:20px;}
.ok{color:#38C0CE;margin-top:20px;}
.info{height:260px;}
.items{width:80%;display: flex;flex-direction: column;}
.item{display: flex;align-items: center;width:100%;margin-bottom:30px;align-items: baseline;}
.item label{color:#888888;width:240px;flex-shrink: 0;text-align: left;}
.item .image{text-align: left;color:rgba(1, 56, 139, 0.46);display: flex;flex-direction: column;}
.item .image .upload{background: #ECF9FB;border-radius: 2px;width:180px;cursor: pointer;height:46px;margin-top:10px;display: flex;align-items: center;justify-content: center;color:#38C0CE;}
.item .image .upload img{margin-right:6px;}
.item .image a{color: #1192CA;text-decoration: underline;margin-top:10px;cursor: pointer;}
.item .line{width:100%;display: flex;border-bottom:1px solid #aaaaaa;padding:10px 0;align-items: center;}
.item .titleLine{width:100%;display: flex;padding:10px 0;align-items: center;}
.item .titleLine h1{width:100%;}
.item .line img{width:25px;margin:8px 16px;flex-shrink: 0;}
.item .line p{width:100%;word-break: break-all;height:24px;overflow: hidden;text-align: left;}
.item .btn{margin-left:10px;box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);cursor: pointer;flex-shrink: 0;background: #01388B;border-radius: 40px;width:130px;height:36px;display: flex;justify-content: center;align-items: center;color:#ffffff;}
.flex{display: flex;width:100%;justify-content: center;}
.flex select{border:none;border-bottom:1px solid #aaaaaa;height:40px;width:120px;text-align:center;margin-right:30px;flex-shrink: 0;color:rgba(1, 56, 139, 0.46);}
.flex input{border:none;width:100%;border-bottom:1px solid #aaaaaa;height:40px;padding:0 10px;color: rgba(1, 56, 139, 0.46);}
.flexC{display: flex;width:100%;flex-direction: column;}
.flexC label{color:#EC2222;width:100%;font-size:14px;padding:10px;}
.editLine{display: flex;flex-direction: column;align-items: flex-end !important;border-bottom:none !important;}
.editLine textarea{width:100%;border:none;border-bottom:1px solid #aaaaaa;height:80px;padding:9px 0;}
.editLine .btn{background-color: #38C0CE !important;margin-top:10px;}
.editLine div{display: flex;}
.editLine div a{position: absolute;margin-left:150px;margin-top:-30px;}
.win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
.win .con{background: #FFFFFF;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px;}
.win .con .close{display: flex;justify-content: flex-end;width:100%;}
.win .con .close img{width:20px;cursor: pointer;}
.win .con .tls{font-size:20px;font-weight: bold;margin-top:-10px;}
.win .con p{color:#01388B;font-size:14px;margin:20px 0 50px 0;}
.win .con .btn{display: flex;justify-content: center;align-items: center;color:#ffffff;width:300px;height:50px;background-color: #EC2222;border-radius: 2px;margin-bottom:20px;cursor: pointer;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-size:13px;color:#4A4A4A;}
@media (max-width: 800px) {
  .item .titleLine h1{text-align: left;}
  .win{background-color: #ffffff;top:60px;align-items: flex-start;}
  .win .con{box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);padding:20px;}
  .win .con .btn{width:100%;}
  .content{margin:0 16px;}
  .pc{display: none !important;}
  .items{width:100%;}
  .item{flex-direction: column;}
  .item .line img{margin:8px 10px 8px 0;}
  .item label{width:100%;display: flex;align-items: center;justify-content: space-between;}
  .item .btn{font-size:14px;height:30px;width:120px;}
  .editLine div{align-items: flex-end;flex-direction: column-reverse;}
  .editLine div a{position: static;margin-left:0;margin-top:5px;}
  .editLine .btn{margin-top:5px;}
  .nav {width: 100%;padding: 50px 20px 30px 20px;}
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .content{width:800px;margin:auto;}
  .mobile{display: none !important;}
  .nav {width: 800px;margin: auto;}
}
@media(min-width: 1200px){
  .content{width:1200px;margin:auto;}
  .mobile{display: none !important;}
  .nav {width: 1200px;margin: auto;}
}
</style>
