<template>
  <div id="register">

    <div class="content">
      <div class="mobile" style="margin-bottom: 60px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 45px;font-size: 20px;">
        会員登録が完了しました
      </div>
      <div class="title" style="margin-bottom: 45px;font-size: 15px;">
        ご登録いただきありがとうございました。</br>
        引き続き、3D-ITEM!をお楽しみください。
      </div>
      <div class="btn ok" @click="go_home">HOME</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>登録が完了</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction,getAction } from '@/network/manage'
  import Vue from 'vue'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
      }
    },
    computed: {

    },
    created() {

    },
    methods: {
      go_url(path) {
        this.$router.push({path:path})
      },
      go_home() {
        window.location.href = "/"
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 325px);padding:80px 0 0 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:14px;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:14px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;align-items: center;justify-content: center;margin-top:20px;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content .check{display: flex;margin:15px 0 0px 0;align-items: center;font-size:13px;}
  .content .check input{width:20px;height:20px;margin-right:5px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .check a{color:#EC2222;}
  .content h1{font-size:18px;}
  .input_error {border: 1px rgba(236, 34, 34, 1) solid !important;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;}
  .content .thirdLogin img{margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{margin:0px 16px;min-height: calc(100vh - 380px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
