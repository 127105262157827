<template>
  <div id="home">
    <div class="content" v-if="announcementShow && announcementAfter !=null && announcementAfter.length > 0 && isLogin && !isM()">
      <div class="newBanner">
        <div class="close" @click="announcementShow = false">×</div>
        <swiper :options="newBanner">
        	<swiper-slide v-for="(item,i) in announcementAfter" :key="i">
            <a class="img" target="_blank" :href="item.linkUrl" :style="item.contentUrl"></a>
          </swiper-slide>
        	<div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="content" v-if="announcementShow && announcementBefore !=null && announcementBefore.length > 0 && !isLogin && !isM()">
      <div class="newBanner">
        <div class="close" @click="announcementShow = false">×</div>
        <swiper :options="newBanner">
        	<swiper-slide v-for="(item,i) in announcementBefore" :key="i">
            <a class="img" target="_blank" :href="item.linkUrl" :style="item.contentUrl"></a>
          </swiper-slide>
        	<div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="content" v-if="announcementShow && announcementAfter_m !=null && announcementAfter_m.length > 0 && isLogin && isM()">
      <div class="newBanner">
        <div class="close" @click="announcementShow = false">×</div>
        <swiper :options="newBanner">
        	<swiper-slide v-for="(item,i) in announcementAfter" :key="i">
            <a class="img" target="_blank" :href="item.linkUrl" :style="item.contentUrl"></a>
          </swiper-slide>
        	<div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="content" v-if="announcementShow && announcementBefore_m !=null && announcementBefore_m.length > 0 && !isLogin && isM()">
      <div class="newBanner">
        <div class="close" @click="announcementShow = false">×</div>
        <swiper :options="newBanner">
        	<swiper-slide v-for="(item,i) in announcementBefore" :key="i">
            <a class="img" target="_blank" :href="item.linkUrl" :style="item.contentUrl"></a>
          </swiper-slide>
        	<div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- banner1 -->
    <div class="banner1 pc">
    	<swiper :options="swiperOptions1">
    		<swiper-slide v-for="(item,i) in PC_HOME_TOP" :key="i">
          <a class="img" target="_blank" :href="item.urlTo" :style="item.contentUrl_bc"></a>
        </swiper-slide>
    		<div class="swiper-pagination" slot="pagination"></div>
    	</swiper>
    </div>
    <!-- banner1 -->
    <div class="banner1m mobile">
    	<swiper :options="swiperOptions1">
    		<swiper-slide class="img" v-for="(item,i) in MOBILE_HOME_TOP" :key="i">
          <a class="img" target="_blank" :href="item.urlTo" :style="item.contentUrl_bc"></a>
        </swiper-slide>
    		<div class="swiper-pagination" slot="pagination"></div>
    	</swiper>
    </div>
    <!-- banner2 -->
    <div class="banner2 pc">
    	<h4 class="content">ランキング</h4>
    	<div class="swiper-father">
    		<swiper :options="swiperOptions2">
    			<swiper-slide v-for="(item,i) in rankingDatas" :key="i">
            <a class="img" @click="ntfHome(item.contents_uuid)" :style="item.thumbnail"><span class="bq">{{i+1}}</span></a>
          </swiper-slide>
    			<div class="swiper-pagination" slot="pagination"></div>
    		</swiper>
    		<div class="swiper-button-prev"></div>
    		<div class="swiper-button-next"></div>
    	</div>
    </div>
    <!-- banner2 -->
    <div class="banner2-m mobile">
    	<h4>ランキング</h4>
    	<div class="swiper-father">
    		<swiper :options="swiperOptions2_mobile">
    			<swiper-slide v-for="(item,i) in rankingDatas" :key="i">
    			  <a class="img" @click="ntfHome(item.contents_uuid)" :style="item.thumbnail"><span class="bq">{{i+1}}</span></a>
    			</swiper-slide>
    		</swiper>
    	</div>
    </div>
    <!-- goodsList -->
    <div class="content goodsList">
    	<div class="tool">
    		<div>
    			<span :class="toolType == 0 ? 'act' : ''" @click="changeTooleType(0)">新着</span>
    			<span :class="toolType == 1 ? 'act' : ''" @click="changeTooleType(1)">人気</span>
    			<span :class="toolType == 2 ? 'act' : ''" @click="changeTooleType(2)">ピックアップ</span>
          <span :class="toolType == 3 ? 'act' : ''" @click="changeTooleType(3)">イベント</span>
    		</div>
    		<a href="javascript:void(0)" @click="explorerPage" class="pc" style="color:rgba(1, 56, 139, 1);font-size:14px;">もっと見る</a>
    	</div>

    	<div class="list">
    		<div class="item" v-for="(item,i) in goodsDatas" :key="i">
    			<div class="img" :style="item.thumbnail" @click="ntfHome(item.contents_uuid)"></div>
          <div class="sales" v-if="item.isPromotion">
            {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
          </div>
    			<h5 @click="ntfHome(item.contents_uuid)">{{item.content_title}}</h5>
    			<div class="price">
            <div v-if="item.isPromotion">
              <h4>¥{{numberFormat(item.price)}}</h4>
              <span>¥{{numberFormat(item.promotional_price)}}</span>
            </div>
            <div v-else>
            	<h2 style="font-weight: normal;">¥ {{numberFormat(item.price)}}</h2>
            </div>
    				<i :class="item.like ? 'heart heartAct' : 'heart'" @click="likeFunction(item)"></i>
    			</div>
    		</div>
    	</div>
    	<a href="javascript:void(0)" @click="explorerPage" class="mobile" style="color:rgba(1, 56, 139, 1);font-size:12px;">もっと見る</a>
    </div>
    <!-- banner3 -->
    <div class="banner3 pc">
      <div class="swiper-father">
        <swiper :options="swiperOptions3">
          <swiper-slide v-for="(item,i) in PC_HOME_BOTTOM" :key="i">
            <a :href="item.urlTo" target="_blank" style="width:100%;">
              <img :src="item.contentUrl"/>
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
    <div class="banner3-m mobile">
    	<swiper :options="swiperOptions3_mobile">
    	  <swiper-slide v-for="(item,i) in MOBILE_HOME_BOTTOM" :key="i">
    	    <a :href="item.urlTo" target="_blank" style="width:100%;">
    	      <img :src="item.contentUrl"/>
    	    </a>
    	  </swiper-slide>
    	</swiper>
    </div>
    <!-- videoDiv -->
    <div class="content videoDiv">
    	<h4>ガイド</h4>
    	<div class="pc">
    		<div v-for="(item,i) in PC_HOME_DD" :key="i">
          <a class="img" :href="item.urlTo" target="_blank" :style="item.contentUrl_bc" v-if="item.bannerType === 0"></a>
          <a class="img" v-if="item.bannerType == 1">
            <video controls style="width:100%;object-fit:fill;">
              <source :src="item.contentUrl" type="video/mp4">
            </video>
          </a>
        </div>
    	</div>
      <div class="mobile">
      	<div v-for="(item,i) in MOBILE_HOME_DD" :key="i">
          <a class="img" :href="item.urlTo" target="_blank" :style="item.contentUrl_bc" v-if="item.bannerType === 0"></a>
          <a class="img" v-if="item.bannerType == 1">
            <video controls style="width:100%;object-fit:fill;">
              <source :src="item.contentUrl" type="video/mp4">
            </video>
          </a>
        </div>
      </div>
    </div>
    <div class="bottomDiv"></div>
  </div>
</template>
<script>
  import { swiper, swiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
  import { getAction } from '@/network/manage'
  import { getActionApi,postActionApi,deleteActionApi } from '@/network/manageApi'
  import numeral from 'numeral'
  import { mapGetters } from 'vuex'
  import {isMobile} from "@/utils/utils"
  import moment from 'moment'
  export default {
    name: 'Home',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        url: {
          mainUrl:'/main/',
          like:'/like/like',
        },
        PC_HOME_TOP:null,
        PC_HOME_BOTTOM:null,
        PC_HOME_DD: null,
        MOBILE_HOME_TOP:null,
        MOBILE_HOME_BOTTOM:null,
        MOBILE_HOME_DD:null,
        announcementBefore_m: null,
        announcementAfter_m: null,
        announcementBefore:null,
        announcementAfter:null,
        announcementShow:true,
        rankingDatas: [],
        goodsDatas: [],
        toolType:0,
        newBanner: {
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        },
        swiperOptions1: {
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        },
        swiperOptions2: {
          loop: false,
          speed: 1000,
          slidesPerView: 3,
          spaceBetween: 20,
          navigation:{
            nextEl:".banner2 .swiper-button-next",
            prevEl:".banner2 .swiper-button-prev"
          }
        },
        swiperOptions2_mobile: {
          loop: false,
          speed: 1000,
          slidesPerView: 1.22,
          centeredSlides: false,
          spaceBetween: 16
        },
        swiperOptions3: {
          loop: true,
          speed: 1000,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          navigation:{
            nextEl:".banner3 .swiper-button-next",
            prevEl:".banner3 .swiper-button-prev"
          }
        },
        swiperOptions3_mobile: {
          loop: true,
          speed: 1000,
          slidesPerView: 1.10,
          centeredSlides: true,
          spaceBetween: 10,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
        }
      }
    },
    computed: {
      ...mapGetters([
        'token',
      ]),
      isLogin() {
        return this.token !== null && this.token !== ''
      },
    },
    created(){
      this.changeTooleType(0)
      this.loadRanking();
      this.loadBanner(0,0,'PC_HOME_TOP');
      this.loadBanner(1,0,'PC_HOME_BOTTOM');
      this.loadBanner(2,0,'PC_HOME_DD');
      this.loadBanner(0,1,'MOBILE_HOME_TOP');
      this.loadBanner(1,1,'MOBILE_HOME_BOTTOM');
      this.loadBanner(2,1,'MOBILE_HOME_DD');
      this.loadAnnouncement(0, 'announcementBefore');
      this.loadAnnouncement(1, 'announcementBefore_m');
      this.loadAnnouncement(2, 'announcementAfter');
      this.loadAnnouncement(3, 'announcementAfter_m');
    },
    methods:{
      likeFunction(item){
        if(item.like){
          this.unlike(item)
        }else{
          this.like(item)
        }
      },
      like(item){
        const that = this;
        postActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',true)
          } else if(res.code == 40023) {
            that.$message.warning("自分の作品は購入できません");
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
        })
      },
      unlike(item){
        const that = this;
        deleteActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',false)
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
        })
      },
      loadRanking() {
        const that = this;
        getActionApi("/contents/general-contents/rank-lists").then((res) => {
          if (res.code == 200) {
            let list = res.data.contents;
            for(var i = 0; i < list.length; i++){
              list[i].thumbnail = "background-image:url('"+list[i].image_url+"')";
            }
            this.rankingDatas = list
          } else {
          }
        }).catch(res => {
        })
      },
      isM() {
        return isMobile()
      },
      explorerPage(){
        this.$router.push({path:'explorer',query:{}})
      },
      ntfHome(id){
        this.$router.push({path:'contentsHome',query:{id:id}})
      },
      async changeTooleType(ind){
        let that = this
        this.toolType = ind;
        let type
        if(ind == 0) {
          type = "new"
        } else if(ind == 1){
          type = "popular"
        } else if(ind == 2){
          type = "pickup"
        } else if(ind == 3){
          type = "time_sale"
        }
        let time = await getAction("/login/getTime")
        let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
        getActionApi("/contents/general-contents/mid-lists-categories?category=" + type).then((res) => {
          if (res.code == 200) {
            let obj = res.data.contents;
            for(var i = 0; i < obj.length; i++){
              obj[i].thumbnail = "background-image:url('"+obj[i].image_url+"')";

              obj[i].isPromotion = false
              if(obj[i].promotion_active) {
                if(obj[i].promotion_start_datetime && obj[i].promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    obj[i].isPromotion = true
                    obj[i].discount = Math.floor(100 - (obj[i].promotional_price/obj[i].price)*100)
                    obj[i].pEndTimeShow = moment(obj[i].promotion_end_datetime).format('YYYY.MM.DD')
                  }
                }
              }

            }
            this.goodsDatas = obj
          } else {
          }
        }).catch(res => {
        })
      },
      numberFormat(txt){
        return numeral(txt).format('0,0')
      },
      loadAnnouncement(type, data){
        const that = this;
        getAction("/home/searchAnnouncement?type=" + type).then((res) => {
          if (res.success) {
            let list = res.result;
            for(var i = 0; i < list.length; i++){
              list[i].contentUrl = "background-image:url('"+list[i].fileUrl+"')";
            }
            eval('that.'+data+'=list');
          } else {
          }
        }).catch(res => {
        })
      },
      loadBanner(lo,type, data){
        const that = this;
        getAction("/home/searchBanner",{lo,type}).then((res) => {
          if (res.success) {
            var list = res.result;
            for(var i = 0; i < list.length; i++){
              if(list[i].bannerType === 0){
                list[i].contentUrl_bc = "background-repeat: no-repeat;background-image:url('"+list[i].contentUrl+"')";
              }
            }
            eval('that.'+data+'=list');
          } else {
          }
        }).catch(res => {
        })
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-family: 'NotoSansJP';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;color:inherit;}
  .banner1 .img{width:100%;background-position: center;background-size: auto 100%;cursor: pointer;}
  .banner1 .swiper-pagination{bottom:10px;}
  .banner2 h4{font-size:18px;font-weight: normal;}
  .banner2 .img{background-position: center;background-size: cover;background-color: #f7f7f7;border-radius: 4px;overflow: hidden;cursor: pointer;}
  .banner2-m .img{background-position: center;background-size: cover;}
  .bq{width:34px;position: relative;background-color: #dddddd;font-size:18px;display: block;text-align: center;line-height:34px;color:#ffffff;}
  .banner2 .swiper-slide:nth-child(1) .bq{background-color: #D7C173;}
  .banner2 .swiper-slide:nth-child(2) .bq{background-color: #AEBCC9;}
  .banner2 .swiper-slide:nth-child(3) .bq{background-color: #C58968;}
  .banner2-m .swiper-slide:nth-child(1) .bq{background-color: #D7C173;}
  .banner2-m .swiper-slide:nth-child(2) .bq{background-color: #AEBCC9;}
  .banner2-m .swiper-slide:nth-child(3) .bq{background-color: #C58968;}
  .goodsList .tool{display: flex;border-bottom:1px solid #F0F0F0;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right_bule.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;background-size: 8px 13px;}
  .goodsList .tool div{display: flex;color:rgba(1, 56, 139, 0.46);}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#000000;border-bottom: 2px solid #000000;}
  .goodsList .list{display: grid;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{width:100%;display: flex;align-items: center;}
  .goodsList .list .item .price h4{line-height:1;color:rgba(1, 56, 139, 0.46);text-decoration-line:line-through;text-decoration-style: double;}
  .goodsList .list .item .price span{line-height:1;margin-left:5px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/like.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/liked.png);}
  .banner3 img{width:100%;}
  .swiper-button-prev{transform:scale(0.6);filter: grayscale(100%);margin-top:-22px;}
  .swiper-button-next{transform:scale(0.6);filter: grayscale(100%);margin-top:-22px;}
  .videoDiv h4{font-size:18px;font-weight: normal;}
  .videoDiv div{display: flex;justify-content: space-between;}
  .videoDiv div .img{background-position: center;background-size: cover;}
  .newBanner{position: absolute;width:470px;height:900px;z-index: 80;}
  .newBanner .close{position: absolute;z-index:81;width:30px;cursor: pointer;height:30px;margin-left:430px;margin-top:10px;background: #ffffff;color:#000000;display: flex;justify-content: center;align-items: center;border-radius: 50%;}
  .newBanner .img{width:100%;background-position: center;height:900px;background-size:cover;cursor: pointer;}
  .newBanner .swiper-pagination{bottom:10px;}
  .sales{background-color: rgba(236, 34, 34, 0.7);font-size:14px;color:#ffffff;position: absolute;padding:4px 10px;display: flex;align-items: center;}
  .sales span{font-size:12px;margin-left:10px;}

  @media (max-width: 800px) {
  	.pc{display: none !important;}
  	.banner1m .img{width:100%;height:104vw;background-position: center;background-size: auto 100%;cursor: pointer;}
  	.banner1m .swiper-pagination{bottom:-5px;}
  	.banner2-m{margin-left:16px;}
  	.banner2-m .img{width:78vw;height:53.3vw;background: #f7f7f7;background-position: center;background-size: cover;}
  	.banner2-m h4{font-size:16px;margin-top:10px;font-weight: normal;line-height:50px;}
  	.content{margin:0 16px;}
  	.goodsList{display: flex;flex-direction: column;align-items: center;}
  	.goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
  	.goodsList .list .item .img{width:44.2vw;height:24.25vw;}
  	.goodsList .list .item h5{font-size:14px;height:40px;}
  	.goodsList .list .item .price{padding: 0px 12px 10px 16px;display: flex;align-items: center;}
  	.goodsList .list .item .price h4{font-size:12px;line-height: 1.5;}
  	.goodsList .list .item .price span{font-size:15px;}
  	.goodsList .tool{margin-top:40px;height:50px;margin-bottom:20px;width:100%;}
  	.goodsList .tool div span{line-height:50px;height:48px;margin-right:30px;flex-shrink: 0;}
  	.goodsList .mobile{padding:30px 0 60px 0;color:#888888;background-image: url(../assets/img/right_bule.png);background-repeat: no-repeat;background-position: right top 35px;padding-right:16px;background-size:6px 10px;}
  	.banner3-m img{width:100%;height:28vw;}
  	.videoDiv{margin-bottom:80px;margin:0 auto 80px auto;}
  	.videoDiv h4{line-height:50px;margin-top:60px;margin-left:4vw;font-size: 16px;}
  	.videoDiv div{flex-direction: column;}
  	.videoDiv div .img{width:92vw;height:51.15vw;margin-bottom:16px;margin:0px auto 16px auto;}
  	.hui{color:#D0D0D0 !important;}
  	.user_menu{width:75vw;left:100vw;top:0;margin:0;height:100vh;border:none;display: block;transition: left 0.2s;}
  	.user_menu .closeBtn{width:24px;height:24px;background-image: url(../assets/img/close_bk.png);display:none;background-position: center;background-repeat: no-repeat;position: fixed;top:10px;right:10px;}
  	.user_menu span{background: #EAFAF6;border-radius: 2px;color:#00C999;display: flex;align-items: center;height:38px;justify-content: center;margin:0 17px;}
  	.user_menu span i{background-image: url(../assets/img/upload.png);margin:0px 10px 0 0;background-position: center;margin-left:0px !important;}
  	.menu_item_end{border-bottom: none;}
  	.menu_header_name {border-bottom: none;}
    .newBanner{width:100vw;height:100vh;left:0;top:0;z-index:101;}
    .newBanner .close{width:8vw;height:8vw;margin-left:0;margin-top:0px;right:3vw;top:3vw;z-index:102;}
    .newBanner .img{height:100vh;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.banner1 .img{height:307px;}
  	.banner2 .img{width:253px;height:173px;}
  	.goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
  	.goodsList .list .item .img{width:191px;height:105px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  	.goodsList .list .item h5{font-size:14px;height:40px;}
  	.goodsList .list .item .price h4{font-size:14px;}
  	.goodsList .list .item .price span{font-size:14px;}
  	.swiper-father{width:900px;margin:auto;position: relative;}
  	.swiper-father .swiper-container{width:800px;margin:auto;}
  	.banner3{padding:50px 0;}
  	.videoDiv h4{line-height:50px;}
  	.videoDiv div .img{width:394px;height:219px;}
    .videoDiv div .img video{height:219px;}
  	.goodsList .tool{margin-top:40px;height:50px;margin-bottom:20px;}
  	.goodsList .tool div span{line-height:50px;margin-right:30px;}
  	.banner2{padding-top:20px;}
  	.banner2 h4{line-height:50px;}
    .banner3 img{height:104px;}
  	.search .searchDiv .main a{font-size:14px;}
  	.search .searchDiv .main{grid-row-gap: 10px;padding:40px 0;}
    .bottomDiv{height:100px;}
    .newBanner{width:320px;height:580px;}
    .newBanner .close{width:20px;height:20px;margin-left:290px;margin-top:10px;}
    .newBanner .img{height:580px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.banner1 .img{height:460px;}
  	.banner2 .img{width:387px;height:265px;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
  	.swiper-father{width:1300px;margin:auto;position: relative;}
  	.swiper-father .swiper-container{width:1200px;margin:auto;}
  	.banner3{padding:100px 0;}
  	.videoDiv h4{line-height:70px;}
  	.videoDiv div .img{width:590px;height:328px;}
    .videoDiv div .img video{height:328px;}
  	.goodsList .tool{margin-top:80px;height:70px;margin-bottom:40px;}
  	.goodsList .tool div span{line-height:70px;margin-right:50px;}
  	.banner2{padding-top:40px;}
  	.banner2 h4{line-height:70px;}
    .banner3 img{height:156px;}
  	.search .searchDiv .main a{font-size:14px;}
  	.search .searchDiv .main{grid-row-gap: 20px;padding:60px 0;}
    .bottomDiv{height:200px;}
  }
</style>
