import { getAction, postAction } from "./manage"
import { getActionApi, postActionApi } from "./manageApi"

function getNonce() {
  return new Promise((resolve, reject) => {
    getAction('/user/nonce')
    .then(res => {
      if (res.code === 200) {
        resolve(res.data)
      } else {
        reject(res)
      }
    })
    .catch(err => {
      reject(err)
    })
  })
}

export async function signup(data) {

}

export async function login(param) {
  return postActionApi("/user-management/login", param)
}

export function getUserInfo() {
  return getAction('/user/member/getUserInfo')
}

export function duplicate(check, element) {
  const data = {check, element}
  return getAction('/user/duplicate', data)
}
