<template>
  <div id="register">

    <div class="content" v-if="step == 1">
      <div class="mobile" style="margin-bottom: 85px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 55px;font-size: 20px;">
        認証メールの有効期限が切れました
      </div>
      <div class="btn ok" @click="go_url('/findPw')">やり直す</div>
    </div>

    <div class="content" v-if="step == 2">
      <div class="mobile" style="margin-bottom: 20px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 30px;font-size: 20px;">
        パスワード再設定
      </div>
      <div style="font-size: 13px;text-align: left;margin:0 0 7px 0;">認証完了 E-mail (ID)</div>
      <input class="input" placeholder="ID（メールアドレス）" v-model="email" style="background:rgba(240, 240, 240, 1);" readonly/>
      <div style="font-size: 13px;text-align: left;margin:16px 0 7px 0;">パスワード</div>
      <input :class="'input' + (error_status?' input_error':'')" placeholder="英数字、特殊記号の組み合わせで6~18文字以内"
       v-model="password" type="password" @input="check_password"/>
      <input :class="'input' + (error_status?' input_error':'')" placeholder="パスワードをもう一度入力してください"
       v-model="rePassword" type="password" style="margin-top:16px;" @input="check_password"/>
      <div style="font-size: 13px;text-align: left;margin:7px 0 0 0;color:rgba(236, 34, 34, 1);">{{error_message}}</div>

      <div :class="'btn' + (btn_status?' ok':'')" @click="reset_btn" style="margin-top:25px;">確認</div>
    </div>

    <div class="content" v-if="step == 3">
      <div class="mobile" style="margin-bottom: 60px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 35px;font-size: 20px;">
        パスワードの再設定が完了しました
      </div>
      <div class="btn ok" @click="go_url('/login')">ログイン</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>パスワードの設定</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction,getAction } from '@/network/manage'
  import Vue from 'vue'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        key: '',
        step: 0,
        memberObj: {},
        email: '',
        password: '',
        rePassword: '',
        error_message: '',
        error_status: false,
        btn_status: false,
      }
    },
    computed: {
      is_ok_btn: function() {
        return (this.email != '' && this.email != null && this.email != undefined && this.emailReg.test(this.email))
      }
    },
    created() {
      this.key = this.$route.query.verify
      this.check_key()
    },
    methods: {
      check_key() {
        const that = this
        const loading = this.$loading.service()
        getAction("/login/findKeyInfo?key=" + this.key).then((res) => {
          loading.close()
          if (res.success) {
            this.step = 2
            this.email = res.result.info
          } else {
            this.step = 1
          }
        }).catch(res => {
        })
      },
      check_password() {
        let reg= /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{6,18}$/;
        if(reg.test(this.password)) {
          if(this.rePassword != '') {
            if(this.rePassword == this.password) {
              this.btn_status = true
              this.error_status = false
              this.error_message = ''
            } else {
              this.btn_status = false
              this.error_status = true
              this.error_message = 'パスワードが一致しません。'
            }
          } else {
            this.btn_status = false
            this.error_status = false
            this.error_message = ''
          }
        } else {
          this.btn_status = false
          this.error_status = true
          this.error_message = '英数字、特殊記号の組み合わせで6~18文字以内'
        }
      },
      reset_btn() {
        if(this.btn_status) {
          const loading = this.$loading.service()
          const that = this
          let params = {key: this.key, pwd: this.password}
          postAction("/login/findPw", params).then((res) => {
            loading.close()
            if (res.success) {
              this.step = 3
            } else {
              this.$message.error("認証メールが期限切れになりました。再操作してください");
              this.$router.replace('/findPw')
            }
          }).catch(res => {
          })
        }
      },
      go_url(path) {
        this.$router.push({path:path})
      },
      go_home() {
        this.$router.push({path:'/'})
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 315px);padding:100px 0 0 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:14px;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:14px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;align-items: center;justify-content: center;margin-top:20px;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content h1{font-size:18px;}
  .input_error {border: 1px rgba(236, 34, 34, 1) solid !important;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;}
  .content .thirdLogin img{margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{margin:0px 16px;min-height: calc(100vh - 400px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
