<template>
  <div>
    <div class="content">
      <div class="title">口座情報</div>

      <div class="items">
        <div class="item">
          <label>名前(口座所有者)</label>
          <div class="line">
            <input v-model="data.bankAccountHolder" placeholder="銀行口座名と同一名前の入力必須"/>
          </div>
        </div>
        <div class="item">
          <label>預金主の身分書類</label>
          <div class="image">
            * 預金主が個人の場合はパスポート身分面のスキャンを、事業者の場合は現地事業者登録証をアップロードしてください
            <a v-if="data.url_name" :href="data.idScanUrl" target="_blank">{{data.idScanName}}</a>
            <upload
              class="avatar-uploader"
              :action="getUploadUrl"
              :headers="headers"
              :show-file-list="false"
              :multiple="false"
              accept="image/*"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <div v-if="data.url_name" class="upload" v-loading="upload_flag"><img src="../assets/img/paper.png">置換ファイル</div>
              <div v-if="!data.url_name" class="upload" v-loading="upload_flag"><img src="../assets/img/paper.png">ファイルの追加</div>
            </upload>
          </div>
        </div>
        <div class="item">
          <label>連絡先</label>
          <div class="line">
            <input v-model="data.phoneNumber" placeholder="国番号を含めて作成"/>
          </div>
        </div>
        <div class="item">
          <label>銀行情報</label>
          <div class="flex">
            <select v-model="data.userBankCountry" @change="change_userCountry">
              <option value="">銀行国家</option>
              <option value="JPN">日本</option>
              <option value="USA">米国</option>
              <option value="CHN">中国</option>
              <option value="KOR">韓国</option>
              <option value="EUR">ヨーロッパ</option>
            </select>
            <div class="flexC">
              <input v-model="data.bankName" placeholder="銀行名を入力してください" @input="change_bankname()"/>
              <label v-if="is_error">{{error_message}}</label>
            </div>
          </div>
        </div>
        <div class="item" v-if="data.userBankCountry == '' || data.userBankCountry == 'JPN'">
          <label></label>
          <div class="flex">
            <select v-model="data.bankAccountType">
              <option value="">口座種類</option>
              <option value="0">普通預金</option>
              <option value="1">当座預金</option>
            </select>
            <div class="flexC">
              <input v-model="data.bankAccountNumber" placeholder="口座番号を入力してください" @input="change_bankNumber()"/>
              <label v-if="is_error_bankNumber">{{error_message_bankNumber}}</label>
            </div>
          </div>
        </div>
        <div class="item" v-if="data.userBankCountry && data.userBankCountry != 'JPN'">
          <label></label>
          <div class="flex">
            <div class="mobile_select" style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
            <div class="flexC">
              <input v-model="data.bankBranchName" placeholder="銀行の支店名を入力してください" @input="change_bankBranchName"/>
              <label v-if="is_error_bankBranchName">{{error_message_bankBranchName}}</label>
            </div>
          </div>
        </div>

        <div class="item" v-if="data.userBankCountry && data.userBankCountry != 'JPN'">
          <label></label>
          <div class="flex">
            <div class="mobile_select" style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
            <div class="flexC">
              <input v-model="data.bankBranchAddress" placeholder="銀行支店の住所を入力してください" @input="change_bankBranchAddress"/>
              <label v-if="is_error_bankBranchAddress">{{error_message_bankBranchAddress}}</label>
            </div>
          </div>
        </div>

        <div class="item" v-if="data.userBankCountry && data.userBankCountry != 'JPN'">
          <label></label>
          <div class="flex">
            <div class="mobile_select" style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
            <div class="flexC">
              <input v-model="data.bankSwiftCode" placeholder="SWIFT CODEを入力してください" @input="change_bankCode"/>
              <label v-if="is_error_bankCode">{{error_message_bankCode}}</label>
            </div>
          </div>
        </div>

        <div class="item" v-if="data.userBankCountry && data.userBankCountry != 'JPN'">
          <label></label>
          <div class="flex">
            <div class="mobile_select" style="width:120px;height:40px;margin-right:30px;flex-shrink: 0;"></div>
            <div class="flexC">
              <input v-model="data.bankAccountNumber" placeholder="口座番号を入力してください" @input="change_bankNumber"/>
              <label v-if="is_error_bankNumber">{{error_message_bankNumber}}</label>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="flex">
            <div :class="is_ok_btn?'btn':'not_btn'" @click="save">保存する</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>口座情報</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Upload } from 'element-ui'
  import { putAction,getAction } from '@/network/manage'
  import { putActionApi } from '@/network/manageApi'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { duplicate } from "network/user"
  export default {
    name: 'Prifile',
    components: {
      Upload
    },
    data() {
      return {
        isUpload:false,
        data:{},
        eng_reg: /^[A-Za-z0-9,-\s]+$/,
        re: /^[,-0-9\s\u30a1-\u30f6\u3041-\u3093\uFF00-\uFFFF\u4e00-\u9fa5]+$/,
        is_error: false,
        error_message: '',
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        upload_flag:false,
        is_error_bankNumber: false,
        error_message_bankNumber: '',
        is_error_bankBranchName: false,
        error_message_bankBranchName: '',
        is_error_bankBranchAddress: false,
        error_message_bankBranchAddress: '',
        is_error_bankCode: false,
        error_message_bankCode: '',
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      getUploadUrl() {
        return process.env.VUE_APP_BASE_API + "/file-upload/upload-public"
      },
      is_ok_btn: function() {
        return (this.data.bankAccountHolder && this.data.bankAccountHolder.length > 0)
        && (this.data.url_name && this.data.url_name.length > 0)
        && (this.data.phoneNumber && this.data.phoneNumber.length > 0)
        && (this.data.userBankCountry && this.data.userBankCountry.length > 0)
        && (this.data.bankName && this.data.bankName.length > 0)
        && ((this.data.userBankCountry == 'JPN' && this.re.test(this.data.bankName))
        || (this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankName)))
        && (this.data.bankAccountNumber && this.data.bankAccountNumber.length > 0
        && ((this.data.userBankCountry == 'JPN' && this.re.test(this.data.bankAccountNumber))
        || (this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankAccountNumber))))
        && ((this.data.userBankCountry == 'JPN' && this.data.bankAccountType && this.data.bankAccountType.length > 0)
        || this.data.userBankCountry != 'JPN')
        && ((this.data.userBankCountry != 'JPN' && this.data.bankBranchName
        && this.data.bankBranchName.length > 0 && this.eng_reg.test(this.data.bankBranchName))
        || this.data.userBankCountry == 'JPN')
        && ((this.data.userBankCountry != 'JPN' && this.data.bankBranchAddress
        && this.data.bankBranchAddress.length > 0 && this.eng_reg.test(this.data.bankBranchAddress))
        || this.data.userBankCountry == 'JPN')
        && ((this.data.userBankCountry != 'JPN' && this.data.bankSwiftCode
        && this.data.bankSwiftCode.length > 0 && this.eng_reg.test(this.data.bankSwiftCode))
        || this.data.userBankCountry == 'JPN');
      }
    },
    created(){
      this.sum_load_data()
    },
    methods:{
      save() {
        let that = this
        if(this.is_ok_btn) {
          let param = {}
          if(this.data.userBankCountry == 'JPN') {
            param = {
              "bank_account_holder_name": this.data.bankAccountHolder,
              "id_scan_key": this.data.idScanUrl,
              "id_scan_name": this.data.idScanName,
              "phone_number": this.data.phoneNumber,
              "country": this.data.userBankCountry,
              "bank_name": this.data.bankName,
              "account_type": this.data.bankAccountType,
              "account_number": this.data.bankAccountNumber,
              "bank_branch_name": "",
              "bank_branch_address": "",
              "swift_code": "",
            }
          } else {
            param = {
              "bank_account_holder_name": this.data.bankAccountHolder,
              "id_scan_key": this.data.idScanUrl,
              "id_scan_name": this.data.idScanName,
              "phone_number": this.data.phoneNumber,
              "country": this.data.userBankCountry,
              "bank_name": this.data.bankName,
              "bank_branch_name": this.data.bankBranchName,
              "bank_branch_address": this.data.bankBranchAddress,
              "swift_code": this.data.bankSwiftCode,
              "account_number": this.data.bankAccountNumber,
              "account_type": ""
            }
          }
          putActionApi("/user-management/update-bank-account", param).then(res => {
            if (res.code == 200) {
              that.$message.success("更新に成功しました")
              that.$router.push("/prifile")
            } else {
              that.$message.error(res.message)
            }
          }).catch(err => {
            that.$message.error(err.message)
          })
        }
      },
      handleAvatarSuccess(res, file) {
        this.data.idScanName = file.name
        this.$set(this.data,'idScanUrl', res.data.upload_path)
        this.$set(this.data,'url_name', this.data.idScanUrl.substring(this.data.idScanUrl.lastIndexOf('/') + 1))
        this.upload_flag = false
        this.$message.success("アップロードに成功しました");
      },
      beforeAvatarUpload(file) {
        const isLt5M = (file.size / 1024 / 1024) < 10;
        if (!isLt5M) {
          this.$message.error('アップロードサイズ：10MBまで！')
        }
        this.upload_flag = true
        return isLt5M
      },
      change_userCountry() {
        if(!this.data.bankName) {
          this.is_error = false
          this.error_message = ''
          return;
        }
        if(this.data.userBankCountry != 'JPN') {
          this.change_bankBranchName()
          this.change_bankBranchAddress()
          this.change_bankNumber()
          this.change_bankname()
          this.change_bankCode()
        } else {
          this.change_bankNumber()
          this.change_bankname()
        }
      },
      change_bankCode() {
        if(this.data.bankSwiftCode == ''
        || this.data.bankSwiftCode == null
        || this.data.bankSwiftCode == undefined) {
          this.is_error_bankCode = false
          this.error_message_bankCode = ''
          return;
        }
        if((this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankSwiftCode))) {
          this.is_error_bankCode = false
          this.error_message_bankCode = ''
          return;
        } else if((this.data.userBankCountry != 'JPN' && !this.eng_reg.test(this.data.bankSwiftCode))){
          this.is_error_bankCode = true
          this.error_message_bankCode = 'SWIFT CODEの場合は英語で入力してください'
          return;
        }
      },
      change_bankBranchName() {
        if(this.data.bankBranchName == ''
        || this.data.bankBranchName == null
        || this.data.bankBranchName == undefined) {
          this.is_error_bankBranchName = false
          this.error_message_bankBranchName = ''
          return;
        }
        if((this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankBranchName))) {
          this.is_error_bankBranchName = false
          this.error_message_bankBranchName = ''
          return;
        } else if((this.data.userBankCountry != 'JPN' && !this.eng_reg.test(this.data.bankBranchName))){
          this.is_error_bankBranchName = true
          this.error_message_bankBranchName = '銀行の支店名の場合は英語で入力してください'
          return;
        }
      },
      change_bankBranchAddress() {
        if(this.data.bankBranchAddress == ''
        || this.data.bankBranchAddress == null
        || this.data.bankBranchAddress == undefined) {
          this.is_error_bankBranchAddress = false
          this.error_message_bankBranchAddress = ''
          return;
        }
        if((this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankBranchAddress))) {
          this.is_error_bankBranchAddress = false
          this.error_message_bankBranchAddress = ''
          return;
        } else if((this.data.userBankCountry != 'JPN' && !this.eng_reg.test(this.data.bankBranchAddress))){
          this.is_error_bankBranchAddress = true
          this.error_message_bankBranchAddress = '銀行支店の住所の場合は英語で入力してください'
          return;
        }
      },
      change_bankNumber() {
        if(this.data.bankAccountNumber == ''
        || this.data.bankAccountNumber == null
        || this.data.bankAccountNumber == undefined) {
          this.is_error_bankNumber = false
          this.error_message_bankNumber = ''
          return;
        }
        if((this.data.userBankCountry == 'JPN' && this.re.test(this.data.bankAccountNumber))
        ||(this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankAccountNumber))) {
          this.is_error_bankNumber = false
          this.error_message_bankNumber = ''
          return;
        } else if(((this.data.userBankCountry == 'JPN' || this.data.userBankCountry == '') && !this.re.test(this.data.bankAccountNumber))){
          this.is_error_bankNumber = true
          this.error_message_bankNumber = '口座番号の場合は半角カタカナを入力してください'
          return;
        } else if((this.data.userBankCountry != 'JPN' && this.data.userBankCountry != '' && !this.eng_reg.test(this.data.bankAccountNumber))){
          this.is_error_bankNumber = true
          this.error_message_bankNumber = '口座番号は数字のみ入力してください。'
          return;
        } else {
          this.is_error_bankNumber = false
          this.error_message_bankNumber = ''
          return;
        }
      },
      change_bankname() {
        if(!this.data.bankName) {
          this.is_error = false
          this.error_message = ''
          return;
        }
        if((this.data.userBankCountry == 'JPN' && this.re.test(this.data.bankName))
        ||(this.data.userBankCountry != 'JPN' && this.eng_reg.test(this.data.bankName))) {
          this.is_error = false
          this.error_message = ''
          return;
        } else if(((this.data.userBankCountry == 'JPN' || this.data.userBankCountry == '') && !this.re.test(this.data.bankName))){
          this.is_error = true
          this.error_message = '日本の銀行の場合は半角カタカナを入力してください'
          return;
        } else if((this.data.userBankCountry != 'JPN' && this.data.userBankCountry != '' && !this.eng_reg.test(this.data.bankName))){
          this.is_error = true
          this.error_message = '銀行名は英語で入力してください'
          return;
        } else {
          this.is_error = false
          this.error_message = ''
          return;
        }
      },
      sum_load_data() {
        const that = this
        getAction("/user/member/personalBasicInfo").then(res => {
          if (res.success) {
            this.data = res.result
            if(!this.data.userBankCountry) {
              this.data.userBankCountry = ''
            }
            if(!this.data.bankAccountType) {
              this.data.bankAccountType = ''
            }
            if(this.data.idScanUrl)
              this.data.url_name = this.data.idScanUrl.substring(this.data.idScanUrl.lastIndexOf('/') + 1);
          } else {
            that.$message.error(res.message)
          }
        }).catch(err => {
          that.$message.error(err.message)
        })
      },
    }
  }
</script>

<style scoped>
*{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
input::placeholder {
   color: rgba(1, 56, 139, 0.46);
}
.content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 215px);}
.title{margin:30px 0;}
.items{width:80%;display: flex;flex-direction: column;}
.item{display: flex;align-items: center;width:100%;margin-bottom:30px;align-items: baseline;}
.item label{color:#888888;width:240px;flex-shrink: 0;text-align: left;}
.item .image{text-align: left;color:rgba(1, 56, 139, 0.46);display: flex;flex-direction: column;}
.item .image .upload{background: #ECF9FB;border-radius: 2px;width:180px;cursor: pointer;height:46px;margin-top:10px;display: flex;align-items: center;justify-content: center;color:#38C0CE;}
.item .image .upload img{margin-right:6px;}
.item .image a{color: #1192CA;text-decoration: underline;margin-top:10px;cursor: pointer;}
.item .line{width:100%;display: flex;border-bottom:1px solid #aaaaaa;padding:10px 0;align-items: center;}
.item .line input{width:100%;height:40px;padding:0 10px;border:none;}
.item .line img{width:20px;height:20px;margin:8px 16px;flex-shrink: 0;}
.item .line p{width:100%;word-break: break-all;height:24px;overflow: hidden;text-align: left;}
.item .btn{box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);margin:30px 0;cursor: pointer;flex-shrink: 0;background: #38C0CE;border-radius: 40px;width:130px;height:36px;display: flex;justify-content: center;align-items: center;color:#ffffff;}
.flex{display: flex;width:100%;justify-content: center;}
.flex select{border:none;border-bottom:1px solid #aaaaaa;height:40px;width:120px;text-align:center;margin-right:30px;flex-shrink: 0;color:#01388B;}
.flex input{border:none;width:100%;border-bottom:1px solid #aaaaaa;height:40px;padding:0 10px;}
.flexC{display: flex;width:100%;flex-direction: column;}
.flexC label{color:#EC2222;width:100%;font-size:14px;padding:10px;}
.paths{display: flex;padding:36px 0;}
.paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
.paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
.paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
.paths a{font-size:13px;color:#4A4A4A;}
.not_btn {box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);margin:30px 0;flex-shrink: 0;border-radius: 40px;width:130px;height:36px;display: flex;justify-content: center;align-items: center;background:rgba(208, 208, 208, 1);color:#ffffff;}
@media (max-width: 800px) {
  .content{margin:0 16px;}
  .pc{display: none !important;}
  .title{text-align: left;width:100%;margin:20px 0 !important;}
  .items{width:100%;}
  .line{padding:0 !important;}
  .item{flex-direction: column;}
  .item input{padding:0 !important;}
  .flex select{width:auto;width:100px;margin-right:15px;}
  .item .btn{font-size:14px;height:30px;width:120px;}
  .item .image{font-size:12px;margin-top:10px;}
  .item .image .upload{width:140px;height:40px;font-size:14px;}
  .nav {width: 100%;padding: 50px 20px 30px 20px;}
  .mobile_select {width:100px !important;margin-right:15px !important;}
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .content{width:800px;margin:auto;}
  .mobile{display: none !important;}
  .nav {width: 800px;margin: auto;}
}
@media(min-width: 1200px){
  .content{width:1200px;margin:auto;}
  .mobile{display: none !important;}
  .nav {width: 1200px;margin: auto;}
}
</style>
