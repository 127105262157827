import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Notification, MessageBox } from 'element-ui'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 3600000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 403:
        Notification.error({ title: 'ヒント', message: 'サーバのアクセス拒否' })
        break
      case 500:
        Notification.error({ title: 'ヒント', message: '内部サーバエラー' })
        break
      case 404:
        Notification.error({ title: 'ヒント', message: '申し訳ありませんが、要求されたリソースが見つかりません' })
        break
      case 504:
        Notification.error({ title: 'ヒント', message: '要求タイムアウト、後で再試行してください' })
        break
      case 401:
        store.dispatch('Logout')
        MessageBox.confirm('申し訳ありませんが、ログインステータスは期限切れです。再ログインしてください', 'ヒント', {
          confirmButtonText: 'ログイン',
        }).then(() => {
          window.location.href='/login';
        })
        break
      default:
        // Notification.error({title: 'ヒント', message: error.message })
        break
    }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
