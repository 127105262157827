<template>
  <div id="register">

    <div class="win" v-if="winForm">
      <div class="con">
        <div class="close" style="margin-bottom: 20px;"><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
        <div class="tls">あなたのIDがブロックされています</div>
        <div class="tls">何かご要望がありましたら</div>
        <div class="tls">メールにてご連絡ください</div>
        <div class="tls" style="color:rgba(1, 56, 139, 1);text-decoration: underline;margin-top: 25px;">admin@3d-item.com</div>
        <div class="btn" @click="go_home">確認</div>
      </div>
    </div>

    <div class="content" v-if="step == 1">
      <div class="mobile" style="margin-bottom: 85px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 55px;font-size: 20px;">
        ログインするまでお待ちください
      </div>
    </div>

    <div class="content" v-if="step == 2">
      <div class="mobile" style="margin-bottom: 20px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 30px;font-size: 20px;">
        新規会員登録
      </div>
      <div style="font-size: 13px;text-align: left;margin:0 0 7px 0;">連動アカウント</div>
      <div class="input" style="background:rgba(240, 240, 240, 1);align-items: center;justify-content: flex-start;display: flex;">
        <img src="../assets/img/line-login-icon.png" style="width:25px;"/><span style="margin-left:30px;color: rgba(0, 0, 0, 1);">{{memberObj.name}}</span>
      </div>

      <div style="font-size: 13px;text-align: left;margin:16px 0 7px 0;">E-mail</div>
      <input class="input" placeholder="メールアドレスを入力してください"
       v-model="email" @input="detect_email_change"/>

       <div style="font-size: 13px;text-align: left;margin:7px 0 0 0;color:rgba(236, 34, 34, 1);margin-bottom: 20px;">
       {{email_error_message}}
       </div>

      <div style="font-size: 13px;text-align: left;margin:16px 0 7px 0;">ユーザーネーム</div>
      <input class="input" placeholder="英数字10文字以内、作家名としても使用されます"
       v-model="nickname" @input="detect_nickname_changes"/>

       <div style="font-size: 13px;text-align: left;margin:7px 0 0 0;color:rgba(236, 34, 34, 1);margin-bottom: 20px;">{{nickname_error_message}}</div>

      <div class="check"><input type="checkbox" v-model="ty_stauts_one"/>利用規約、プライバシーポリシーに同意する<a>（必須）</a></div>
      <div class="check"><input type="checkbox" v-model="ty_stauts_two"/>情報及びマーケティング利用に同意する（任意）</div>

      <div :class="'btn' + (is_ok_btn?' ok':'')" @click="reset_btn" style="margin-top:40px;">登録</div>
    </div>

    <div class="content" v-if="step == 3">
      <div class="mobile" style="margin-bottom: 15px;" @click="go_home()">
        <img src="../assets/img/logo.png" style="width:50px;"/>
      </div>
      <div style="margin-bottom: 65px;font-size: 20px;">
        認証メールを送信しました
      </div>
      <div class="title pc" style="margin-bottom: 30px;font-size: 15px;width:600px;">
        入力したメールアカウントにログインして認証を完了してください。
      </div>
      <div class="title mobile" style="margin-bottom: 30px;font-size: 15px;">
        入力したメールアカウントにログインして認証を完了してください。
      </div>

      <div class="btn" style="background:rgba(236, 249, 251, 1);color:#000000;">{{this.email}}</div>

      <div class="title" style="margin-top: 20px;font-size: 13px;color:#888888;">
        認証URLの有効期限は30分です。
      </div>
    </div>


    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>新規会員登録</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction,getAction } from '@/network/manage'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from '@/store/mutation-types'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        step: 1,
        memberObj: {},
        email: '',
        email_error_message: '',
        email_error_status: false,
        nickname: '',
        nickname_error_message: '',
        nickname_error_status: false,
        ty_stauts_one: false,
        ty_stauts_two: false,
        g_access_token: '',
        winForm: false,
        emailReg: /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/,
        timer_emial: null,
        timer_nickname: null,
      }
    },
    computed: {
      is_ok_btn: function() {
        return (this.nickname_error_status && this.ty_stauts_one && this.email_error_status)
      }
    },
    created() {
      let code = this.$route.query.code
      if(code) {
        let param = {code: code}
        const loading = this.$loading.service()
        postAction("/login/lineLoginVerify", param).then((res) => {
          loading.close()
          if (res.success) {
            if(res.result.status == 200) {
              Vue.ls.set(ACCESS_TOKEN, res.result.token, 24 * 60 * 60 * 1000)
              this.$message.success("ログイン成功");
              window.location.href = "/"
            } else {
              this.memberObj = res.result
              this.step = 2
            }
          } else {
            if(res.code == 502) {
              //被锁定
              this.winForm = true
            } else {
              this.$message.error("Lineログインのタイムアウト、再試行してください");
              this.$router.replace('/login')
            }
          }
        }).catch(res => {
        })
      } else {
        this.$router.replace('/')
      }
    },
    methods: {
      detect_email_change(e) {
        this.email_error_status = false
        clearTimeout(this.timer_emial);
        this.timer_emial = setTimeout(() => {
          this.check_email()
        }, 500);
      },
      async check_email() {
        if(this.emailReg.test(this.email)) {
          let res = await getAction("/login/lineCheckEmailIsCanUse?email=" + this.email)
          if(res.success) {
            this.email_error_message = ""
            this.email_error_status = true
          } else {
            this.email_error_message = "使用不可メール: 他のアカウントと連動したメールアドレスです。"
            this.email_error_status = false
          }
        } else {
          this.email_error_message = "メールアドレスの形式が正しくありません"
          this.email_error_status = false
        }
      },
      detect_nickname_changes(e) {
        this.nickname_error_status = false
        clearTimeout(this.timer_nickname);
        this.timer_nickname = setTimeout(() => {
          this.check_nickname()
        }, 500);
      },
      async check_nickname() {
        let reg= /^[\da-zA-Z]{1,10}$/;
        if(reg.test(this.nickname)) {
          let res = await getAction("/login/checkNicknameToReginRedis?nickname=" + this.nickname)
          if(res.success) {
            this.nickname_error_status = true
            this.nickname_error_message = ''
          } else {
            this.nickname_error_status = false
            this.nickname_error_message = 'この名前はすでに登録されています。他の名前をお試しください。'
          }
        } else {
          this.nickname_error_status = false
          this.nickname_error_message = '英数字10文字以内、作家名としても使用されます'
        }
      },
      reset_btn() {
        if(this.is_ok_btn) {
          const loading = this.$loading.service()
          const that = this
          let params = {key: this.memberObj.key, nickname: this.nickname, email:this.email}
          postAction("/login/lineLoginVerifyEmail", params).then((res) => {
            loading.close()
            if (res.success) {
              if(res.result.status == 200) {
                Vue.ls.set(ACCESS_TOKEN, res.result.token, 24 * 60 * 60 * 1000)
                window.location.href = "/"
              } else {
                this.step = 3
              }
            } else {
              if(res.code == 502) {
                this.nickname_error_message = 'この名前はすでに登録されています。他の名前をお試しください。'
              } else if(res.code == 503) {
                this.winForm = true
              } else if(res.code == 504) {
                this.email_error_message = '使用不可メール: 他のアカウントと連動したメールアドレスです。'
              } else {
                this.$message.error("Lineログインのタイムアウト、再試行してください");
                this.$router.replace('/login')
              }
            }
          }).catch(res => {
          })
        }
      },
      go_url(path) {
        this.$router.push({path:path})
      },
      go_home() {
        this.$router.push({path:'/'})
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .content{display: flex;flex-direction: column;align-items: center;text-align: center;min-height: calc(100vh - 225px);padding:80px 0 0 0;}
  .content .input{width:100%;height:50px;border:1px solid rgba(72, 83, 100, 0.46);border-radius: 2px;padding:0 20px;}
  .content .input::placeholder{color:rgba(1, 56, 139, 0.46);}
  .content .msg{height:30px;line-height:30px;text-align: left;color: #EC2222;font-size:14px;}
  .content div{width:100%;}
  .content .btn{background: #D0D0D0;border-radius: 2px;width:100%;display: flex;justify-content: center;align-items: center;color:#ffffff;height:50px;}
  .content .checkDiv{margin:20px;display: flex;align-items: center;font-size:14px;}
  .content .checkDiv input{width:20px;height:20px;margin-right:10px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .link{display: flex;align-items: center;justify-content: center;margin-top:20px;}
  .content .link a{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;cursor: pointer;}
  .content .link span{color:rgba(1, 56, 139, 0.46);margin-right:6px;font-size:14px;}
  .content .line{border-top:1px solid #F0F0F0;margin:40px;}
  .content .check{display: flex;margin:15px 0 0px 0;align-items: center;font-size:13px;}
  .content .check input{width:20px;height:20px;margin-right:5px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .content .check a{color:#EC2222;}
  .content h1{font-size:18px;}
  .input_error {border: 1px rgba(236, 34, 34, 1) solid !important;}
  .content .thirdLogin{margin-top:24px;border:1px solid rgba(72, 83, 100, 0.46);width:100%;height:50px;display: flex;align-items: center;padding:0 20px;}
  .content .thirdLogin img{margin-right:10px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .ok{background: #38C0CE !important;cursor: pointer;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px 100px;}
  .win .con .close{display: flex;justify-content: flex-end;width:100%;}
  .win .con .close img{width:20px;cursor: pointer;}
  .win .con .tls{font-size:15px;font-weight: bold;margin-top:0px;text-align: center;color:#4A4A4A;}
  .win .con p{width:100%;}
  .win .con .btn{display: flex;margin-top:30px;justify-content: center;align-items: center;color:#ffffff;width:300px;height:50px;background-color: rgba(56, 192, 206, 1);border-radius: 2px;margin-bottom:20px;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{margin:0px 16px;min-height: calc(100vh - 380px);padding: 0;}
    .pc{display: none !important;}
    .content .btn{width:100%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 400px;margin: auto;}
  }
  @media(min-width: 1200px) {
    .nav {width: 1200px;margin: auto;}
  	.content{width:400px;margin:auto;}
    .mobile{display: none !important;}
  }
</style>
