<template>
  <div id="home" v-if="nftObj != null">
    <div class="win" v-if="payLoadingForm">
      <div class="con" v-loading="true" element-loading-text="お支払い中、お待ちください" element-loading-spinner="el-icon-loading">
      </div>
    </div>
    <div class="content mai">
			<div class="left">
				<div class="goodsImgBanner">
					<div class="imgShow" :style="actIndex==0?nftObj.thumbnail.urlBG:nftObj.sub_images[actIndex-1].urlBG">
              <div class="sales" v-if="is_promotion">
                {{nftObj.discount}}%OFF <span>~ {{nftObj.pEndTimeShow}}</span>
              </div>
          </div>
					<div class="gallery-thumbs">
						<div class="cont">
						  <div class="swiperpager">
							<div class="bg">
							  <div class="swiper-button-prev"></div>
							</div>
							<div class="bg">
							  <div class="swiper-button-next"></div>
							</div>
						  </div>
						  <swiper :options="swiperOptions">
							<swiper-slide>
							  <img :class="actIndex==0?'act':''" @click="changeactIndex(0)" :src="nftObj.thumbnail.image_url">
							</swiper-slide>
							<swiper-slide v-for="(item,i) in nftObj.sub_images" :key="i">
							  <img :class="actIndex== i + 1 ? 'act' : ''" :src="item.image_url" @click="changeactIndex(i+1)"/>
							</swiper-slide>
						  </swiper>
						</div>
					</div>

				</div>
				<h1 class="pc">プロジェクトのご紹介</h1>
				<pre class="pc">{{nftObj.content_short_description}}</pre>
				<div class="tit pc">作品内容</div>
        <div class="terms_nr">
          <div class="desc pc" v-html="nftObj.introduction"></div>
        </div>
        <h1 class="pc">制作時参考PC仕様</h1>
        <pre class="pc">{{nftObj.notice_description}}</pre>
			</div>
			<div class="right">
				<h1>{{nftObj.content_title}}</h1>
				<div class="userSpan">
					<i :style="nftObj.sellerProfileImg"></i>
					<a style="color:rgba(1, 56, 139, 1);text-decoration: underline;cursor: pointer;" @click="go_artist_home(nftObj.creator_uuid)">{{nftObj.nick_name}}</a>
				</div>
				<div class="skp">{{nftObj.main_contents_name}} : <font style="color:rgba(1, 56, 139, 0.46);font-family: NotoSansJP-ExtraLight;">{{nftObj.main_contents_size}} MB</font></div>
				<div class="tags">
					<span v-for="(item,i) in type_datas" :key="i">{{item}}</span>
				</div>
				<div class="ops">
					<div :class="nftObj.like ? 'act' : 'likeSpan'" @click="like_item">お気に入り追加</div>
          <div class="added" v-if="nftObj.basket" @click="cart_item">追加中</div>
					<div v-else class="cartSpan" @click="cart_item">カートへ追加</div>
				</div>
				<div class="btn"  @click="buy_now" v-if="nftObj.content_status == 'on_sale'">
					<div >
						<h2 v-if="is_promotion"><span style="text-decoration-line: line-through;text-decoration-style: double;">¥{{numberFormat(nftObj.price)}}</span> >> ¥{{numberFormat(nftObj.promotional_price)}} <font style="font-size:15px;">購入する</font></h2>
            <h2 v-else>¥{{numberFormat(nftObj.price)}} <font style="font-size:15px;">購入する</font></h2>
					</div>
				</div>
        <div class="btn" style="background-color: rgb(136, 136, 136);" v-else>
          <div>
          	<h2>販売待機中</h2>
          </div>
        </div>
				<div class="rightBanner pc">
          <swiper :options="swiperOptions2">
          	<swiper-slide v-for="(item,i) in banner" :key="i">
              <a class="img" :href="item.urlTo" :style="item.contentUrl_bc"></a>
            </swiper-slide>
          	<div class="swiper-pagination" slot="pagination"></div>
          </swiper>
				</div>
				<div class="divLine mobile"></div>
				<div class="mobileDetail mobile">
					<h1>プロジェクトのご紹介</h1>
					<pre>{{nftObj.content_short_description}}</pre>
					<div class="tit">作品内容</div>
          <div class="terms_nr">
            <div class="desc" v-html="nftObj.introduction"></div>
          </div>
          <h1>制作時参考PC仕様</h1>
          <pre>{{nftObj.notice_description}}</pre>
				</div>
			</div>
		</div>
    <div class="divLine mobile"></div>
    <div class="mobile mobileBanner">
      <swiper :options="swiperOptions3">
        <swiper-slide v-for="(item,i) in banner_mobile" :key="i">
          <a class="img" :href="item.urlTo" :style="item.contentUrl_bc"></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
		<div class="bottomDiv"></div>
		<div class="content paths pc">
			<i class="home" @click="go_home"></i>
			<i class="jt"></i>
			<a>{{nftObj.nick_name}}の作品</a>
			<i class="jt"></i>
			<a>{{nftObj.content_title}}</a>
		</div>
    <div class="content paths mobile">
      <a class="home" @click="go_home">　</a>
    	<a>{{nftObj.nick_name}}の作品</a>
    	<a>{{nftObj.content_title}}</a>
    </div>
    <form id="pay_form" :action="pay_url" method="POST">
      <input type="hidden" name="pay_method" id="pay_method"/>
      <input type="hidden" name="merchant_id" id="merchant_id"/>
      <input type="hidden" name="service_id" id="service_id"/>
      <input type="hidden" name="cust_code" id="cust_code"/>
      <input type="hidden" name="sps_cust_no"/>
      <input type="hidden" name="sps_payment_no"/>
      <input type="hidden" name="order_id" id="order_id"/>
      <input type="hidden" name="item_id" id="item_id"/>
      <input type="hidden" name="pay_item_id"/>
      <input type="hidden" name="item_name" id="item_name"/>
      <input type="hidden" name="tax"/>
      <input type="hidden" name="amount" id="amount"/>
      <input type="hidden" name="pay_type" id="pay_type"/>
      <input type="hidden" name="auto_charge_type"/>
      <input type="hidden" name="service_type" id="service_type"/>
      <input type="hidden" name="div_settele"/>
      <input type="hidden" name="last_charge_month"/>
      <input type="hidden" name="camp_type"/>
      <input type="hidden" name="tracking_id"/>
      <input type="hidden" name="terminal_type"/>
      <input type="hidden" name="success_url" id="success_url"/>
      <input type="hidden" name="cancel_url" id="cancel_url"/>
      <input type="hidden" name="error_url" id="error_url"/>
      <input type="hidden" name="pagecon_url" id="pagecon_url"/>
      <input type="hidden" name="free1"/>
      <input type="hidden" name="free2"/>
      <input type="hidden" name="free3"/>
      <input type="hidden" name="free_csv"/>
      <input type="hidden" name="request_date" id="request_date"/>
      <input type="hidden" name="limit_second"/>
      <input type="hidden" name="sps_hashcode" id="sps_hashcode"/>
      <input type="hidden" name="dtl_rowno"/>
      <input type="hidden" name="dtl_item_id"/>
      <input type="hidden" name="dtl_item_name"/>
      <input type="hidden" name="dtl_item_count"/>
      <input type="hidden" name="dtl_tax"/>
      <input type="hidden" name="dtl_amount"/>
      <input type="hidden" name="dtl_free1"/>
      <input type="hidden" name="dtl_free2"/>
      <input type="hidden" name="dtl_free3"/>
    </form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import { swiper, swiperSlide} from "vue-awesome-swiper"
  import 'swiper/css/swiper.css'
  import { getActionApi,deleteActionApi,postActionApi } from '@/network/manageApi'
  import { getAction, postAction} from '@/network/manage'
  import numeral from 'numeral'
  import moment from 'moment'
  export default {
    name: 'ContentsHome',
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        url: {
          detail: '/contents/general-contents/detail/',
          banner: '/home/searchBanner',
          like:'/like/like/',
          cart:'/shopping-cart/shopping-cart/'
        },
        payLoadingForm: false,
        nftObj:{thumbnail:{}},
        is_promotion: false,
        type_datas:[],
        banner:[],
        banner_mobile:[],
        orderData: {},
        spage:1,
        actIndex:0,
        pay_url: process.env.VUE_APP_PAY_URL,
        swiperOptions:{
          slidesPerView: 5,
          spaceBetween: 10,
          slidesPerGroup: 5,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
        swiperOptions2:{
          loop: true,
          speed: 1000,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        },
        swiperOptions3:{
          loop: true,
          speed: 1000,
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'token',
        'uuid'
      ])
    },
    created(){
      this.listData();
      this.loadBanner(3, 0, 'banner');
      this.loadBanner(3, 1, 'banner_mobile');
    },
    methods:{
      like_item(){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.like_item_function();
        } else {
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'Login',
            callback: (action, instance) => {
              if (action === 'confirm') {
                that.$router.push({path:'/login'})
              }
            }
          })
        }
      },
      like_item_function(){
        if(this.nftObj.creator_uuid == this.uuid) {
          this.$notification.error({title: 'システムメッセージ', message: "自分の作品は収蔵できない" })
          return;
        }
        const that = this;
        if(that.nftObj.like){
          deleteActionApi(that.url.like + this.nftObj.contents_uuid).then((res) => {
            if (res.code == 200) {
              that.nftObj.like = false;
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
          })
        } else {
          postActionApi(that.url.like + this.nftObj.contents_uuid).then((res) => {
            if (res.code == 200) {
              that.nftObj.like = true;
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
          })
        }
      },
      buy_now() {
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.go_pay();
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'Login',
            callback: (action, instance) => {
              if (action === 'confirm') {
                that.$router.push({path:'/login'})
              }
            }
          })
        }
      },
      go_pay() {
        const that = this;
         if(this.nftObj.creator_uuid == this.uuid) {
          this.$notification.error({title: 'システムメッセージ', message: "自分の作品は購入できません" })
          return;
        }
        if(this.nftObj.content_status != 'on_sale') {
          that.$message.warning("販売待機中");
          return;
        }
        const loading = this.$loading.service();
        postAction("/user/member/nowOrderPay", {contentUuid: this.nftObj.contents_uuid}).then((res) => {
          if (res.success) {
            if(res.result.amount == 0) {
              this.$message.success("購入に成功しました~");
              this.$router.push({path:'purchaseHistory'})
            } else {
              this.payLoadingForm = true
              this.orderData = res.result
              document.getElementById("pay_method").value = this.orderData.pay_method
              document.getElementById("merchant_id").value = this.orderData.merchant_id
              document.getElementById("service_id").value = this.orderData.service_id
              document.getElementById("cust_code").value = this.orderData.cust_code
              document.getElementById("order_id").value = this.orderData.order_id
              document.getElementById("item_id").value = this.orderData.item_id
              document.getElementById("item_name").value = this.orderData.item_name
              document.getElementById("amount").value = this.orderData.amount
              document.getElementById("pay_type").value = this.orderData.pay_type
              document.getElementById("service_type").value = this.orderData.service_type
              document.getElementById("success_url").value = this.orderData.success_url
              document.getElementById("cancel_url").value = this.orderData.cancel_url
              document.getElementById("error_url").value = this.orderData.error_url
              document.getElementById("pagecon_url").value = this.orderData.pagecon_url
              document.getElementById("request_date").value = this.orderData.request_date
              document.getElementById("sps_hashcode").value = this.orderData.sps_hashcode
              document.getElementById("pay_form").submit();
            }
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
        }).finally(res => {
          loading.close();
        })
      },
      cart_item(){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.cart_item_function();
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'Login',
            callback: (action, instance) => {
              if (action === 'confirm') {
                that.$router.push({path:'/login'})
              }
            }
          })
        }
      },
      cart_item_function(){
        const that = this;
         if(this.nftObj.creator_uuid == this.uuid) {
          this.$notification.error({title: 'システムメッセージ', message: "自分の作品は購入できません" })
          return;
        }
        if(this.nftObj.content_status != 'on_sale') {
          that.$message.warning("販売待機中");
          return;
        }
        if(!this.nftObj.basket) {
          const loading = this.$loading.service();
          postActionApi(that.url.cart + this.nftObj.contents_uuid).then((res) => {
            if (res.code == 200) {
              that.nftObj.basket = true
              that.$message.success("追加成功");
              that.$store.dispatch('UpdateCartNumber');
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
          }).finally(res => {
            loading.close();
          })
        } else {
          that.$message.success("同作品はカートに組み込まれている");
        }
      },
      changeactIndex(ind){
        this.actIndex = ind;
      },
      numberFormat(txt){
        return numeral(txt).format('0,0')
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      go_artist_home(id) {
        this.$router.push({path:'artistProfile',query:{id: id}})
      },
      loadBanner(lo, type, data){
        const that = this;
        getAction(this.url.banner,{lo,type}).then((res) => {
          if (res.success) {
            var list = res.result;
            for(var i = 0; i < list.length; i++){
              if(list[i].bannerType === 0){
                list[i].contentUrl_bc = "background-image:url('"+list[i].contentUrl+"')";
              }
            }
            eval('that.' + data + '=list');
          }
        })
      },
      async listData(){
        const that = this;
        let res = await getActionApi(this.url.detail + this.$route.query.id)
        if (res.code == 200) {
          if(res.data.content_status != 'on_sale') {
            that.$message.warning("作品販売待機中");
            that.$router.replace('/home')
            return;
          }
          var obj = res.data;
          if(obj.profile_picture != null)
            obj.sellerProfileImg = "background-image:url('" + obj.profile_picture + "')";
          if(obj.thumbnail) {
              obj.thumbnail.urlBG = "background-image:url('" + obj.thumbnail.image_url + "')";
          }
          for(var i = 0;i<obj.sub_images.length;i++){
            obj.sub_images[i].urlBG = "background-image:url('"+obj.sub_images[i].image_url+"')";
          }
          this.type_datas = obj.main_content_file_type.split(",")
          let time = await getAction("/login/getTime")
          let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
          if(obj.content_status == 'on_sale' && obj.promotion_active) {
              if(obj.promotion_start_datetime && obj.promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj.promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj.promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    this.is_promotion = true
                    obj.discount = Math.floor(100 - (obj.promotional_price/obj.price)*100)
                    obj.pEndTimeShow = moment(obj.promotion_end_datetime).format('YYYY.MM.DD')
                  }
              }
          }
          this.nftObj = obj;
        }
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;width:300px;height:200px;}
  .sales{background-color: rgba(236, 34, 34, 0.7);font-size:15px;color:#ffffff;position: absolute;padding:7px 15px;display: flex;align-items: center;}
  .paths{display: flex;padding:36px 0;}
  .mobileBanner{padding:20px;}
  .mobileBanner .img{width:100%;height:calc(83.33vw - 33.33px);display: block;background-size: cover;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 18px;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;background-size: 18px;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;background-size: 18px;}
  .paths a{font-size:13px;color:#4A4A4A;background-size: 18px;}
  .mai{display: flex;align-items:flex-start;justify-content: space-between;}
  .swiperpager{display: flex;position: absolute;height:0;justify-content: space-between;z-index:100;}
  .swiperpager .bg{background: rgba(255,255,255,0.5);padding:0 6px;display: flex;justify-content: center;align-items: center;}
  .swiperpager .swiper-button-prev{position: static;filter: grayscale(100%);transform: scale(0.6);}
  .swiperpager .swiper-button-next{position: static;filter: grayscale(100%);transform: scale(0.6);}
  .swiperpager .swiper-button-prev.swiper-button-disabled {opacity: 0.15;}
  .swiperpager .swiper-button-next.swiper-button-disabled {opacity: 0.15;}
  .mai .left .goodsImgBanner .imgShow{width:100%;background-size: cover;background-position: center;}
  .mai .left .goodsImgBanner .gallery-thumbs {height: 128px;padding: 10px 0;overflow: hidden;}
  .mai .left .goodsImgBanner .gallery-thumbs .cont{width:100%;}
  .mai .left .goodsImgBanner .pager div{position: absolute;margin-top:10px;z-index: 2;display: flex;cursor: pointer;}
  .mai .left .goodsImgBanner .pager div i{background: rgba(255,255,255,0.5);background-repeat: no-repeat;background-position: center;}
  .mai .left .goodsImgBanner .pager .last i{transform: rotate(180deg);}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide {}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {display: block;width: 100%;object-fit: cover;opacity: 0.4;}
  .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide .act {border:2px solid #000000;opacity: 1;}
  .mai .left h1{font-size: 20px;margin:60px 0 40px 0;font-weight: normal;}
  .mai .left p{font-size:15px;line-height:2;margin-bottom:40px;}
  .mai .left pre{font-size:15px;line-height:2;margin-bottom:40px;white-space: pre-wrap;word-wrap: break-word;}
  .mai .left .tit{font-size: 20px;margin-bottom:30px;}
  .mai .left .desc{display: flex;flex-direction: column;word-break: break-all;}
  .mai .left .desc img{max-width: 100%;}
  .mai .left .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
  .mai .right h1{font-size: 24px;line-height:1.8;margin-bottom:16px;font-weight: normal;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .mai .right .userSpan{display: flex;align-items: center;font-size:14px;}
  .mai .right .userSpan i{width:28px;height:28px;background-image: url(../assets/img/userGray_cur.png);border-radius: 50%;background-size:cover;background-position:center;margin-right:10px;border: 1px solid #dedede;}
  .mai .right .link{color: #00C999;text-decoration-line: underline;font-size: 14px;margin:16px 0;}
  .mai .right .roy{color: #4A4A4A;text-decoration-line: underline;font-size: 15px;margin:16px 0;}
  .mai .right .skp{color: #4A4A4A;font-size: 15px;margin:25px 0;}
  .mai .right .tags{display: flex;flex-wrap: wrap;}
  .mai .right .tags span{background: #F7F7F7;border-radius: 2px;padding: 6px 10px;color: #4A4A4A;font-size:14px;margin:0 10px 10px 0;font-family: NotoSansJP-ExtraLight;}
  .mai .right .ops{display: flex;align-items: center;margin:16px 0;}
  .mai .right .ops div{width:100%;background-position: left center;background-repeat: no-repeat;padding-left:30px;font-size: 15px;}
  .mai .right .ops .likeSpan{background-image:url(../assets/img/blackHeart.png);cursor: pointer;background-size: 20px;}
  .mai .right .ops .act{background-image:url(../assets/img/liked.png);cursor: pointer;background-size: 20px;}
  .mai .right .ops .cartSpan{background-image: url(../assets/img/cart.png);cursor: pointer;background-size: 20px 18px;}
  .mai .right .ops .added{background-image: url(../assets/img/cart_added.png);cursor: pointer;background-size: 20px 18px;color: #38C0CE;}
  .mai .right .btn{width:100%;height:56px;background: rgba(56, 192, 206, 1);cursor: pointer;margin:30px 0 30px 0;border-radius: 2px;color:#ffffff;display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .mai .right .btn span{font-size: 16px;}
  .mai .right .btn div{display: flex;justify-content: center;align-items: center;}
  .mai .right .btn div h2{font-weight: normal;font-size: 19px;}
  .mai .right .btn div h3{font-weight: normal;font-size: 15px;margin-left:10px;margin-right:4px;}
  .mai .right .btn div h4{font-weight: normal;font-size: 14px;}
  .mai .rightBanner .swiper-slide{display: flex;}
  .mai .rightBanner .img{width:100%;background-size: cover;background-position: center;background-color: #f7f7f7;}
  .terms_nr >>>table {border: none;border-collapse: collapse;}
  .terms_nr >>>table tr td{
   border: 1px solid #ccc !important;
   padding: 3px 5px;
   min-width: 50px;
   height: 20px;
  }
  .terms_nr >>>img {
    max-width: 100%;
  }
  @media (max-width: 800px) {

    .paths{display: block;padding:20px;}
    .paths a{padding-left:22px;background-image: url(../assets/img/nav_right.png);background-repeat: no-repeat;background-position: left 2px center;background-size: 15px;}
  	body{padding-top:60px;}
    .paths .home{background-image: url(../assets/img/home.png);background-position: left 0 center;}
  	.mai{flex-direction: column;width:100vw;}
  	.mai .left .goodsImgBanner{width:100vw;}
  	.mai .left .goodsImgBanner .imgShow{width:100vw;height:304px;}
  	.mai .left .goodsImgBanner .gallery-thumbs{padding:2vw;overflow: hidden;height:auto;}
  	.mai .left .goodsImgBanner .mobile{}
  	.mai .left .goodsImgBanner .mobile div{margin-top: 2vw;}
  	.mai .left .goodsImgBanner .mobile div i{width:30px;height:16.07vw;}
  	.mai .left .goodsImgBanner .mobile .last{left:2vw;}
  	.mai .left .goodsImgBanner .mobile .next{right:2vw;}
    .swiperpager{width:96vw;}
    .swiperpager .bg{padding:0 2px;height:12vw;}
    .swiperpager .swiper-button-prev{transform: scale(0.4);}
    .swiperpager .swiper-button-next{transform: scale(0.4);}
  	.mai .right{width:100vw;}
  	.mai .right h1{margin:20px 4vw;font-size:22px;}
  	.mai .right .userSpan{margin:0 4vw;}
  	.mai .right .link{margin:10px 4vw;}
  	.mai .right .roy{margin:10px 4vw;}
  	.mai .right .skp{margin:10px 4vw;}
  	.mai .right .tags{margin:10px 4vw;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img{height:12vw;}
  	.mai .right .ops{position: fixed;width:100vw;z-index:1000;bottom:56px;left:0px;background-color: #ffffff;height:40px;justify-content: center;margin:0px;}
  	.mai .right .ops div{width:auto;}
  	.mai .right .ops div:nth-child(1){margin-right:30px;}
  	.mai .right .btn{position: fixed;width:100vw;z-index:1000;bottom:0px;left:0px;height:56px;margin:0px;}
  	.divLine{background: #F0F0F0;height:8px;}
  	.mai .mobileDetail{margin:0px 4vw;}
  	.mai .mobileDetail h1{font-size: 18px;margin:20px 0;font-weight: normal;}
  	.mai .mobileDetail p{font-size:14px;line-height:2;margin:10px 0;}
    .mai .mobileDetail pre{font-size:14px;line-height:2;margin:10px 0;white-space: pre-wrap;word-wrap: break-word;}
  	.mai .mobileDetail .tit{font-size: 18px;margin:30px 0 20px 0;}
  	.mai .mobileDetail .desc{margin:20px 0;display: flex;flex-direction: column;word-break: break-all;}
  	.mai .mobileDetail .desc img{max-width: 100%;}
  	.mai .mobileDetail .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
  	.pc{display: none !important;}
  	.content{margin:0;width:100vw;}
  	.bottomDiv{height:100px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mai{padding-top:20px;}
  	.mai .left{width:528px;}
    .swiperpager{width:528px;}
    .swiperpager .bg{height:70px;}
  	.mai .left .goodsImgBanner .imgShow{height:304px;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {height:70px;}
  	.mai .left .goodsImgBanner .pager div i{width:30px;height:69.72px;}
  	.mai .left .goodsImgBanner .pager .next{margin-left:498px;}
  	.mai .right .ops div {font-size: 12px;}
  	.mai .right{width:240px;}
  	.mai .rightBanner .img{height:200px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.bottomDiv{height:100px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mai{padding-top:50px;}
  	.mai .left{width:792px;}
    .swiperpager{width:792px;}
    .swiperpager .bg{height:108px;}
  	.mai .left .goodsImgBanner .imgShow{height:456px;}
    .mai .left .goodsImgBanner .gallery-thumbs .swiper-slide img {height:108px;}
  	.mai .left .goodsImgBanner .pager div i{width:40px;height:108px;}
  	.mai .left .goodsImgBanner .pager .next{margin-left:752px;}
  	.mai .right{width:360px;}
  	.mai .rightBanner .img{height:300px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.bottomDiv{height:200px;}
  }
</style>
<style>
	.mai .left .desc img{max-width: 100%;}
	.mai .left .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
	.mai .mobileDetail .desc img{max-width: 100%;}
	.mai .mobileDetail .desc p{margin:0px;display: flex;flex-direction: column;align-items: flex-start;}
</style>
