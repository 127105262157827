<template>
  <div v-loading="mainLoading">
    	<div class="goodsList">
    		<div class="content">
          <div class="userInfo" v-if="userInfo != null">
            <img v-if="userInfo.profilePicture" :src="userInfo.profilePicture"/>
            <img v-else src="../assets/img/p_upload.png"/>
            {{userInfo.nickName}}
          </div>
          <div style="color:rgba(1, 56, 139, 0.46);margin:20px 0;font-size:14px;font-weight: bold;" v-if="userInfo != null">作家紹介</div>
          <div style="font-size:14px;line-height:1.6;" v-if="userInfo != null">
            {{userInfo.writerIntroduction}}
          </div>
          <div style="color:rgba(1, 56, 139, 0.46);margin:20px 0;font-size:14px;font-weight: bold;">作家作品</div>
    			<div class="list">
    				<div class="item" v-for="(item,i) in list" :key="item.tokenId">
    					<div class="img" @click="go_detail_page(item.contentsUuid)" :style="item.imageUrl"></div>
              <div class="sales" v-if="item.isPromotion">
                {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
              </div>
    					<h5 @click="go_detail_page(item.contentsUuid)">{{item.contentTitle}}</h5>
    					<div class="price" v-if="item.isPromotion">
                <h4 style="line-height: 1;color: rgba(1, 56, 139, 0.46);-webkit-text-decoration-line: line-through;text-decoration-line: line-through;text-decoration-style: double;">¥{{numberFormat(item.price)}}</h4>
                <span style="margin: 0 5px;"> >> </span>
                <span>¥{{numberFormat(item.promotionalPrice)}}</span>
              </div>
              <div class="price" v-else>
                ¥ {{numberFormat(item.price)}}
              </div>
    					<div class="options">
    						<div class="edit" @click="addCart(item)" v-if="!item.cart"><i></i>カートへ</div>
                <div class="edit act" v-if="item.cart"><i></i>追加中</div>
    						<div @click="likeFunction(item)" :class="item.like?'heartAct':'heart'"></div>
    					</div>
    				</div>
    			</div>
    		</div>
    		<div class="content pager pc">
    			<pagination
    			  layout="prev, pager, next"
    			  :page-size="pageSize"
    			  @current-change="change_page"
    			  :total="total">
    			</pagination>
    		</div>
    		<div class="more mobile" @click="nextPage" v-if="isHaveNext">MORE</div>
    		<div class="bottomDiv"></div>
    		<div class="content paths">
    			<i class="home" @click="go_home"></i>
    			<i class="jt"></i>
    			<a>{{userInfo.nickName}}</a>
    		</div>
    	</div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Pagination } from 'element-ui'
  import { putAction,getAction } from '@/network/manage'
  import { deleteActionApi,postActionApi } from '@/network/manageApi'
  import numeral from 'numeral'
  import moment from 'moment'

  export default {
    name: 'ArtistProfile',
    components: {
        Pagination
      },
      data() {
        return {
          url: {
            list: '/home/authorContents',
            info:'/home/authorBasicInfo',
            like:'/like/like',
            cart:'/shopping-cart/shopping-cart'
          },
          list:[],
          hl:1,
          uuid:'',
          page:1,
          userInfo:{},
          isHaveNext:false,
          mainLoading:true,
          total:0,
          pageSize:8
        }
      },
      computed: {
        ...mapGetters([
          'token'
        ])
      },
      created(){
        if(this.$route.query.id == null) {
            this.go_home()
        }
        this.uuid = this.$route.query.id
        this.loadInfo();
        this.listData();
      },
      methods:{
        likeFunction(item){
          if(item.like){
            this.unlike(item)
          }else{
            this.like(item)
          }
        },
        like(item){
          const that = this;
          postActionApi(this.url.like+'/'+item.contentsUuid).then((res) => {
            if (res.code == 200) {
              that.$set(item,'like',true)
            } else {
              if(res.code == 40023) {
                that.$message.warning("自分の作品は購入できません");
              } else {
                that.$message.error(res.message);
              }
            }
          })
        },
        addCart(item){
          const that = this;
          postActionApi(this.url.cart+'/'+item.contentsUuid).then((res) => {
            if (res.code == 200) {
              item.cart = true
              that.$store.dispatch('UpdateCartNumber');
            } else {
              if(res.code == 40023) {
                that.$message.warning("自分の作品は購入できません");
              } else {
                that.$message.error(res.message);
              }
            }
          })
        },
        unlike(item){
          const that = this;
          deleteActionApi(this.url.like+'/'+item.contentsUuid).then((res) => {
            if (res.code == 200) {
              that.$set(item,'like',false)
            } else {
              that.$message.error(res.message);
            }
          })
        },
        go_page(url){
          this.$router.push({path:url,query:{}})
        },
        go_detail_page(id){
          this.$router.push({path:'contentsHome',query:{id:id}})
        },
        go_home(){
          this.$router.push({path:'home',query:{}})
        },
        change_page(e){
          this.list=[];
          this.page = e;
          this.listData();
        },
        numberFormat(txt){
          return numeral(txt).format('0,0')
        },
        nextPage(){
          this.page = this.page + 1;
          this.listData();
        },
        loadInfo(){
          const that = this;
          that.mainLoading = true;
          getAction(this.url.info,{uuid:this.uuid}).then((res) => {
            if (res.code == 200) {
              that.userInfo = res.result
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
          }).finally(res => {
            that.mainLoading = false;
          })
        },
        async listData(){
          let time = await getAction("/login/getTime")
          let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
          const that = this;
          that.mainLoading = true;
          getAction(this.url.list,{pageNo:this.page,uuid:this.uuid}).then((res) => {
            if (res.code == 200) {
              var obj = res.result.records;
              for(var i = 0;i<obj.length;i++){
                obj[i].imageUrl = "background-image:url('"+obj[i].imageUrl+"')";
                obj[i].isPromotion = false
                if(obj[i].promotionActive) {
                  if(obj[i].promotionStartDatetime && obj[i].promotionEndDatetime) {
                    let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotionStartDatetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                    let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotionEndDatetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                    if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                      obj[i].isPromotion = true
                      obj[i].discount = Math.floor(100 - (obj[i].promotionalPrice/obj[i].price)*100)
                      obj[i].pEndTimeShow = moment(obj[i].promotionEndDatetime).format('YYYY.MM.DD')
                    }
                  }
                }
              }
              console.log(obj);

              that.list = that.list.concat(obj);
              that.total = res.result.total;
              if(res.result.current < res.result.pages)
                that.isHaveNext = true;
              else
                that.isHaveNext = false;
            } else {
              that.$message.error(res.message);
            }
          }).catch(res => {
          }).finally(res => {
            that.mainLoading = false;
          })
        }
      }
    }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .sales{background-color: rgba(236, 34, 34, 0.7);font-size:14px;color:#ffffff;position: absolute;padding:4px 10px;display: flex;align-items: center;}
  .sales span{font-size:12px;margin-left:10px;}
  .sales h5{font-weight: normal;cursor: pointer;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .goodsList .tool{display: flex;align-items: center;color:#888888;justify-content: space-between;}
  .goodsList .tool a{background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right center;padding-right:20px;}
  .goodsList .tool div{display: flex;}
  .goodsList .tool div span{cursor: pointer;}
  .goodsList .tool div .act{color:#00C999;}
  .goodsList .tool .addOption{cursor: pointer;color: #1192CA;padding-left:30px;background-image: url(../assets/img/addBlue.png);background-repeat: no-repeat;background-position: left top 3px;}
  .goodsList .list{display: grid;}
  .goodsList .list .item{background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .zz{background: rgba(0, 0, 0, 0.4);position: absolute;}
  .goodsList .list .item .zz .zzCon{display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .goodsList .list .item .zz p{color:#ffffff;font-size:15px;margin-top:10px;}
  .goodsList .list .item .img{width:100%;cursor: pointer;background-position: center;background-size: cover;display: flex;justify-content: flex-end;align-items: flex-start;padding:7px 12px;}
  .goodsList .list .item .img .tg{background: rgba(255, 255, 255, 0.7);border-radius: 20px;font-size:12px;padding:2px 16px;color:#4A4A4A;}
  .goodsList .list .item h5{font-weight: normal;cursor: pointer;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{margin:0 16px;height:41px;display: flex;align-items: center;}
  .goodsList .list .item .options{border-top:1px solid #F0F0F0;padding:16px;display: flex;justify-content: space-between;align-items: center;}
  .goodsList .list .item .options .heart{cursor: pointer;width:21px;height:21px;padding:0px;margin:0px;background-image: url(../assets/img/heart.png);background-repeat: no-repeat;background-position: right center;background-size: 100%;}
  .goodsList .list .item .options .heartAct{cursor: pointer;width:21px;height:21px;padding:0px;margin:0px;background-image: url(../assets/img/liked.png);background-repeat: no-repeat;background-position: right center;background-size: 100%;}
  .goodsList .list .item .options .edit{cursor: pointer;font-size:15px;display: flex;align-items: center;line-height:1;}
  .goodsList .list .item .options .edit i{width:28px;height:28px;flex-shrink: 0;background-image: url(../assets/img/cart.png);background-position: center;background-repeat: no-repeat;margin-right:10px;background-size: 20px;}
  .goodsList .list .item .options .act{color:rgba(56, 192, 206, 1);}
  .goodsList .list .item .options .act i{background-image: url(../assets/img/cart_act.png);}
  .pager{display: flex;justify-content: center;align-items: center;margin-top:50px !important;}
  .pager i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;margin:0px 20px;}
  .pager .lastPage{background-image: url(../assets/img/right.png);transform: rotate(180deg);cursor: pointer;}
  .pager .nextPage{background-image: url(../assets/img/right.png);cursor: pointer;}
  .pager a{width:18px;height:18px;line-height:18px;color:#888888;margin:0px 15px;cursor: pointer;}
  .pager .act{color:#000000;}
  .title{line-height: 80px;font-size:20px;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:140px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;}
  .userInfo{display: flex;align-items: center;color:rgba(56, 192, 206, 1);font-size:18px;margin-top:30px;}
  .userInfo img{width: 70px;height:70px;border:1px solid #d0d0d0;border-radius: 50%;margin:0 20px 0 0;}
  @media (max-width: 800px) {
    .userInfo{flex-direction: column;}
    .userInfo img{margin:0 0 20px 0;}
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .content{margin:0 16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:13px;height:40px;}
    .goodsList .list .item .price{padding: 0px}
    .goodsList .list .item .price h4{font-size:13px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:15px;}
    .goodsList .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .goodsList .tool div{width:100%;justify-content: space-between;}
    .goodsList .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .goodsList .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
    .goodsList .mobile{padding:30px 0 60px 0;color:#888888;background-image: url(../assets/img/right.png);background-repeat: no-repeat;background-position: right top 37px;padding-right:16px;}
    .goodsList .list .item .zz{width:44.2vw;height:24.25vw;}
    .goodsList .list .item .zz .zzBG{height:143.5px;position: absolute;width:44.2vw;background: rgba(0, 0, 0, 0.4);padding:0px;margin:0px;}
    .goodsList .list .item .zz .zzCon{height:24.25vw;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .goodsList .list .item .options .edit{font-size:13px;}
    .goodsList .list .item .options .edit i{width:24px;height:24px;margin-right:10px;}
    .paths {padding: 20px 0;}
    .emptyInfo{padding:20vw 0;}
    .more{height:30px;background: #00C999;color:#ffffff;border-radius: 35px;width: 148px;margin:30px auto;display: flex;justify-content: center;align-items: center;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
    .content{width:800px;margin:auto;}
    .goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
    .goodsList .list .item .img{width:191px;height:105px;}
    .goodsList .list .item .price i{width:30px;height:30px;}
    .goodsList .list .item h5{font-size:15px;height:40px;}
    .goodsList .list .item .price h4{font-size:14px;}
    .goodsList .list .item .price span{font-size:14px;}
    .goodsList .list .item .zz{width:191px;height:245px;}
    .goodsList .list .item .zz .zzCon{height:105px;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .bottomDiv{height:100px;}
    .goodsList .tool{margin-top:0px;height:50px;margin-top:20px;}
    .goodsList .tool div span{line-height:50px;margin-right:30px;}
    .emptyInfo{padding:80px 0;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;font-size: 15px;}
  	.goodsList .list .item .zz{width:285px;height:319px;}
  	.goodsList .list .item .zz .zzCon{height:156px;}
  	.goodsList .list .item .zz img{height:60px;}
    .goodsList .tool{margin-top:0px;height:70px;margin-top:40px;}
    .goodsList .tool div span{line-height:70px;margin-right:50px;}
    .bottomDiv{height:100px;}
    .emptyInfo{padding:80px 0;}
  }
</style>
