import Vue from 'vue'
import Router from 'vue-router'
import Home from 'views/Home'
import Register from 'views/Register'
import RegisterFinish from 'views/RegisterFinish'
import Explorer from 'views/Explorer'
import ContentsHome from 'views/ContentsHome'
import CollectionFavorited from 'views/CollectionFavorited'
import Page from 'views/Page'
import Prifile from 'views/Prifile'
import Upload from 'views/Upload'
import UploadEdit from 'views/UploadEdit'
import Cart from 'views/Cart'
import Apply from 'views/Apply'
import Contract from 'views/Contract'
import PrifileEdit from 'views/PrifileEdit'
import SalesManage from 'views/SalesManage'
import SalesData from 'views/SalesData'
import SalesJs from 'views/SalesJs'
import PurchaseHistory from 'views/PurchaseHistory'
import Login from 'views/Login'
import FindId from 'views/FindId'
import FindPw from 'views/FindPw'
import ResetPw from 'views/ResetPw'
import Signup from 'views/Signup'
import GoogleLogin from 'views/GoogleLogin'
import LineLogin from 'views/LineLogin'
import ActiveLine from 'views/ActiveLine'
import ArtistProfile from 'views/ArtistProfile'
import PageQuestion from 'views/PageQuestion'


Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/explorer',
    component: Explorer
  },
  {
    path: '/contentsHome',
    component: ContentsHome
  },
  {
    path: '/like',
    component: CollectionFavorited,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/upload',
    component: Upload
  },
  {
    path: '/uploadEdit',
    component: UploadEdit
  },
  {
    path: '/cart',
    component: Cart
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/registerFinish',
    component: RegisterFinish
  },
  {
    path: '/page/:linkurl',
    name: "page",
    component: Page
  },
  {
    path: '/prifile',
    component: Prifile
  },
  {
    path: '/apply',
    component: Apply
  },
  {
    path: '/contract',
    component: Contract
  },
  {
    path: '/prifileEdit',
    component: PrifileEdit
  },
  {
    path: '/salesManage',
    component: SalesManage
  },
  {
    path: '/salesData',
    component: SalesData
  },
  {
    path: '/salesJs',
    component: SalesJs
  },
  {
    path: '/purchaseHistory',
    component: PurchaseHistory
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/findId',
    component: FindId
  },
  {
    path: '/findPw',
    component: FindPw
  },
  {
    path: '/resetPw',
    component: ResetPw
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/googleLogin',
    component: GoogleLogin
  },
  {
    path: '/lineLogin',
    component: LineLogin
  },
  {
    path: '/activeline',
    component: ActiveLine
  },
  {
    path: '/artistProfile',
    component: ArtistProfile
  },
  {
    path: '/question',
    component: PageQuestion
  },
  {
    path: '/404',
    component: () => import('views/exception/404')
  },
  {
    path: '*',
    redirect: '/404',
  }
]
const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior(to,from,savePosition){
    if(savePosition){
      return savePosition
    } else {
      return {x:0,y:0}
    }
  }
})
export default router
