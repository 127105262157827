<template>
  <div>
    <!-- goodsList -->
    <div class="content mai">
    	<form>
    		<h1>アップロード</h1>
    		<div class="ti">
    			作品タイトル
    			<span>必須</span>
    		</div>
    		<div class="input">
    			<input maxlength="50" v-model="item.title" @input="check_input('title')"/>
    			<span><a :class="error_title?'error':''">{{item.title.length}}</a>/24</span>
    		</div>
        <lable v-if="error_title" style="color:red;font-size: 13px;">
          {{error_title}}
        </lable>
    		<div class="ti">
    			作品説明
    			<span>必須</span>
    		</div>
    		<div class="textarea">
    			<textarea v-model="item.short_description" maxlength="150" @input="check_input('short_description')"></textarea>
    			<span><a :class="error_short_description?'error':''">{{item.short_description.length}}</a>/100</span>
    		</div>
        <lable v-if="error_short_description" style="color:red;font-size: 13px;">
          {{error_short_description}}
        </lable>
    		<div class="ti">
    			カテゴリー
    			<span>必須</span>
    		</div>
    		<div class="radio" style="margin-bottom: 20px;">
          <label v-for="(itemC,i) in categoryList" :key="i">
          	<input type="checkbox" @change="value => change_category(value, i)"/>
          	{{itemC.categoryName}}
          </label>
    		</div>
        <lable v-if="error_category" style="color:red;font-size: 13px;">
          {{error_category}}
        </lable>
    		<div class="ti">
    			作品の表紙
    			<span>必須</span>
    			<label>推奨画像サイズ：横661px、縦380px／JPGまたはPNG形式（1MBまで）</label>
    		</div>

        <el-upload
          :action="getUploadUrl"
          :headers="headers"
          :show-file-list="false"
          :multiple="false"
          accept="image/*"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <div :class="'fm' + (error_upload_image?' error_border':'')" v-loading="listLoading" :style="headimgurlBG"></div>
        </el-upload>
        <lable v-if="error_upload_image" style="color:red;font-size: 13px;">
          {{error_upload_image}}
        </lable>

    		<div class="ti">
    			サブ画像
    			<label>推奨画像サイズ：横661px、縦380px／JPGまたはPNG形式（1MBまで）(~8枚まで)</label>
    		</div>
        <div class="imgs" v-loading="listLoadingImgs">
          <div class="item" v-for="(item,i) in imgs" :key="i" v-if="i<8" v-loading="item.isLoading" :style="item.url"><span @click="removeFile(i)" class="del">×</span></div>
          <el-upload class="item" v-if="imgs.length < 8"
            :action="getUploadUrl"
            :headers="headers"
            :show-file-list="false"
            :multiple="true"
            :limit="8"
            accept="image/*"
            :on-exceed="file_exceeds"
            :on-success="handleAvatarSuccessImgs"
            :before-upload="beforeAvatarUploadImgs">
            <div class="item"></div>
          </el-upload>
        </div>
    		<div class="ti">
    			作品ファイル
    			<span>必須</span>
    			<label>zip、.skp、.clip、.png、.psd、.sut形式（1GBまで）</label>
    		</div>
    		<div class="file">
          <el-upload
            action="str"
            :show-file-list="false"
            :multiple="false"
            accept=".zip,.skp,.clip,.png,.psd,.sut"
            :http-request="uploadMainFile"
            v-if="!fileUrl"
            >
            <div class="but" v-if="!listLoadingFile">ファイルの追加</div>
          </el-upload>
          <el-progress :percentage="total_progress" v-if="listLoadingFile"></el-progress>
          <div v-if="fileUrl" style="display: flex;align-items: center;">
            <a style="text-decoration: underline;color:rgba(17, 146, 202, 1);font-size:16px;">{{contents_name}}</a>
            <i class="el-icon-close" style="color:#888888;margin-left:15px;cursor: pointer;" @click="remove_file">削除</i>
          </div>
    			<span v-if="error_upload_file" style="color:rgba(236, 34, 34, 1);font-size;13px;">{{error_upload_file}}</span>
    		</div>
    		<div class="ti">
    			ファイル拡張子選択
    			<span>必須</span>
    			<label>4つまで重複選択可能</label>
    		</div>
    		<div class="tag">
    			<span v-for="(item,i) in labels" @click="changeLabel(i)" :key="i" :class="labelsCheck[i]?'act':''">{{item}}</span>
    		</div>
        <lable v-if="error_upload_labs" style="color:red;font-size: 13px;">
          {{error_upload_labs}}
        </lable>
    		<div class="ti">
    			作品紹介文
    			<span>必須</span>
    		</div>
    		<div class="edit">
          <wang-editor v-model="item.introduction" activeHeight="400px"></wang-editor>
    		</div>
    		<div class="ti">
    			価格
    			<span>必須</span>
    			<label>0と入力の場合は無料で出品されます</label>
    		</div>
    		<div class="lineInput">
    			<div :style="error_price?'border-bottom: 1px solid red;':''">
    				<input v-model="item.price" class="lineInput" @input="change_price"/>
    				JPY
    			</div>
    		</div>
        <lable v-if="error_price" style="color:red;font-size: 13px;">
          {{error_price}}
        </lable>
        <div class="ti">
        	クレジット表記
        </div>
        <div class="input">
        	<input maxlength="80" v-model="item.support_creditor" @input="check_input('support_creditor')"/>
        	<span><a :class="error_support_creditor?'error':''">{{item.support_creditor.length}}</a>/40</span>
        </div>
        <lable v-if="error_support_creditor" style="color:red;font-size: 13px;">
          {{error_support_creditor}}
        </lable>

        <div class="ti">
        	制作時参考PC仕様
        </div>
        <div class="textarea">
        	<textarea style="height:140px;" v-model="item.notice_description" maxlength="500" @input="check_input('notice_description')"></textarea>
        	<span><a :class="error_notice_description?'error':''">{{item.notice_description.length}}</a>/400</span>
        </div>
        <lable v-if="error_notice_description" style="color:red;font-size: 13px;">
          {{error_notice_description}}
        </lable>

        <div class="btn_bj">
            <div class="blank_btn" @click="submit_form(false)" v-loading="formLoading">下書きとして保存</div>
            <div :class="validateBtn?'btn ok':'btn'" @click="submit_form(true)" v-loading="formLoading">審査用アップロード</div>
        </div>
    	</form>
    </div>
    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home"></i>
    	<i class="jt"></i>
    	<a>アップロードTOP</a>
    	<i class="jt"></i>
    	<a>アップロード</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { getAction } from '@/network/manage'
  import { getActionApi,putActionApi,postActionApi } from '@/network/manageApi'
  import { Upload } from 'element-ui'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import WangEditor from '@/components/wangEditor/WangEditor'
  import numeral from 'numeral'
  import moment from 'moment'
  export default {
    name: 'Upload',
    components: {
      elUpload:Upload,
      WangEditor,
    },
    data() {
      return {
        url: {
          category_list: '/home/searchContentsCategory',
          upload: '/file-upload/upload-public',
          create: '/contents/my-contents',
          main_upload_init: '/file-upload/upload-private-multipart/create',
          main_upload_part: '/file-upload/upload-private-multipart/multi-part-upload',
          main_upload_complete: '/file-upload/upload-private-multipart/multi-part-complete'
        },
        formLoading:false,
        headimgurl:null,
        headimgurlBG:null,
        categoryList: [],
        item:{category: 0,title:'',short_description:'',introduction:'',support_creditor:'',notice_description:''},
        fileUrl:null,
        fileSize:null,
        labels:['Sketchup','Blender','Sculptris','ZBrush','cs3o','obj/fbx','psd','ai','jpg'],
        labelsCheck:[false,false,false,false,false,false,false,false,false],
        imgs:[],
        listLoading:false,
        listLoadingImgs:false,
        listLoadingFile:false,
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        main_file_upload_key: '',
        main_file_upload_uuid: '',
        total_progress: 0,
        contents_name: '',
        error_title: '',
        error_title_status: false,
        error_short_description: '',
        error_short_description_status: false,
        error_upload_image: '',
        error_upload_image_status: false,
        error_upload_file: '',
        error_upload_file_status: false,
        error_upload_labs: '',
        error_upload_labs_status: false,
        error_price: '',
        error_price_status: false,
        error_support_creditor: '',
        error_support_creditor_status: true,
        error_notice_description: '',
        error_notice_description_status: true,
        error_category: '',
        error_category_status: false
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      getUploadUrl() {
        return process.env.VUE_APP_BASE_API + this.url.upload
      },
      validateBtn:function(){
        return this.error_title_status
        && this.error_short_description_status
        && this.error_upload_image_status
        && this.error_upload_file_status
        && this.error_upload_labs_status
        && (this.item.introduction.length > 0)
        && this.error_price_status
        && this.error_support_creditor_status
        && this.error_notice_description_status
        && this.error_category_status;
      }
    },
    created(){
      if(this.type != 1) {
        this.$router.replace('/apply').catch(err => {})
      }
      this.search_category()
    },
    methods:{
      change_category(val, i) {
        this.$set(this.categoryList[i], "checked", val.target.checked);
        this.check_category()
      },
      check_category() {
        this.error_category = ''
        this.error_category_status = false
        let un = 0
        for(let ca of this.categoryList) {
          if(ca.checked) {
            un++
          }
        }
        if(un == 0) {
          this.error_category = '必須項目です'
          this.error_category_status = false
          return;
        }
        this.error_category = ''
        this.error_category_status = true
      },
      check_input(type) {
        if(type == 'title') {
          if(this.item.title.length > 24) {
            this.error_title_status = false
            this.error_title = '文字数が上限を超えています'
          } else if(this.item.title.length == 0) {
            this.error_title_status = false
            this.error_title = '必須項目です'
          } else {
            this.error_title_status = true
            this.error_title = ''
          }
        } else if(type == 'short_description') {
          if(this.item.short_description.length > 100) {
            this.error_short_description_status = false
            this.error_short_description = '文字数が上限を超えています'
          } else if(this.item.short_description.length == 0) {
            this.error_short_description_status = false
            this.error_short_description = '必須項目です'
          } else {
            this.error_short_description_status = true
            this.error_short_description = ''
          }
        } else if (type == 'support_creditor') {
          if(this.item.support_creditor.length > 40) {
            this.error_support_creditor_status = false
            this.error_support_creditor = '文字数が上限を超えています'
          } else {
            this.error_support_creditor_status = true
            this.error_support_creditor = ''
          }
        } else if (type == 'notice_description') {
          if(this.item.notice_description.length > 400) {
            this.error_notice_description_status = false
            this.error_notice_description = '文字数が上限を超えています'
          } else {
            this.error_notice_description_status = true
            this.error_notice_description = ''
          }
        }
      },
      change_price() {
        this.error_price = ''
        if(this.item.price) {
            if(isNaN(parseInt(this.item.price))) {
                this.item.price = ''
                this.error_price = '必須項目です'
                this.error_price_status = false
                return;
            }
            this.item.price = parseInt(this.item.price).toFixed(0)
            if(this.item.price < 0) {
              this.item.price = 0
              this.error_price_status = true
              return;
            }
            if(this.item.price > 99999999999) {
              this.item.price = 99999999999
              this.error_price_status = true
              return;
            }
            this.error_price_status = true
        } else {
          this.error_price = '必須項目です'
          this.error_price_status = false
        }
      },
      remove_file() {
        let that = this
        that.$messageBox.confirm('本当に削除しますか？', 'システムメッセージ', {
          confirmButtonText: '確認',
          callback: (action, instance) => {
            if (action === 'confirm') {
              that.fileSize = null
              that.fileUrl = null
              that.contents_name = null
              that.error_upload_file = ''
              that.error_upload_file_status = false
            }
          }
        })
      },
      async uploadMainFile(fileTool) {
        if(this.listLoadingFile) {
          return;
        }
        this.error_upload_file = ''
        let fileSize = fileTool.file.size / 1024 / 1024
        const isLtM = fileSize <= 1024;
        if (!isLtM) {
          this.error_upload_file = 'ファイルのサイズは 1GB を超えることはできません。'
          this.error_upload_file_status = false
          return isLtM
        }
        this.total_progress = Math.floor(Math.random()*5) + 1;
        this.listLoadingFile = true
        let key = new Date().getTime() + "/" + moment().format('YYYYMMDD') + "/" + fileTool.file.name
        //初始化上传接口
        let initObj = await postActionApi(this.url.main_upload_init + "?key=" + key)
        if(initObj.code == 200) {
          this.main_file_upload_key = initObj.data.key
          this.main_file_upload_uuid = initObj.data.upload_uuid
          //切片文件
          const chunkSize = 1024 * 1024 * 10;
          const fileSize = fileTool.file.size;
          const chunks = Math.ceil(fileSize / chunkSize);
          const tasks = [];
          const list_parts = []
          let uploaded = 0;
          //文件切割
          for (let i = 0; i < chunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, fileSize);
            tasks.push(
              new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('file_piece', fileTool.file.slice(start, end));
                postActionApi(this.url.main_upload_part +
                "?upload_uuid=" + this.main_file_upload_uuid +
                '&key=' + this.main_file_upload_key + '&piece_number=' + (i+1), formData).then(res => {
                    if(res.code == 200) {
                      let PartNumber = res.data.piece_upload_result.PartNumber
                      let ETag = res.data.piece_upload_result.ETag.replace(/"/g,"")
                      let obj = {PartNumber, ETag}
                      list_parts.push(obj)
                      uploaded++;
                      this.total_progress = Math.floor((uploaded / chunks) * 100);
                      resolve()
                    }
                  }).catch(err => {
                    reject(err)
                  })
              })
            )
          }
          await Promise.all(tasks);
          if(uploaded == chunks) {
            let parts = list_parts.sort((a, b) => a.PartNumber - b.PartNumber)
            let params = {upload_uuid: this.main_file_upload_uuid,key:this.main_file_upload_key,list_parts: parts}
            let upload_res = await postActionApi(this.url.main_upload_complete, params)
            this.listLoadingFile = false
            this.fileSize = fileSize/1024/1024
            this.contents_name = fileTool.file.name
            this.fileUrl = upload_res.data.upload_result.Key
            this.error_upload_file_status = true
          } else {
            this.listLoadingFile = false
            this.upload_file_error = 'ネットワーク異常、再試行してください!'
            this.error_upload_file_status = false
          }
        } else {
          this.listLoadingFile = false
          this.upload_file_error = 'ネットワーク異常、再試行してください!'
          this.error_upload_file_status = false
        }
      },
      search_category() {
        const that = this;
        getAction(this.url.category_list,{}).then((res) => {
          if (res.success) {
            that.categoryList = res.result;
            for(let cate of that.categoryList) {
              cate.checked = false
            }
          }
        })
      },
      beforeAvatarUpload(file) {
        this.error_upload_image = ''
        this.error_upload_image_status = false
        this.headimgurl = null
        this.headimgurlBG = null
        const isLt2M = file.size / 1024 / 1024 <= 1;
        if (!isLt2M) {
          this.error_upload_image = 'アップロードサイズ：1MBまで!'
          this.error_upload_image_status = false
        } else {
          this.listLoading = true;
        }
        return isLt2M
      },
      handleAvatarSuccess(res, file) {
        this.headimgurl = res.data.upload_path
        this.headimgurlBG = 'background-image:url("'+res.data.upload_path+'")';
        this.error_upload_image = ''
        this.error_upload_image_status = true
        this.listLoading = false;
      },
      file_exceeds(res) {
        if(res.length > 8) {
          this.$message.warning(`最大8枚まで`);
        }
      },
      beforeAvatarUploadImgs(file) {
        const isLt2M = file.size / 1024 / 1024 <= 1;
        if (!isLt2M) {
          this.$message.error('アップロードサイズ：1MBまで!')
          return isLt2M;
        }
        if(this.imgs.length >= 8){
          return false;
        } else {
          this.imgs = this.imgs.concat([{url:'',isLoading:true}]);
          return true;
        }
      },
      handleAvatarSuccessImgs(res, file) {
        const loadingImg = this.imgs.filter(img => img.isLoading)[0];
        this.$set(loadingImg, 'url', `background-image:url('${res.data.upload_path}')`);
        this.$set(loadingImg, 'rUrl', `${res.data.upload_path}`);
        this.$set(loadingImg, 'isLoading', false);
      },
      removeFile(i){
        this.imgs.splice(i,1);
      },
      changeLabel(i){
        if(!this.labelsCheck[i]){
          const loadingImg = this.labelsCheck.filter(label => label);
          if(loadingImg.length > 3){
            return;
          }
        }
        this.$set(this.labelsCheck, i, !this.labelsCheck[i]);
        const loadingImg = this.labelsCheck.filter(label => label);
        if(loadingImg.length == 0){
          this.error_upload_labs = '必須項目です'
          this.error_upload_labs_status = false
          return;
        }
        this.error_upload_labs = ''
        this.error_upload_labs_status = true
      },
      validateForm(){
        //check title
        this.check_input('title')
        this.check_category()
        this.check_input('short_description')
        this.check_input('support_creditor')
        this.check_input('notice_description')
        if(!this.headimgurl) {
          this.error_upload_image = '必須項目です'
          this.error_upload_image_status = false
        }
        if(!this.fileUrl) {
          this.error_upload_file = '必須項目です'
          this.error_upload_file_status = false
        }
        const loadingImg = this.labelsCheck.filter(label => label)
        if(loadingImg.length == 0) {
          this.error_upload_labs = '必須項目です'
          this.error_upload_labs_status = false
        }
        if(!this.item.price) {
          this.error_price = '必須項目です'
          this.error_price_status = false
        }
      },
      validateForm_temporary(){
        let flag = true
        if(this.error_upload_image) {
          this.error_upload_image = ''
        }
        if(this.error_upload_file) {
          this.error_upload_file = ''
        }
        if(this.error_upload_labs) {
          this.error_upload_labs = ''
        }
        this.check_input('title')
        if(!this.error_title_status) {
          flag = false;
        }
        if(this.item.short_description == '') {
          this.error_short_description = ''
        } else {
          if(this.error_short_description) {
            flag = false;
          }
        }
        if(this.error_support_creditor) {
          flag = false;
        }
        if(this.error_notice_description) {
          flag = false;
        }
        if(!this.item.price) {
          this.error_price = '必須項目です'
          this.error_price_status = false
          flag = false;
        }
        return flag;
      },
      submit_form(for_submit){
        const that = this;
        if(this.formLoading) {
          return;
        }
        if(for_submit) {
          that.validateForm();
          if(!that.validateBtn){
            return;
          }
        } else {
          if(!that.validateForm_temporary()){
            return;
          }
        }
        this.formLoading = true;
        let formData = Object.assign({}, this.item)
        formData.for_submit = for_submit
        formData.thumbnail_image = this.headimgurl
        formData.contents_name = this.contents_name
        formData.contents_url_key = this.fileUrl
        formData.contents_size = parseInt(this.fileSize)
        formData.price = parseInt(formData.price)
        var las = [];
        for(var i = 0;i<this.labels.length;i++){
          if(this.labelsCheck[i])
            las[las.length] = this.labels[i];
        }
        formData.contents_extensions = las;
        var imgs = [];
        for(var i = 0;i<this.imgs.length;i++){
          if(i<8){
            imgs[imgs.length] = this.imgs[i].rUrl
          }
        }
        let category = []
        for(let cate of this.categoryList) {
          if(cate.checked) {
            category.push(cate.categoryId)
          }
        }
        formData.category = category
        formData.sub_images = imgs;
        postActionApi(this.url.create,formData).then((res) => {
          that.formLoading = false;
          if (res.code == 200) {
            that.$message.success(for_submit?"アップロードに成功しました":"保存に成功しました");
            that.$router.replace('/salesManage')
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.formLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select,textarea{outline:none;}
  a{text-decoration: none;}
  .error {color:red !important;}
  .error_border {border: 1px solid red !important;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .mai{display: flex;flex-direction: column;}
  .mai h1{font-size:22px;margin:30px 0;font-weight: normal;}
  .mai .ti{display: flex;align-items: center;font-size:18px;margin:40px 0 20px 0;}
  .mai .ti span{color:#EC2222;font-size:13px;margin-left:20px;}
  .mai .ti label{color:#888888;font-size:14px;margin-left:30px;}
  .mai .remark{color: #888888;font-size:14px;margin:20px 0;}
  .mai .type{display:flex;margin-top:20px;}
  .mai .type span{font-size:14px;color:#4A4A4A;cursor: pointer;border: 2px solid #D0D0D0;border-radius: 20px;height:36px;width:150px;display: flex;justify-content: center;align-items: center;margin-right:30px;}
  .mai .type .act{border:2px solid #00C999;}
  .mai .goodDiv{margin:40px 0;}
  .mai .input{display: flex;align-items: flex-end;}
  .mai .input input{width:100%;height:40px;border: 1px solid #D0D0D0;border-radius: 2px;padding-left:16px;}
  .mai .input span{flex-shrink: 0;color:#888888;width:50px;text-align: right;}
  .mai .input span a{color:#000000;}
  .mai .textarea{display: flex;align-items: flex-end;}
  .mai .textarea textarea{width:100%;height:70px;border: 1px solid #D0D0D0;border-radius: 2px;padding:10px 16px;}
  .mai .textarea span{flex-shrink: 0;color:#888888;width:50px;text-align: right;}
  .mai .textarea span a{color:#000000;}
  .mai .radio{display: grid;grid-template-columns: 20% 20% 20% 20% 20%;grid-row-gap: 20px;}
  .mai .radio label{display: flex;align-items: center;font-size:14px;cursor: pointer;}
  .mai .radio input{margin-right:10px;appearance: none;position: relative;outline: none;}
  .mai .radio input:before {content: "";width: 24px;height: 24px;border: 1px solid #D0D0D0;display: inline-block;border-radius: 50%;vertical-align: middle;}
  .mai .radio input:checked:before {content: "";width: 24px;height: 24px;background-color: #000000;border: 1px solid #000000;display: inline-block;border-radius: 50%;}
  .mai .radio input:checked:after {content: "";width: 10px;height: 10px;text-align: center;background: #ffffff;border-radius: 50%;display: block;position: absolute;top: 8px;left: 8px;}
  .mai .fm{background-position: center;background-size: cover;height:234px;width:406px;background-image: url(../assets/img/noImg.jpg);}
  .mai .imgs{display: grid;grid-row-gap: 10px;grid-column-gap: 10px;}
  .mai .imgs .item{width:100%;background-image: url(../assets/img/noImg.jpg);background-position: center;background-size: cover;display: flex;justify-content: flex-end;}
  .mai .imgs .item .del{width:20px;height:20px;cursor: pointer;background-color: #C4C4C4;color:#ffffff;display: flex;align-items: center;justify-content: center;font-size:18px;}
  .mai .file .but{background: rgba(236, 249, 251, 1);border-radius: 2px;height:46px;width:200px;display: flex;justify-content: center;align-items: center;background-image: url(../assets/img/paper.png);background-repeat: no-repeat;background-position: left 20px center;color:rgba(56, 192, 206, 1);font-size: 15px;}
  .mai .file span{color:#00C999;font-size:13px;margin-top:10px;}
  .mai .tag{display: flex;flex-wrap: wrap;}
  .mai .tag .act{background: rgba(56, 192, 206, 1);color: #ffffff;}
  .mai .tag span{background: #F7F7F7;cursor: pointer;border-radius: 2px;margin:0 20px 10px 0;padding:6px 16px;color:#4A4A4A;}
  .mai .lineInput{display: flex;align-items: center;font-size:14px;}
  .mai .lineInput div{display: flex;align-items: center;border-bottom:1px solid #000000;border-top:1px solid #ffffff;font-size:15px;height:40px;width:260px;margin-right:10px;}
  .mai .lineInput div input{border:none;width:100%;font-size:15px;}
  .mai .an{align-items: flex-start;}
  .mai .an img{margin-left:30px;}
  .mai .radioInline{display: flex;}
  .mai .radioInline label{margin-right:30px;cursor: pointer;}
  .mai .select{width:100%;}
  .mai .btn_bj {display: flex;margin: auto;width:600px;}
  .mai .blank_btn{margin:80px auto 0 auto;width:260px;height:52px;color:#000000;background: #ffffff;border-radius: 2px;font-size:15px;display: flex;justify-content: center;align-items: center;border:1px solid #d0d0d0;cursor: pointer;}
  .mai .btn{margin:80px auto 0 auto;width:260px;height:52px;color:#ffffff;background: #D0D0D0;border-radius: 2px;font-size:15px;display: flex;justify-content: center;align-items: center;}
  .mai .ok{background: rgba(56, 192, 206, 1);cursor: pointer;}
  .goodDiv .conDiv{display: grid;}
  .goodDiv .til{display: flex;align-items: center;justify-content: space-between;}
  .goodDiv .til input{border:none;border-bottom:1px solid #F0F0F0;width:300px;height:36px;background-image: url(../assets/img/search.png);background-repeat: no-repeat;background-position: right center;}
  .goodDiv .til input::-webkit-input-placeholder{color:#D0D0D0;font-size:15px;}
  .goodDiv .conDivs{width:100%;overflow-x: hidden;padding-bottom:10px;}
  .goodDiv .conDivEmpty{display: flex;justify-content: center;align-items: center;width:100%;font-size:20px;color: #888888;text-decoration-line: underline;}
  .goodDiv .conDivs::-webkit-scrollbar {width : 2px;}
  .goodDiv .conDivs::-webkit-scrollbar-thumb {border-radius   : 10px;background-color: #C4C4C4;}
  .goodDiv .conDivs::-webkit-scrollbar-track {box-shadow:inset 0 0 5px #ffffff;background:#ffffff;border-radius: 10px;}
  .goodDiv .conDiv .item{background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodDiv .conDiv .item .img{width:100%;background-position: center;background-size: cover;}
  .goodDiv .conDiv .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodDiv .conDiv .item .info{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodDiv .conDiv .item .info .headImg{height:30px;width:30px;margin-right:10px;border-radius: 50%;border: 1px solid #D0D0D0;background-size: cover;background-position: center;}
  .goodDiv .conDiv .item .info span{color: #4A4A4A;font-size:15px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;}
  .goodDiv .conDiv .act .zzDiv{background: #00C999;opacity: 0.2;position: relative;}
  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
  	.mai h1{font-size:18px;margin:20px 0;}
  	.mai .ti{font-size:13px;margin:30px 0 20px 0;flex-wrap: wrap;width:100%;}
  	.mai .ti span{font-size:12px;margin-left:10px;}
  	.mai .ti label{font-size:12px;margin-left:0;margin-top:10px;width:100%;}
  	.mai .remark{font-size:13px;margin:20px 0;line-height:1.8;}
  	.mai .input{flex-direction: column;align-items: flex-start;}
  	.mai .input span{font-size:13px;text-align: left;margin-top:10px;}
  	.mai .textarea{flex-direction: column;align-items: flex-start;}
  	.mai .textarea span{font-size:13px;text-align: left;margin-top:10px;}
  	.mai .radio{grid-template-columns: 50% 50%;grid-row-gap: 20px;}
  	.mai .radio label{font-size:13px;}
  	.mai .radio input{margin-right:5px;transform: scale(0.8);}
  	.mai .fm{height:53vw;width:92vw;}
  	.mai .imgs{display: flex;flex-wrap: wrap;justify-content: space-between;grid-row-gap: 2vw;grid-column-gap: 0px;}
  	.mai .imgs .item{width:45vw;height:25.94vw;margin-bottom:0px;}
    .mai .imgs .item div{height:25.94vw;width:45vw;}
  	.mai .file .but {height: 40px;width: 160px;background-position: left 16px center;justify-content: flex-start;padding-left:36px;font-size: 13px;}
  	.mai .tag span{margin:0 16px 10px 0;padding:6px 10px;font-size: 14px;}
  	.mai .lineInput{font-size:12px;}
  	.mai .lineInput div{font-size:13px;height:30px;width:200px;}
  	.mai .blank_btn {margin: 50px auto 0 auto;width: 100%;height: 52px;}
    .mai .btn {margin: 10px auto 0 auto;width: 100%;height: 52px;}
  	.mai .select div select {padding: 10px 0;}
  	.mai .select{flex-wrap: wrap;}
  	.mai .select div {width:80vw;}
  	.mai .select span {margin:0 0 0 20px;}
  	.mai .select div select:nth-child(2) {width: 40%;margin: 0 10px;}
  	.mai .radioInline {flex-wrap: wrap;}
  	.mai .radioInline label {width:23vw;margin-right:0px;}
  	.mai .an h5{padding-top:6px;font-weight: normal;}
  	.paths {padding: 20px 0;}
  	.bottomDiv {height: 30px;}
  	.menu_item_end{border-bottom: none;}
  	.menu_header_name {border-bottom: none;}
  	.goodDiv .til{width:100%;}
  	.goodDiv .til .ti{margin: 20px 0 !important;}
  	.goodDiv .til input{width:40vw;height:28px;font-size:12px;}
  	.goodDiv .til input::-webkit-input-placeholder{font-size:12px;}
  	.goodDiv{display: flex;flex-direction: column;align-items: center;}
  	.goodDiv .til{margin-bottom:20px;font-size:16px;}
  	.goodDiv .conDivs{height:400px;overflow-y: auto;padding-bottom:0px;}
  	.goodDiv .conDiv{display: flex;flex-wrap: wrap;justify-content: space-between;}
  	.goodDiv .conDiv .item{width:44.2vw;margin-bottom:20px;}
  	.goodDiv .conDiv .item .img{width:44.2vw;height:24.25vw;}
  	.goodDiv .conDiv .item h5{font-size:14px;height:40px;}
    .goodDiv .conDiv .act .img{margin-top:calc(-24.25vw - 100px);}
    .goodDiv .conDiv .act .zzDiv{height:calc( 24.25vw + 100px);}
  	.content{margin:0 4vw;}
    .mai .btn_bj {display: flex;flex-direction:column;width:100%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.mai .imgs{grid-template-columns: 152px 152px 152px 152px 152px;}
  	.mai .imgs .item{height:87.6px;}
    .mai .imgs .item div{height:87.6px;width:152px;}
  	.goodDiv .conDivs{height:210px;overflow-y: auto;}
  	.goodDiv .conDivEmpty{height:210px;}
  	.goodDiv .conDiv{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
  	.goodDiv .conDiv .item .img{width:191px;height:105px;}
  	.goodDiv .conDiv .item h5{font-size:14px;height:40px;}
  	.goodDiv .conDiv .act .img{margin-top:-205px;}
  	.goodDiv .conDiv .act .zzDiv{height:205px;}
  	.bottomDiv{height:100px;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.mai .imgs{grid-template-columns: 232px 232px 232px 232px 232px;}
    .mai .imgs .item div{height:133.7px;width:232px;}
  	.mai .imgs .item{height:133.7px;}
  	.goodDiv .conDivs{height:280px;overflow-y: auto;}
  	.goodDiv .conDivEmpty{height:280px;}
  	.goodDiv .conDiv{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodDiv .conDiv .item .img{width:285px;height:156px;}
  	.goodDiv .conDiv .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodDiv .conDiv .item h5{height:48px;}
  	.goodDiv .conDiv .act .img{margin-top:-264px;}
  	.goodDiv .conDiv .act .zzDiv{height:264px;}
  	.bottomDiv{height:200px;}
  }
</style>
