<template>
  <div id="register">
    <div class="win" v-if="winForm">
      <div class="con">
        <div class="close"><img @click="winForm = false" src="../assets/img/close_bk.png"/></div>
        <div class="tls">作品の販売を中止しますか？</div>
        <p>中止時、当該作品は下書き保存状態に転換され</p>
        <p>販売再開時に再審査が求められます。</p>
        <div class="btn" @click="stop_contents">中止する</div>
      </div>
    </div>
    <div class="content">
      <div class="title pc">
        販売管理
      </div>
      <div class="title mobile">
        作品管理
      </div>
      <div class="con">
        <div class="menus pc">
          <span @click="tz('/salesManage')" class="act">作品管理</span>
          <span @click="tz('/salesData')">販売状況</span>
          <span @click="tz('/salesJs')">精算管理</span>
        </div>
        <div class="right">
          <div class="headInfo">
            <p>* 作品ファイルの有効期限は開始日から1年です。</p>
            <span class="pc"><img src="../assets/img/update_icon.png"/>編集</span>
            <span class="pc"><img src="../assets/img/stop_icon.png"/>中止</span>
          </div>
          <div class="list">
            <div class="item header">
              <span>アップロード日</span>
              <span>状態</span>
              <span>作品名</span>
              <span class="pc">価格</span>
              <span class="pc">お気に入り数</span>
              <span class="pc">販売数</span>
              <span class="pc">操作</span>
            </div>

            <div class="item" v-for="item,index in datas">
              <span>{{moment(item.created_datetime).format('YY.MM.DD')}}</span>
              <span class="blue" v-if="item.content_status == 'under_review'">審査中</span>
              <span class="blue" v-else-if="item.content_status == 'on_sale'">販売中</span>
              <span class="red" v-else-if="item.content_status == 'denied'">却下</span>
              <span class="gray" v-else-if="item.content_status == 'temporary_saved' || item.content_status == 'not_on_sale' || item.content_status == 'expired'">下書き保存</span>
              <span class="gray" v-else>-</span>

              <span class="special_under" v-if="item.content_status != 'under_review'" @click="go_edit(item.contents_uuid)">{{item.content_title}}</span>
              <span v-if="item.content_status == 'under_review'">{{item.content_title}}</span>

              <span class="pc">
               ¥ {{formatMoney(item.price)}}
              </span>
              <span class="pc">{{formatMoney(item.likes)}}</span>
              <span class="pc">{{formatMoney(item.sold)}}</span>
              <span class="pc">
                <img src="../assets/img/update_icon.png" @click="go_edit(item.contents_uuid)" v-if="item.content_status != 'under_review'"/>
                <img src="../assets/img/stop_icon.png" @click="show_stop_div(item)" v-if="item.content_status == 'on_sale'"/>
              </span>
            </div>

            <div class="moreBtn" v-if="!isEnd" @click="next_page" v-loading="list_loading">+ もっと見る</div>

            <div class="emptyInfo" v-if="datas.length === 0 && !list_loading">
              作品はありません
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>作品管理</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { getActionApi,putActionApi } from '@/network/manageApi'
  import moment from 'moment'
  import numeral from 'numeral'

  export default {
    name: 'SalesManage',
    components: {
    },
    data() {
      return {
        winForm:false,
        list_loading: false,
        datas: [],
        page: 1,
        page_size:20,
        isEnd: false,
        select_uuid: undefined
      }
    },
    computed: {
      ...mapGetters([
        'type'
      ]),
      formatMoney() {
        return function(price) {
          return numeral(price).format('0,0')
        }
      },
    },
    created() {
      if(this.type != 1) {
        this.$router.replace('/apply').catch(err => {})
      }
      this.load_data()
    },
    methods: {
      show_stop_div(uuid) {
        this.select_uuid = uuid
        this.winForm = true
      },
      stop_contents() {
        let that = this
        const loading = this.$loading.service()
        putActionApi("/contents/my-contents/stop-sale/" + this.select_uuid.contents_uuid).then(res => {
          if (res.code == 200) {
            this.select_uuid.content_status = 'not_on_sale'
            that.$message.success("操作が成功しました");
          } else {
            that.$message.error(res.message);
          }
        }).catch(err => {
          that.$message.error(err.message)
        }).finally(() => {
          that.winForm = false
          loading.close()
        })
      },
      next_page() {
        this.page++
        this.load_data()
      },
      moment,
      load_data() {
        if(this.list_loading) {
          return;
        }
        let that = this
        this.list_loading = true
        let param = {page:this.page, size: this.page_size}
        getActionApi("/contents/my-contents",param).then(res => {
          this.list_loading = false
          if (res.code == 200) {
            this.datas = this.datas.concat(res.data.contents)
            if(res.data.page.totalPage <= this.page) {
              this.isEnd = true
            }
          }
        })
      },
      go_edit(id) {
        this.$router.push({path:'uploadEdit', query: {id: id}})
      },
      tz(url){
        this.$router.push(url)
      }
    }
  }
</script>

<style scoped>
  *{font-size:14px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .content{min-height: calc(100vh - 215px);}
  .content .title{margin:30px 0 10px 0;font-size:26px;font-weight: bold;}
  .content .con{display: flex;}
  .con .menus{margin-right:40px;display: flex;flex-shrink: 0;flex-direction: column;}
  .con .menus span{margin:14px 0;font-size:20px;color: rgba(1, 56, 139, 0.46);cursor: pointer;}
  .con .menus span:hover{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .menus .act{color: #38C0CE;font-family: NotoSansJP-SemiBold;}
  .con .right{width:100%;}
  .con .right .headInfo{display: flex;margin-bottom:6px;}
  .con .right .headInfo p{width:100%;}
  .con .right .headInfo span{flex-shrink: 0;margin-left:20px;display: flex;align-items: center;}
  .con .right .headInfo span img{margin-right:10px;width:20px;}
  .con .right .list .item{display: flex;}
  .red{font-weight: bold;color: #EC2222;}
  .gray{font-weight: bold;color: rgba(72, 83, 100, 0.46);}
  .blue{font-weight: bold;color: #38C0CE;}
  .con .right .list .header{border-top:2px solid rgba(72, 83, 100, 0.46);border-bottom:2px solid rgba(72, 83, 100, 0.46);margin-top:10px;}
  .con .right .list .item span{width:100%;display: flex;justify-content: center;align-items: center;padding:14px 0;text-align: center;}
  .con .right .list .item span:nth-child(3){width:260%;}
  .con .right .list .item span img{margin:0 10px;cursor: pointer;width:20px;}
  .moreBtn{margin:20px auto;width:130px;height:36px;background: #ECF9FB;color:#38C0CE;border-radius: 40px;display: flex;justify-content: center;align-items: center;font-size:14px;cursor: pointer;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;display: flex;flex-direction: column;align-items: center;padding:30px;}
  .win .con .close{display: flex;justify-content: flex-end;width:100%;}
  .win .con .close img{width:20px;cursor: pointer;}
  .win .con .tls{font-size:20px;font-weight: bold;margin-top:-10px;margin-bottom:30px;}
  .win .con p{width:100%;}
  .win .con .btn{display: flex;margin-top:30px;justify-content: center;align-items: center;color:#ffffff;width:300px;height:50px;background-color: #EC2222;border-radius: 2px;margin-bottom:20px;cursor: pointer;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:50px 0;margin-bottom:20px;margin-top:50px;}
  @media (max-width: 800px) {
  	.content{margin:0 16px;min-height: calc(100vh - 323px);}
    .pc{display: none !important;}
    .content .title{margin:10px 0;text-align: center;}
    .con .right .headInfo p{text-align: center;}
    .content .title{margin:30px 0 30px 0;font-size:18px;}
    .content .btn{width:100%;}
    .win{background-color: #ffffff;top:60px;align-items: flex-start;}
    .win .con{box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);padding:20px;}
    .win .con .btn{width:100%;}
    .con .right .list .item span{padding:6px 0;}
    .con .right .list .item span:nth-child(3){width:200%;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
    .special_under {text-decoration: underline;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 800px;margin: auto;}
  }
  @media(min-width: 1200px) {
  	.content{width:1200px;margin:auto;}
    .mobile{display: none !important;}
    .nav {width: 1200px;margin: auto;}
  }
</style>
