<template>
  <div id="app">
    <hik-header :type_hiden="type"></hik-header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <hik-bottom></hik-bottom>
  </div>
</template>

<script>
  import HikHeader from "components/HikHeader"
  import HikBottom from "components/HikBottom"
  import {isMobile} from "@/utils/utils"
  export default {
    name: 'App',
    components: {
      HikHeader,
      HikBottom
    },
    data() {
      return {
        type: 0
      }
    },
    created() {
      if((this.$route.path == '/login'
      || this.$route.path == '/findId'
      || this.$route.path == '/findPw'
      || this.$route.path == '/resetPw'
      || this.$route.path == '/register'
      || this.$route.path == '/signup'
      || this.$route.path == '/registerFinish'
      || this.$route.path == '/googleLogin'
      || this.$route.path == '/lineLogin'
      || this.$route.path == '/activeline') && isMobile()) {
        this.type = 1
      } else {
        this.type = 0
      }
    },
    watch:{
      $route(to,from){
        if((to.path == '/login'
        || to.path == '/findId'
        || to.path == '/findPw'
        || to.path == '/resetPw'
        || to.path == '/register'
        || to.path == '/signup'
        || to.path == '/registerFinish'
        || to.path == '/googleLogin'
        || to.path == '/lineLogin'
        || to.path == '/activeline') && isMobile()) {
          this.type = 1
        } else {
          this.type = 0
        }
      }
    },
  }
</script>

<style>
	@import "./assets/css/base.css";
  @media (max-width: 800px) {
  	#app{padding-top:60px;}
    .el-message {
      min-width: 300px !important;
    }
    .el-message-box{
      width: 80vw !important;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	#app{padding-top:80px;}
  }
  @media(min-width: 1200px) {
  	#app{padding-top:80px;}
  }
</style>
