<template>
  <div>
    <div class="win" v-if="payLoadingForm">
      <div class="con" v-loading="true" element-loading-text="お支払い中、お待ちください" element-loading-spinner="el-icon-loading">
      </div>
    </div>
    <div class="content mai">
    	<div class="tool pc">
    		<div>
    			<span @click="go_window('/like')">お気に入り</span>
    			<span class="pc act">カート</span>
    			<span @click="go_page('purchaseHistory')">購入履歴</span>
    		</div>
    	</div>
    	<div class="table" v-loading="listLoading" v-if="list.length > 0">
    		<div class="item bord pc">
    			<i @click="select_all" class="radio" :class="{'checked': isAllChecked}"></i>
    			<div class="info">
    				<label @click="select_all">全て選択</label>
    				<span style="cursor: pointer;" class="span" :class="{'act': checkedList.length > 0}" @click="remove_select">選択項目を削除</span>
    			</div>
    			<div class="ops">価格</div>
    		</div>
        <div class="item bord mobile">
        	<i @click="select_all" class="radio" :class="{'checked': isAllChecked}"></i>
        	<div class="info">
        		<label @click="select_all">全て選択</label>
        	</div>
        	<div class="ops" style="color:#000000;" @click="remove_select">選択項目を削除</div>
        </div>
        <div style="max-height:600px;overflow: auto;">
          <div class="item" v-for="(item,i) in list" :key="i">
            <i class="radio" :class="{'checked': item.check}" @click="check_item(item)"></i>
          	<div class="info">
          		<div class="img" :style="item.thumbnail" @click="ntfHome(item.contentsUuid)"></div>
          		<div class="sp">
          			<h2 @click="ntfHome(item.contentsUuid)">{{item.contentTitle}}</h2>
          			<div class="mobile">
          				<span v-if="item.contentStatus == 1">¥ {{formatYen(item.price)}}</span>
                  <span style="color:rgb(169 169 169);" v-else>販売待機中</span>
          			</div>
          		</div>
          	</div>
          	<div class="ops pc">
          		<h2 v-if="item.contentStatus == 1">¥ {{formatYen(item.price)}}</h2>
              <h2 style="color:rgb(169 169 169);" v-else>販売待機中</h2>
          	</div>
          </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top:20px;font-size:13px;" class="pc">
          <div>小計（税込）</div>
          <div style="color:rgba(56, 192, 206, 1);font-size:18px;font-weight: bold;">¥ {{formatYen(totalPrice)}}</div>
        </div>
        <div style="display: flex;align-items: center;justify-content: flex-start;margin-top:20px;font-size:13px;" class="mobile">
          <div>小計（税込）</div>
          <div style="color:rgba(56, 192, 206, 1);font-size:18px;font-weight: bold;">¥ {{formatYen(totalPrice)}}</div>
        </div>
    	</div>
      <div class="emptyInfo" v-if="list.length === 0" v-loading="listLoading">
        カートに作品がありません
      </div>

      <div v-if="list.length > 0" class="check"><input type="checkbox" @click="changeCheck"/><a href="/page/terms" style="color:rgba(56, 192, 206, 1);cursor: pointer;">購入規約</a> に同意 <a>必須</a></div>
    	<div v-if="list.length > 0" class="button pcBtn" :class="{'ok': isCanBuy}" @click="showSubmitBox()">支払いに進む</div>

    	<div class="goodsList" v-if="list.length == 0 && !listLoading">
    		<div class="til pc">オススメの作品</div>
        <div class="til mobile" style="width:100%;text-align: left;">オススメの作品</div>
    		<div class="list">
    			<div class="item" v-for="(item,i) in goodsList" :key="i" >
    				<div class="img" :style="item.thumbnail" @click="ntfHome(item.contents_uuid)"></div>
            <div class="sales" v-if="item.isPromotion">
              {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
            </div>
    				<h5 @click="ntfHome(item.contents_uuid)">{{item.content_title}}</h5>
    				<div class="price">
              <div v-if="item.isPromotion">
                <h4>¥{{formatYen(item.price)}}</h4>
                <span>¥{{formatYen(item.promotional_price)}}</span>
              </div>
              <div v-else>
              	<h2 style="font-weight: normal;">¥ {{formatYen(item.price)}}</h2>
              </div>
    					<i :class="item.like ? 'heartAct' : ''" @click="likeFunction(item)"></i>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>カート</a>
    </div>
    <form id="pay_form" :action="pay_url" method="POST">
      <input type="hidden" name="pay_method" id="pay_method"/>
      <input type="hidden" name="merchant_id" id="merchant_id"/>
      <input type="hidden" name="service_id" id="service_id"/>
      <input type="hidden" name="cust_code" id="cust_code"/>
      <input type="hidden" name="sps_cust_no"/>
      <input type="hidden" name="sps_payment_no"/>
      <input type="hidden" name="order_id" id="order_id"/>
      <input type="hidden" name="item_id" id="item_id"/>
      <input type="hidden" name="pay_item_id"/>
      <input type="hidden" name="item_name" id="item_name"/>
      <input type="hidden" name="tax"/>
      <input type="hidden" name="amount" id="amount"/>
      <input type="hidden" name="pay_type" id="pay_type"/>
      <input type="hidden" name="auto_charge_type"/>
      <input type="hidden" name="service_type" id="service_type"/>
      <input type="hidden" name="div_settele"/>
      <input type="hidden" name="last_charge_month"/>
      <input type="hidden" name="camp_type"/>
      <input type="hidden" name="tracking_id"/>
      <input type="hidden" name="terminal_type"/>
      <input type="hidden" name="success_url" id="success_url"/>
      <input type="hidden" name="cancel_url" id="cancel_url"/>
      <input type="hidden" name="error_url" id="error_url"/>
      <input type="hidden" name="pagecon_url" id="pagecon_url"/>
      <input type="hidden" name="free1"/>
      <input type="hidden" name="free2"/>
      <input type="hidden" name="free3"/>
      <input type="hidden" name="free_csv"/>
      <input type="hidden" name="request_date" id="request_date"/>
      <input type="hidden" name="limit_second"/>
      <input type="hidden" name="sps_hashcode" id="sps_hashcode"/>
      <input type="hidden" name="dtl_rowno"/>
      <input type="hidden" name="dtl_item_id"/>
      <input type="hidden" name="dtl_item_name"/>
      <input type="hidden" name="dtl_item_count"/>
      <input type="hidden" name="dtl_tax"/>
      <input type="hidden" name="dtl_amount"/>
      <input type="hidden" name="dtl_free1"/>
      <input type="hidden" name="dtl_free2"/>
      <input type="hidden" name="dtl_free3"/>
    </form>
  </div>
</template>
<script>
  import { putAction,getAction,postAction } from '@/network/manage'
  import { deleteActionApi,getActionApi,postActionApi } from '@/network/manageApi'
  import numeral from 'numeral'
  import moment from 'moment'

  export default {
    name: 'Cart',
    components: {
    },
    data() {
      return {
        url: {
          list: '/user/member/searchShoppingCart',
          cartPay: '/user/member/orderPay',
          like:'/like/like',
        },
        listLoading: false,
        list: [],
        goodsList: [],
        isCheck:false,
        orderData: {},
        payLoadingForm: false,
        pay_url: process.env.VUE_APP_PAY_URL
      }
    },
    created() {
      this.listData()
      this.loadGoodsList()
    },
    computed: {
      formatYen() {
        return function(price) {
          return numeral(price).format('0,0')
        }
      },
      isCanBuy() {
        return this.checkedList.length > 0 && this.isCheck && this.totalNotAmount == 0
      },
      checkedList() {
        return this.list.filter(item => item.check)
      },
      isAllChecked() {
        return this.list.length > 0 && this.checkedList.length === this.list.length
      },
      totalPrice() {
        return this.checkedList.reduce((val, item) =>  val + (item.contentStatus==1 ? item.price:0), 0)
      },
      totalAmount() {
        return this.checkedList.reduce((val, item) =>  val + (item.contentStatus==1 ? 1:0), 0)
      },
      totalNotAmount() {
        return this.checkedList.reduce((val, item) =>  val + (item.contentStatus!=1 ? 1:0), 0)
      }
    },
    methods: {
      go_window(path) {
        window.location.href = path
      },
      likeFunction(item){
        if(item.like){
          this.unlike(item)
        }else{
          this.like(item)
        }
      },
      like(item){
        const that = this;
        postActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',true)
          } else if(res.code == 40023) {
            that.$message.warning("自分の作品は購入できません");
          } else {
            that.$message.error(res.message);
          }
        })
      },
      unlike(item){
        const that = this;
        deleteActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',false)
          } else {
            that.$message.error(res.message);
          }
        })
      },
      async showSubmitBox() {
        if(this.isCanBuy) {
          let array = []
          for(let item of this.checkedList) {
            array.push(item.contentsBasketSaveUuid)
          }
          const loading = this.$loading.service()
          let res = await postAction(this.url.cartPay, {cart_ids: array})
          loading.close()
          if(res.result.amount == 0) {
            this.$message.success("購入に成功しました~");
            this.$router.push({path:'purchaseHistory'})
          } else {
            this.payLoadingForm = true
            this.orderData = res.result
            document.getElementById("pay_method").value = this.orderData.pay_method
            document.getElementById("merchant_id").value = this.orderData.merchant_id
            document.getElementById("service_id").value = this.orderData.service_id
            document.getElementById("cust_code").value = this.orderData.cust_code
            document.getElementById("order_id").value = this.orderData.order_id
            document.getElementById("item_id").value = this.orderData.item_id
            document.getElementById("item_name").value = this.orderData.item_name
            document.getElementById("amount").value = this.orderData.amount
            document.getElementById("pay_type").value = this.orderData.pay_type
            document.getElementById("service_type").value = this.orderData.service_type
            document.getElementById("success_url").value = this.orderData.success_url
            document.getElementById("cancel_url").value = this.orderData.cancel_url
            document.getElementById("error_url").value = this.orderData.error_url
            document.getElementById("pagecon_url").value = this.orderData.pagecon_url
            document.getElementById("request_date").value = this.orderData.request_date
            document.getElementById("sps_hashcode").value = this.orderData.sps_hashcode
            document.getElementById("pay_form").submit();
          }
        }
      },
      go_page(path) {
        this.$router.push(path)
      },
      changeCheck(e){
        this.isCheck = e.target.checked
      },
      ntfHome(id) {
        this.$router.push({path:'contentsHome', query: {id: id}})
      },
      deleteCart(cartItems) {
        const that = this
        let promiseArr = []
        cartItems.forEach((item, i) => {
          promiseArr[i] = new Promise((resolve, reject) => {
            deleteActionApi("/shopping-cart/shopping-cart/" + item.contentsUuid)
            .then(res => {
              if (res.code === 200) {
                resolve(res)
              } else {
                reject(res)
              }
            }).catch(err => {
              reject(err)
            })
          })
        })
        return Promise.all(promiseArr)
      },
      remove_select() {
        const that = this
        if(that.checkedList.length === 0) {
          that.$notification.error({title: 'システムメッセージ', message: '削除するアイテムを選択してください'})
        } else {
          that.$messageBox.confirm('本当に選択したアイテムを削除しますか？', 'ヒント', {
            confirmButtonText: '削除',
            cancelButtonText: 'キャンセル',
            callback: (action, instance) => {
              if (action === 'confirm') {
                const loading = that.$loading.service()
                that.deleteCart(that.checkedList)
                .then(res => {
                  that.listData()
                  that.$store.dispatch('UpdateCartNumber')
                })
                .catch(err => {
                  that.$message.error(err.message)
                })
                .finally(() => {
                  loading.close()
                })
              }
            }
          })
        }
      },
      select_all() {
        let state = !this.isAllChecked
        this.list.forEach(item => {
          this.$set(item, 'check', state)
        })
      },
      check_item(item) {
        this.$set(item, 'check', !item.check)
      },
      listData() {
        this.listLoading = true
        const that = this
        getAction(this.url.list).then(res => {
          if (res.success) {
            res.result.forEach(item => {
              item.thumbnail = `background-image:url('${item.imageUrl}')`
            })
            that.list = res.result
          } else {
            that.$message.error(res.message)
          }
        })
        .catch(err => {
          that.$message.error(err.message)
        })
        .finally(() => {
          that.listLoading = false
        })
      },
      async loadGoodsList() {
        const that = this;
        let time = await getAction("/login/getTime")
        let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
        getActionApi("/contents/general-contents/picked-by-manager",{size:8,page:1}).then((res) => {
          if (res.code == 200) {
            let obj = res.data.contents;
            for(let i = 0; i < obj.length; i++){
              obj[i].thumbnail = `background-image:url('${obj[i].image_url}')`
              obj[i].isPromotion = false
              if(obj[i].promotion_active) {
                if(obj[i].promotion_start_datetime && obj[i].promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    obj[i].isPromotion = true
                    obj[i].discount = Math.floor(100 - (obj[i].promotional_price/obj[i].price)*100)
                    obj[i].pEndTimeShow = moment(obj[i].promotion_end_datetime).format('YYYY.MM.DD')
                  }
                }
              }
            }
            that.goodsList = obj
          } else {
            that.$message.error(res.message)
          }
        }).catch(res => {
        })
      }
    }
  }
</script>

<style scoped>
  .radio {cursor: pointer;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .check{display: flex;margin:40px 0 20px 0;align-items: center;font-size:15px;justify-content: center;}
  .check input{width:20px;height:20px;margin-right:5px;border: 1px solid #D0D0D0;border-radius: 2px;}
  .check a{color:#EC2222;}
  .tool{display: flex;align-items: center;color:rgba(1, 56, 139, 0.46);justify-content: space-between;}
  .tool div{display: flex;font-size: 18px;}
  .tool div span{cursor: pointer;}
  .tool div .act{color:rgba(56, 192, 206, 1);font-weight: bold;border-bottom: 1px rgba(56, 192, 206, 1) solid;}
  .mai .button{height:50px;margin:20px auto 50px auto;background: #D0D0D0;border-radius: 2px;color:#ffffff;display: flex;justify-content: center;align-items: center;cursor: pointer;}
  .ok{background: rgba(56, 192, 206, 1) !important;}
  .mai h1{font-size: 20px;font-weight: normal;margin:30px 0 20px 0;}
  .table{width:100%;}
  .table .item{display: flex;align-items: center;padding:18px 0;border-bottom:1px solid #F0F0F0;}
  .table .bord i{height:30px !important;}
  .table .item i{flex-shrink: 0;height:50px;width:40px;background-image: url(../assets/img/select_mobile.png);background-position:left center;background-repeat: no-repeat;background-size: 20px;}
  .table .item .checked{background-image: url(../assets/img/select_check.png);}
  .table .item .info{width:100%;display: flex;align-items: center;}
  .table .item .info .img{width:100px;height:57px;background-size: cover;background-position: center;flex-shrink: 0;}
  .table .item .info .sp{margin-left:30px;display: flex;flex-direction: column;}
  .table .item .info .sp h2{font-weight: normal;font-size: 15px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .table .item .info .span{margin-left:30px;font-size: 13px;color:#999999;}
  .table .item .info .act{cursor: pointer;color:#000000;}
  .table .item .info label{font-size: 13px;cursor: pointer;}
  .table .item .ops{flex-shrink: 0;display: flex;flex-direction: column;align-items: flex-end;justify-content: center;color:rgba(56, 192, 206, 1);font-size: 13px;}
  .table .item .ops h2{font-size: 15px;color:#000000;}
  .table .item .ops h3{font-size: 15px;color: #888888;}
  .goodsList {margin-top: 80px;}
  .goodsList .list{display: grid;}
  .goodsList .til{font-size: 18px;text-align: center;margin-bottom:40px;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .goodsList .list .item .sales{background-color: rgba(236, 34, 34, 0.7);font-size:14px;color:#ffffff;position: absolute;padding:4px 10px;display: flex;align-items: center;}
  .goodsList .list .item .sales span{font-size:12px;margin-left:10px;}
  .goodsList .list .item h5{font-weight: normal;cursor: pointer;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{width:100%;display: flex;align-items: center;}
  .goodsList .list .item .price h4{line-height:1;color:rgba(1, 56, 139, 0.46);text-decoration-line: line-through;text-decoration-style: double;}
  .goodsList .list .item .price span{line-height:1;margin-left:5px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/like.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/liked.png);}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:50px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;border-top: 1px solid #F0F0F0;margin-top:50px;}
  .win{position: fixed;left:0;top:0;z-index:1000;width:100vw;height:100vh;background: rgba(217, 217, 217, 0.5);display: flex;justify-content: center;align-items: center;}
  .win .con{background: #FFFFFF;width:300px;height:200px;}
  .content {min-height: calc(100vh - 300px);}
  @media (max-width: 800px) {
    .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .tool div{width:100%;justify-content: space-between;}
    .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
  	.pc{display: none !important;}
  	.table{}
  	.table .item i{background-image: url(../assets/img/select_mobile.png);width:8vw;}
  	.table .item .checked{background-image: url(../assets/img/select_check.png);}
  	.table .item .info .sp{width:100%;margin-left:3vw;}
  	.table .item .info .sp div{display: flex;align-items:center;}
  	.table .item .info .sp div span{font-size:14px;width:100%;}
  	.table .item .info .sp div span a{font-size:12px;margin-left:5px;}
  	.table .item .info .sp h2{font-size:13px;margin-bottom:5px;}

    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .til{margin-bottom:20px;font-size:16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:12px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:13px;}
  	.content{margin:0 4vw;}
    .emptyInfo{padding:20vw 0;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
    .mai .button{width:380px;height:50px;}
  	.bottomDiv{height:100px;}
    .emptyInfo{padding:50px 0;}
    .tool{margin-top:0px;height:40px;margin-top:20px;}
    .tool div span{line-height:40px;margin-right:30px;}
    .nav {width: 800px;margin: auto;}

    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .til{margin-bottom:20px;font-size:16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:15px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  }
  @media(min-width: 1200px){
  	.content{width:1200px;margin:auto;}
  	.mobile{display: none !important;}
  	.mai .button{width:400px;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
  	.bottomDiv{height:200px;}
    .tool{margin-top:0px;height:40px;margin-top:40px;}
    .tool div span{line-height:40px;margin-right:50px;}
    .nav {width: 1200px;margin: auto;}
  }
</style>
