export function isMobile() {
  const u = navigator.userAgent
  const browser = {
    isMobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
    isIos: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
    isAndroid: u.indexOf('Android') > -1, //android终端或者uc浏览器
    isIPhone: u.indexOf('iPhone') > -1, //是否为iPhone
    isIPad: u.indexOf('iPad') > -1 //是否iPad
  }
  if (browser.isMobile || browser.isIos || browser.isAndroid || browser.isIPhone || browser.isIPad) {
    return true
  }
  return false
}
