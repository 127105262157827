<template>
  <div id="register">
    <div class="content">
      <div class="win mobile" v-if="isSearch">
        <div class="con">
          <div class="tot">
            期間
            <img @click="isSearch = false" src="../assets/img/close_bk.png"/>
          </div>
          <div class="winFlex">
            <label><input type="radio" :value="1" name="rad" @click="set_type(1)" checked/>過去1ヶ月</label>
            <label><input type="radio" :value="2" name="rad" @click="set_type(2)"/>過去3ヶ月</label>
            <label><input type="radio" :value="3" name="rad" @click="set_type(3)"/>過去6ヶ月</label>
            <label><input type="radio" :value="4" name="rad" @click="set_type(4)"/>過去12ヶ月</label>
            <label><input type="radio" :value="5" name="rad" @click="set_type(5)"/>期間指定</label>
          </div>
          <div class="winFlex">
            <div>
              <el-date-picker
                  style="width:100%;"
                    size="large"
                    v-model="value1"
                    unlink-panels
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="~"
                    align="right"
                    start-placeholder="開始時間を選択してください"
                    end-placeholder="終了日を選択してください"
                    :picker-options="pickerOptions"
                    @change="change_date">
                  </el-date-picker>
            </div>
          </div>
          <a class="btn" @click="search_load">表示</a>
        </div>
      </div>
      <div class="tool pc">
      	<div>
      		<span @click="go_window('/like')">お気に入り</span>
      		<span @click="go_page('cart')">カート</span>
      		<span class="pc act" @click="go_page('purchaseHistory')">購入履歴</span>
      	</div>
      </div>
      <div class="mobile menus">購入履歴</div>
      <div class="mobile searchTorbar" @click="isSearch = true"><img src="../assets/img/mobile-search-icon.png"/>絞り込み</div>
      <div class="searchDiv pc">
        <div class="ts">
          <label><input type="radio" :value="1" name="rad" @click="set_type(1)" checked/>過去1ヶ月</label>
          <label><input type="radio" :value="2" name="rad" @click="set_type(2)"/>過去3ヶ月</label>
          <label><input type="radio" :value="3" name="rad" @click="set_type(3)"/>過去6ヶ月</label>
          <label><input type="radio" :value="4" name="rad" @click="set_type(4)"/>過去12ヶ月</label>
          <label><input type="radio" :value="5" name="rad" @click="set_type(5)"/>期間指定</label>
        </div>
        <div class="ts">
          <el-date-picker
              style="width:100%;"
                size="large"
                v-model="value1"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="~"
                align="center"
                start-placeholder="開始時間を選択してください"
                end-placeholder="終了日を選択してください"
                @change="change_date">
              </el-date-picker>
          <a style="margin-left:10px;" @click="search_load">表示</a>
        </div>
      </div>
      <div class="gw_list" v-loading="list_loading">
        <div class="item header pc">
          <span>購入日</span>
          <span>購入番号</span>
          <span></span>
          <span>価格</span>
          <span></span>
        </div>

        <div class="item" v-for="item,index in datas">
          <span><a class="mobile">購入日</a>{{item.soldDatetime}}</span>
          <span><a class="mobile">購入番号</a>{{format_order(item.soldItemNumber)}}</span>
          <span>
            <img :src="item.imageUrl" class="fm"  @click="ntfHome(item.contentUuid)"  style="cursor: pointer;"/>
            <div>
              <p class="pc" @click="author_center(item.contentCreatorUuid)" style="cursor: pointer;"><img :src="item.profilePicture"/>{{item.nickName}}</p>
              <a  @click="ntfHome(item.contentUuid)"  style="cursor: pointer;">{{item.contentTitle}}</a>
              <span class="mobile">¥{{formatMoney(item.itemPrice)}}</span>
            </div>
          </span>
          <span class="pc">¥{{formatMoney(item.itemPrice)}}</span>
          <span>
            <a style="cursor: pointer;" v-if="item.donwloadStatus == 1" @click="down_file(item.contentsSoldUuid)">ダウンロード</a>
            <label class="pc" v-if="item.donwloadStatus == 1">有効期限 : ~{{moment(item.downloadExpiredDatetime).format('YYYY.MM.DD')}}</label>
            <label class="label mobile" v-if="item.donwloadStatus == 1">
              <label>有効期限</label>
              <label>{{moment(item.downloadExpiredDatetime).format('YYYY.MM.DD')}}</label>
            </label>
            <label class="pc" v-if="item.donwloadStatus === 0" style="color:rgba(236, 34, 34, 1);">満了</label>
            <label class="label mobile" v-if="item.donwloadStatus === 0">
              <label>有効期限</label>
              <label style="color:rgba(236, 34, 34, 1);">満了</label>
            </label>
          </span>
        </div>
        <div class="emptyInfo" v-if="datas.length === 0" v-loading="list_loading">
          購入履歴がありません
        </div>

      </div>
      <div class="goodsList" v-if="datas.length == 0 && !list_loading">
      	<div class="til pc">オススメの作品</div>
        <div class="til mobile" style="width:100%;text-align: left;">オススメの作品</div>
      	<div class="list">
      		<div class="item" v-for="(item,i) in goodsList" :key="i">
      			<div class="img" :style="item.thumbnail" @click="ntfHome(item.contents_uuid)"></div>
            <div class="sales" v-if="item.isPromotion">
              {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
            </div>
      			<h5 @click="ntfHome(item.contents_uuid)">{{item.content_title}}</h5>
      			<div class="price">
      				<div v-if="item.isPromotion">
      				  <h4>¥{{formatMoney(item.price)}}</h4>
      				  <span>¥{{formatMoney(item.promotional_price)}}</span>
      				</div>
      				<div v-else>
      					<h2 style="font-weight: normal;">¥ {{formatMoney(item.price)}}</h2>
      				</div>
      				<i :class="item.like ? 'heartAct' : ''" @click="likeFunction(item)"></i>
      			</div>
      		</div>
      	</div>
      </div>
    </div>

    <div class="nav paths">
    	<i class="home" @click="go_home()"></i>
    	<i class="jt"></i>
    	<a>購入履歴</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { putAction,getAction } from '@/network/manage'
  import { getActionApi,postActionApi,deleteActionApi } from '@/network/manageApi'
  import { format_code } from '@/utils/stringUtils'
  import { saveAs as FileSaver } from 'file-saver'
  import numeral from 'numeral'
  import moment from 'moment'

  export default {
    name: 'PurchaseHistory',
    components: {
    },
    data() {
      return {
        url: {
          like:'/like/like',
        },
        isSearch:false,
        list_loading: false,
        datas: [],
        type: 1,
        value1:null,
        startTime: null,
        endTime: null,
        goodsList:[],
        loading: null,
        pickerOptions: {
          shortcuts: [{
            text: '今月中に',
            onClick(picker) {
              let date = new Date()
              let new_year = date.getFullYear();
              let month = date.getMonth();
              const start = new Date(new_year, month, 1);
              picker.$emit('pick', [start, new Date()]);
            }
          }, {
            text: '本年中に',
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近3ヶ月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近6ヶ月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    computed: {
      formatMoney() {
        return function(price) {
          return numeral(price).format('0,0')
        }
      },
    },
    created() {
      this.load_data()
      this.loadGoodsList()
    },
    methods: {
      go_window(path) {
        window.location.href = path
      },
      likeFunction(item){
        if(item.like){
          this.unlike(item)
        }else{
          this.like(item)
        }
      },
      like(item){
        const that = this;
        postActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',true)
          } else if(res.code == 40023) {
            that.$message.warning("自分の作品は購入できません");
          } else {
            that.$message.error(res.message);
          }
        })
      },
      unlike(item){
        const that = this;
        deleteActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',false)
          } else {
            that.$message.error(res.message);
          }
        })
      },
      async down_file(uuid) {
        this.loading = this.$loading.service()
        let that = this
        try{
          let res = await getAction("/user/member/getUserPurchaseOrderDownloadUrl?soldUuid=" + uuid)
          if(res.success) {
            let token = res.result.token
            let jg = await getActionApi("/file-upload/get-url?token=" + token)
            if(jg.code == 200) {
              let url = jg.data.url
              let url_not_param = url.substring(0, url.lastIndexOf('?'))
              let filename = url_not_param.substring(url_not_param.lastIndexOf('/') + 1);
              let suffix = url_not_param.substring(url_not_param.lastIndexOf('.') + 1);
              if(suffix == "png") {
                const oReq = new XMLHttpRequest()
                const URL= url
                oReq.open('GET', URL, true)
                oReq.responseType = 'blob'
                oReq.onload = function() {
                  const file = new Blob([oReq.response], {
                    type: 'blob'
                  })
                  FileSaver.saveAs(file, filename)
                  that.loading.close()
                }
                oReq.onreadystatechange = function () {
                  if(oReq.status == 0) {
                    that.loading.close()
                  }
                }
                oReq.send()
              } else {
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                that.loading.close()
              }
            } else {
              that.loading.close()
              that.$message.error("ダウンロードに例外が発生しました。ページを更新して再試行します。")
            }
          } else {
            that.loading.close()
            that.$message.error("ダウンロードに例外が発生しました。ページを更新して再試行します。")
          }
        } catch(e){
          that.loading.close()
        }
      },
      ntfHome(id) {
        this.$router.push({path:'contentsHome', query: {id: id}})
      },
      author_center(id) {
        this.$router.push({path:'artistProfile', query: {id: id}})
      },
      async loadGoodsList() {
        const that = this;
        let time = await getAction("/login/getTime")
        let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
        getActionApi("/contents/general-contents/picked-by-manager",{size:8,page:1}).then((res) => {
          if (res.code == 200) {
            let obj = res.data.contents;
            for(let i = 0; i < obj.length; i++){
              obj[i].thumbnail = `background-image:url('${obj[i].image_url}')`

              obj[i].isPromotion = false
              if(obj[i].promotion_active) {
                if(obj[i].promotion_start_datetime && obj[i].promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    obj[i].isPromotion = true
                    obj[i].discount = Math.floor(100 - (obj[i].promotional_price/obj[i].price)*100)
                    obj[i].pEndTimeShow = moment(obj[i].promotion_end_datetime).format('YYYY.MM.DD')
                  }
                }
              }
            }
            that.goodsList = obj
          } else {
            that.$message.error(res.message)
          }
        }).catch(res => {
        })
      },
      set_type(t) {
        this.type = t
      },
      search_load() {
        if(this.type != 5) {
          this.value1 = null
          this.change_date(null)
        }
        this.isSearch = false
        this.load_data()
      },
      change_date(val) {
        if(val) {
          this.startTime = val[0] + " 00:00:00"
          this.endTime = val[1] + " 23:59:59"
        } else {
          this.startTime = null
          this.endTime = null
        }
      },
      moment,
      format_order(item) {
        return format_code(item, 9)
      },
      load_data() {
        let that = this
        this.list_loading = true
        let param = {startTime:this.startTime,endTime:this.endTime,type: this.type}
        getAction("/user/member/userPurchaseOrder",param).then(res => {
          this.list_loading = false
          if (res.success) {
            this.datas = res.result
          }
        })
      },
      go_page(path) {
        this.$router.push(path)
      },
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}
  .tool{display: flex;align-items: center;color:rgba(1, 56, 139, 0.46);justify-content: space-between;}
  .tool div{display: flex;font-size: 18px;}
  .tool div span{cursor: pointer;}
  .tool div .act{color:rgba(56, 192, 206, 1);font-weight: bold;border-bottom: 1px rgba(56, 192, 206, 1) solid;}
  .content{display: flex;flex-direction: column;min-height: calc(100vh - 215px);}
  .menus{display: flex;width:100%;margin:40px 0 20px 0;}
  .menus span{color: rgba(1, 56, 139, 0.46);font-size:18px;margin-right:30px;display: flex;flex-direction: column;align-items: center;}
  .menus span label{height:1px;width:50px;background-color: #ffffff;margin-top:4px;}
  .menus .act{color: #38C0CE;}
  .menus .act label{background-color: #38C0CE;}
  .searchDiv{display: flex;width:100%;justify-content: space-between;align-items: center;margin:20px 0;}
  .searchDiv .ts{width:100%;display: flex;justify-content: space-between;align-items: center;}
  .searchDiv label{margin-right:20px;display: flex;align-items: center;font-size:13px;}
  .searchDiv label input{width:20px;height:20px;margin-right:6px;}
  .searchDiv select{border: none;border-bottom:1px solid #F0F0F0;width:60px;height:36px;}
  .searchDiv a{width:60px;height:38px;color:#ffffff;background: #38C0CE;border-radius: 2px;display: flex;justify-content: center;align-items: center;flex-shrink: 0;cursor: pointer;}
  .gw_list{width:100%;margin-bottom:20px;}
  .gw_list .item{display: flex;padding:16px 0;border-bottom:1px solid #F0F0F0;}
  .gw_list .item span{width:14%;display: flex;align-items: center;justify-content: center;font-size:14px;}
  .gw_list .item span:nth-child(1){color:#888888;}
  .gw_list .item span:nth-child(2){color:#888888;}
  .gw_list .item span:nth-child(3){width:40%;display: flex;}
  .gw_list .item span:nth-child(3) .fm{width:112px;height:57px;margin-right:10px;flex-shrink: 0;}
  .gw_list .item span:nth-child(3) div{display: flex;flex-direction: column;align-items: flex-start;}
  .gw_list .item span:nth-child(3) div p{display: flex;align-items: center;color:#01388B;text-decoration: underline;}
  .gw_list .item span:nth-child(3) div p img{width:24px;height:24px;border-radius: 50%;margin-right:6px;}
  .gw_list .item span:nth-child(3) a{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:400px;text-align: left;margin-top:4px;}
  .gw_list .item span:nth-child(5){flex-direction: column;width:18%;}
  .gw_list .item span:nth-child(5) a{background: #01388B;border-radius: 2px;width:120px;height:38px;color:#ffffff;display: flex;justify-content: center;align-items: center;margin-bottom:6px;}
  .gw_list .item span:nth-child(5) label{color:#01388B;font-size:13px;}
  .gw_list .header{border-bottom:1px solid rgba(72, 83, 100, 0.46);color:#000000;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:100px 0;border-bottom:1px solid rgba(72, 83, 100, 0.46);margin-bottom:20px;}

  .goodsList {margin-top: 0px;}
  .goodsList .list{display: grid;}
  .goodsList .til{font-size: 18px;text-align: center;margin-bottom:40px;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .goodsList .list .item .sales{background-color: rgba(236, 34, 34, 0.7);font-size:14px;color:#ffffff;position: absolute;padding:4px 10px;display: flex;align-items: center;}
  .goodsList .list .item .sales span{font-size:12px;margin-left:10px;}
  .goodsList .list .item h5{font-weight: normal;cursor: pointer;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{width:100%;display: flex;align-items: center;}
  .goodsList .list .item .price h4{line-height:1;color:rgba(1, 56, 139, 0.46);text-decoration-line: line-through;text-decoration-style: double;}
  .goodsList .list .item .price span{line-height:1;margin-left:5px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/like.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/liked.png);}
  @media (max-width: 800px) {
    .emptyInfo {border-bottom:1px solid rgba(240, 240, 240, 1);border-top:1px solid rgba(240, 240, 240, 1);}
    .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .tool div{width:100%;justify-content: space-between;}
    .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
  	.content{margin:0 16px;min-height: calc(100vh - 323px);}
    .pc{display: none !important;}
    .searchTorbar{display: flex;width:100%;align-items: center;margin-bottom:30px;}
    .searchTorbar img{margin-right:6px;width:16px;height:14px;}
    .gw_list .item{flex-direction: column;border-bottom:0px solid #F0F0F0;border-top:1px solid #F0F0F0;}
    .gw_list .item span{width:100%;justify-content: space-between;}
    .gw_list .item span:nth-child(3){width:100%;order:1;margin-bottom:10px;}
    .gw_list .item span:nth-child(3) span{justify-content: flex-start;}
    .gw_list .item span:nth-child(1){order:2;margin-bottom:6px;}
    .gw_list .item span:nth-child(1) a{color:#888888;}
    .gw_list .item span:nth-child(2){order:3;margin-bottom:6px;}
    .gw_list .item span:nth-child(2) a{color:#888888;}
    .gw_list .item span:nth-child(4){order:4;}
    .gw_list .item span:nth-child(5){order:5;width:100%;}
    .gw_list .item span:nth-child(5) a{order:2;width:100%;}
    .gw_list .item span:nth-child(5) .label{display: flex;justify-content: space-between;width:100%;}
    .gw_list .item span:nth-child(5) .label label{color:#01388B;font-size:14px;margin-bottom:20px;}
    .gw_list .item span:nth-child(3) a{white-space:normal;width:100%;}
    .win{position: fixed;z-index:1000;background-color: rgba(0, 0, 0, 0.6);width:100vw;height:100vh;left:0;top:0;display: flex;align-items: flex-end;}
    .win .con{width:100vw;background-color: #ffffff;border-radius: 8px 8px 0 0;padding:20px;}
    .win .con .tot{display: flex;align-items: center;justify-content: space-between;margin-bottom:10px;}
    .win .con .tot img{width:20px;height:20px;}
    .win .con .winFlex{display: flex;flex-wrap: wrap;align-items: baseline;}
    .win .con .winFlex label{width:50%;display: flex;justify-content: flex-start;margin:10px 0;align-items: center;}
    .win .con .winFlex label input{width:20px;height:20px;margin-right:6px;}
    .win .con .winFlex div{width:95%;display: flex;}
    .win .con .winFlex div label select{border:none;border-bottom:1px solid #F0F0F0;width:100%;margin-right:20px;color:#888888;}
    .win .con .winFlex div label:nth-child(1){width:100% !important;}
    .win .con .winFlex span{color:#888888;}
    .win .con .btn{width:100%;height:46px;background: #38C0CE;color:#ffffff;border-radius: 2px;display: flex;justify-content: center;align-items: center;margin-top:30px;}
    .nav {width: 100%;padding: 50px 20px 30px 20px;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .til{margin-bottom:20px;font-size:16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:12px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
	.goodsList .list .item .price i{width:30px;height:30px;background-size: 20px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	.content{width:800px;margin:auto;}
    .searchDiv{flex-wrap: wrap;}
    .searchDiv .ts{margin-bottom:10px;}
    .searchDiv select{width:80px;}
    .gw_list .item span:nth-child(3) a{width:200px;}
    .mobile{display: none !important;}
    .tool{margin-top:0px;height:40px;margin-top:20px;}
    .tool div span{line-height:40px;margin-right:30px;}
    .nav {width: 800px;margin: auto;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .til{margin-bottom:20px;font-size:16px;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:15px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  }
  @media(min-width: 1200px) {
  	.content{width:1200px;margin:auto;}
    .mobile{display: none !important;}
    .tool{margin-top:0px;height:40px;margin-top:40px;}
    .tool div span{line-height:40px;margin-right:50px;}
    .nav {width: 1200px;margin: auto;}
    .goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
    .goodsList .list .item .img{width:285px;height:156px;}
    .goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
    .goodsList .list .item h5{height:48px;}
  }
</style>
