<template>
  <div class="bottom" v-if="type === 0">
    <div class="content main">
      <span class="copyRight">Copyright © {{year}} Metapia Inc.</span>
      <div class="remarkDiv">
        <!-- <div v-for="(item,i) in pageList" :key="i">
          <span class="link" @click="go_page_detail(item.page_path)">{{item.terms_title}}</span>
          <span class="hui" v-if=" i != pageList.length - 1">|</span>
        </div> -->
        <div>
          <span class="link" @click="go_page_detail('company')">会社概要</span>
          <span class="hui">|</span>
        </div>
        <div>
          <span class="link" @click="go_page_detail('terms')">利用規約</span>
          <span class="hui">|</span>
        </div>
        <div>
          <span class="link" @click="go_page_detail('privacypolicy')">プライバシーポリシー</span>
          <span class="hui">|</span>
        </div>
        <div>
          <span class="link" @click="go_page_detail('specifiedcommercialtransactions')">特定商取引法に基づく表記</span>
          <span class="hui">|</span>
        </div>
        <div>
          <span class="link" @click="go_page_detail('help')">利用者ガイド</span>
          <span class="hui">|</span>
        </div>
        <div>
          <span class="link" @click="go_question()">お問い合わせ</span>
        </div>
      </div>
      <div class="iconDiv" v-if="snsList != null">
        <a target="_blank" :href='snsList[0].sns_url'><img src="~assets/img/bird.png"/></a>
        <a target="_blank" :href='snsList[1].sns_url'><img src="~assets/img/find.png"/></a>
        <a target="_blank" :href='snsList[2].sns_url'><img src="~assets/img/carm.png"/></a>
        <div class="div mobile"></div>
        <select>
          <option>JP</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
  import { getActionApi } from '@/network/manageApi'
  export default {
    name: 'HikBottom',
    data() {
      return {
        url: {
          page: '/terms_of_use/',
          sns:'/page/sns'
        },
        pageList:null,
        snsList:null,
		year: ''
      }
    },
    props:{
      "type":{type:Number,default:0}
    },
    created(){
      this.loadPage();
      this.loadSns();
	  this.year = new Date().getFullYear()
    },
    methods:{
      loadPage(){
        const that = this;
        getActionApi(this.url.page,{}).then((res) => {
          if (res.code == 200) {
            that.pageList = res.data.contents;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      loadSns(){
        const that = this;
        getActionApi(this.url.sns).then((res) => {
          if (res.code == 200) {
            that.snsList = res.data;
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
          that.$message.warning(res.message);
        })
      },
      go_question() {
        this.$router.push({path:'question',query:{}})
      },
      go_page_detail(id){
        this.$router.push({
            name: "page",
            params:{
                linkurl:id
            }
        })
      },
      go_thrid_page(address){
        if(address != null && address != ''){
          window.open(address);
        }
      }
    }
  }
</script>

<style scoped>
  .bottom{background-color: #ECF9FB;border-top:1px solid #F0F0F0;}
  .bottom .main{display: flex;padding:30px 0px 60px 0px;align-items: flex-start;justify-content: space-between;}
  .bottom .main span{color:#4A4A4A;}
  .bottom .main .remarkDiv{display: flex;color:#4A4A4A;align-items: center;flex-wrap: wrap;}
  .bottom .main .remarkDiv div{display: flex;align-items: center;}
  .bottom .main .remarkDiv .link{cursor: pointer;}
  .bottom .main .copyRight{flex-shrink: 0;}
  .bottom .main .iconDiv{display: flex;align-items: center;}
  .bottom .main .iconDiv a{display: flex;align-items: center;}
  .bottom .main .iconDiv img{width:22px;height:22px;flex-shrink: 0;}
  .bottom .main .iconDiv select{font-family: 'SF UI Display bold';border:none;background-color: transparent;font-size:18px;padding-left:20px;background-image: url('../assets/img/earth.png');background-repeat: no-repeat;background-position: center left;background-size: 20px;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  	.content{margin:0 16px;}
  	.bottom .main{flex-direction: column;align-items: flex-start;}
  	.bottom .main span{margin-bottom:30px;}
  	.bottom .main .remarkDiv{flex-wrap: wrap;}
  	.bottom .main .remarkDiv span{font-size:12px;margin:6px 5px;color:#4A4A4A;}
  	.bottom .main .iconDiv{width:100%;margin-top:30px;}
  	.bottom .main .iconDiv img{margin-bottom:0px;margin-right:16px;}
  	.bottom .main .iconDiv select{font-size: 16px;}
  	.bottom .main .iconDiv .div{width:100%;}
    .bottom .main .copyRight{flex-shrink: 0;font-size: 12px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:800px;margin:auto;}
  	.bottom .main .remarkDiv{margin:0px 20px;}
  	.bottom .main .remarkDiv span{font-size:12px;margin:0 5px;}
  	.bottom .main .iconDiv img{margin:0px 5px;}
  	.bottom .main span{font-size:12px;}
  	.bottom .main .iconDiv select{margin-left:10px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.bottom .main .remarkDiv{margin:0px 80px;}
  	.bottom .main .remarkDiv span{font-size:13px;margin:0 10px;}
  	.bottom .main .iconDiv img{margin:0px 10px;}
  	.bottom .main span{font-size:13px;}
  	.bottom .main .iconDiv select{margin-left:30px;}
  }
</style>
