import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { MessageBox,Notification,Loading } from 'element-ui'

NProgress.configure({ showSpinner: false })

const whiteList = ['/404', '/register','/login','/findId','/findPw',
 '/resetPw', '/signup', '/registerFinish', '/home','/googleLogin','/lineLogin','/activeline',
  '/explorer', '/contentsHome','/artistProfile','/question']
const white_pattern = /^\/page\/{1}\w*\-*\w*$/
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (store.getters.token) {
    /* has token */
    if (to.path === '/login' || to.path === '/register') {
      next({ path: '/' })
      NProgress.done()
    } else {
      store.dispatch('UpdateCartNumber')
      if (store.getters.nickname === '') {
        store.dispatch('GetUserInfo')
        .catch(err => {
        })
        .finally(() => {
          next()
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 || white_pattern.test(to.path)) {
      next()
    } else {
      NProgress.done()
      MessageBox.confirm('ログインしてください。', 'システムメッセージ', {
        confirmButtonText: 'ログイン',
        callback: (action, instance) => {
          if (action === 'confirm') {
            next({ path: '/login', query: { redirect: to.fullPath } })
          } else {
            next(false)
          }
        }
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
