<template>
  <div>
    <!-- goodsList -->
    <div style="width:100%;height:1px;background:rgba(240, 240, 240, 1);" class="mobile"></div>
    <div class="content goodsList" v-loading="mainLoading">
    	<div class="tool pc">
    		<div>
          <span class="act">お気に入り</span>
          <span @click="go_page('cart')">カート</span>
          <span @click="go_page('purchaseHistory')">購入履歴</span>
    		</div>
    	</div>
      <div class="emptyInfo" v-if="list.length === 0">
        お気に入りが登録されておりません
      </div>

    	<div class="list" v-if="list.length > 0">
    		<div class="item" v-for="(item,i) in list" :key="i" >
    			<div class="img" :style="item.url" @click="go_detail_page(item.contents_uuid)"></div>
          <div class="sales" v-if="item.isPromotion">
            {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
          </div>
    			<h5 @click="go_detail_page(item.contents_uuid)">{{item.content_title}}</h5>
          <div class="price" v-if="item.isPromotion">
              <h4 style="line-height: 1;color: rgba(1, 56, 139, 0.46);-webkit-text-decoration-line: line-through;text-decoration-line: line-through;text-decoration-style: double;">¥{{numberFormat(item.price)}}</h4>
              <span style="margin: 0 5px;"> >> </span>
              ¥{{numberFormat(item.promotional_price)}}
          </div>
          <div class="price" v-else>
            ¥ {{numberFormat(item.price)}}
          </div>
    			<div class="options">
    				<div class="edit" v-if="!item.basket_saved" @click.stop="cart_item(item)">カートへ</div>
            <div class="edit_act" v-if="item.basket_saved">追加中</div>
    				<div class="heart" @click="unlike(item)"></div>
    			</div>
    		</div>
    	</div>

      <div class="tjList" v-if="list.length == 0 && is_show">
      	<div class="til pc">オススメの作品</div>
        <div class="til mobile" style="width:100%;text-align: left;">オススメの作品</div>
      	<div class="list">
      		<div class="item" v-for="(item,i) in goodsList" :key="i">
      			<div class="img" :style="item.thumbnail" @click="go_detail_page(item.contents_uuid)"></div>
            <div class="sales" v-if="item.isPromotion">
              {{item.discount}}%OFF <span>~ {{item.pEndTimeShow}}</span>
            </div>
      			<h5 @click="go_detail_page(item.contents_uuid)">{{item.content_title}}</h5>
      			<div class="price">
              <div v-if="item.isPromotion" style="flex-direction: row;">
                <h4>¥{{numberFormat(item.price)}}</h4>
                <span>¥{{numberFormat(item.promotional_price)}}</span>
              </div>
              <div v-else style="flex-direction: row;">
              	<h2 style="font-weight: normal;">¥ {{numberFormat(item.price)}}</h2>
              </div>
      				<i :class="item.like ? 'heartAct' : ''" @click="likeFunction(item)"></i>
      			</div>
      		</div>
      	</div>
      </div>
    </div>
    <div class="content pager pc" v-if="list.length > 0">
      <pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="change_page"
        :current-page="page"
        hide-on-single-page
        :total="total">
      </pagination>
    </div>
    <div class="more mobile" @click="nextPage" v-if="isHaveNext">MORE</div>


    <div class="bottomDiv"></div>
    <div class="content paths">
    	<i class="home" @click="go_home"></i>
    	<i class="jt"></i>
    	<a>お気に入り</a>
    </div>
  </div>
</template>

<script>
  import { Pagination } from 'element-ui'
  import { putAction,getAction,postAction } from '@/network/manage'
  import { putActionApi,getActionApi,postActionApi,deleteActionApi } from '@/network/manageApi'
  import store from '@/store'
  import numeral from 'numeral'
  import moment from 'moment'
  export default {
    name: 'CollectionFavorited',
    components: {
      Pagination
    },
    data() {
      return {
        url: {
          list: '/like/',
          cart:'/shopping-cart/shopping-cart/',
          like:'/like/like',
        },
        goodsList: [],
        list:[],
        page:1,
        isHaveNext:false,
        mainLoading:false,
        total:0,
        pageSize:16,
        is_show:false
      }
    },
    created(){
      this.listData()
    },
    methods:{
      likeFunction(item){
        if(item.like){
          this.unlike_tj(item)
        }else{
          this.like_tj(item)
        }
      },
      like_tj(item){
        const that = this;
        postActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',true)
          } else if(res.code == 40023) {
            that.$message.warning("自分の作品は購入できません");
          } else {
            that.$message.error(res.message);
          }
        })
      },
      unlike_tj(item){
        const that = this;
        deleteActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            that.$set(item,'like',false)
          } else {
            that.$message.error(res.message);
          }
        })
      },
      unlike(item){
        const that = this;
        deleteActionApi(this.url.like+'/'+item.contents_uuid).then((res) => {
          if (res.code == 200) {
            this.list = []
            this.listData()
          } else {
            that.$message.error(res.message);
          }
        })
      },
      go_page(url){
        this.$router.push({path:url,query:{}})
      },
      go_add_page(){
        this.$router.push({path:'upload',query:{}});
      },
      go_detail_page(id){
        this.$router.push({path:'contentsHome',query:{id:id}})
      },
      go_home(){
        this.$router.push({path:'home',query:{}})
      },
      change_page(e){
        this.list=[];
        this.page = e;
        this.listData();
      },
      cart_item(item){
        const that = this;
        if(this.token !== null && this.token !== ''){
          that.cart_item_function(item);
        }else{
          that.$messageBox.confirm('ログインしてください。', 'システムメッセージ', {
            confirmButtonText: 'ログイン',
            callback: (action, instance) => {
              if (action === 'confirm') {
                that.$router.push({path:'/login'})
              }
            }
          })
        }
      },
      cart_item_function(item){
        const that = this;
        const loading = this.$loading.service();
        postActionApi(that.url.cart + item.contents_uuid).then((res) => {
          if (res.code == 200) {
            item.basket_saved = true
            this.$store.dispatch('UpdateCartNumber');
          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
        }).finally(res => {
          loading.close();
        })
      },
      numberFormat(txt){
        return numeral(txt).format('0,0')
      },
      nextPage(){
        this.page = this.page + 1;
        this.listData();
      },
      async listData(){
        const that = this;
        that.mainLoading = true;
        let time = await getAction("/login/getTime")
        let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
        getActionApi("/contents/my-like",{page:this.page,size:this.pageSize}).then((res) => {
          if (res.code == 200) {
            var obj = res.data.contents;

            if(this.page != 1 && obj.length == 0) {
              this.page = 1
              this.listData()
              return;
            }

            for(var i = 0;i<obj.length;i++) {
              obj[i].url = "background-image:url('"+obj[i].thumbnail_url+"')";

              obj[i].isPromotion = false
              if(obj[i].promotion_active) {
                if(obj[i].promotion_start_datetime && obj[i].promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    obj[i].isPromotion = true
                    obj[i].discount = Math.floor(100 - (obj[i].promotional_price/obj[i].price)*100)
                    obj[i].pEndTimeShow = moment(obj[i].promotion_end_datetime).format('YYYY.MM.DD')
                  }
                }
              }

            }
            if(res.data.page.totalElements == 0) {
              this.is_show = true
            }
            that.list = that.list.concat(obj);
            that.total = res.data.page.totalElements;
            if(res.data.page.currentPage < res.data.page.totalPage)
              that.isHaveNext = true;
            else
              that.isHaveNext = false;

             if(this.page == 1 && obj.length == 0) {
               this.loadGoodsList()
               return;
             }

          } else {
            that.$message.error(res.message);
          }
        }).catch(res => {
        }).finally(res => {
          that.mainLoading = false;
        })
      },
      async loadGoodsList() {
        const that = this;
        let time = await getAction("/login/getTime")
        let now_time = new Date(Date.parse(time.result.time.replace(/-/g,  "/")))
        getActionApi("/contents/general-contents/picked-by-manager",{size:8,page:1}).then((res) => {
          if (res.code == 200) {
            let obj = res.data.contents;
            for(let i = 0; i < obj.length; i++){
              obj[i].thumbnail = `background-image:url('${obj[i].image_url}')`

              obj[i].isPromotion = false
              if(obj[i].promotion_active) {
                if(obj[i].promotion_start_datetime && obj[i].promotion_end_datetime) {
                  let promotion_start_datetime = new Date(Date.parse(moment(obj[i].promotion_start_datetime).format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  let promotion_end_datetime = new Date(Date.parse(moment(obj[i].promotion_end_datetime).add(1, "days").format('YYYY-MM-DD HH:mm:ss').replace(/-/g,  "/")))
                  if(now_time >= promotion_start_datetime && now_time <= promotion_end_datetime) {
                    obj[i].isPromotion = true
                    obj[i].discount = Math.floor(100 - (obj[i].promotional_price/obj[i].price)*100)
                    obj[i].pEndTimeShow = moment(obj[i].promotion_end_datetime).format('YYYY.MM.DD')
                  }
                }
              }

            }
            that.goodsList = obj
          } else {
            that.$message.error(res.message)
          }
        }).catch(res => {
        })
      }
    }
  }
</script>

<style scoped>
  body{background: #ffffff;overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,select{outline:none;}
  a{text-decoration: none;}
  .sales{background-color: rgba(236, 34, 34, 0.7);font-size:14px;color:#ffffff;position: absolute;padding:4px 10px;display: flex;align-items: center;}
  .sales span{font-size:12px;margin-left:10px;}
  .sales h5{font-weight: normal;cursor: pointer;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .paths{display: flex;padding:36px 0;}
  .paths i{width:18px;height:18px;background-position: center;background-repeat: no-repeat;background-size: 100%;}
  .paths .home{background-image: url(../assets/img/home.png);cursor: pointer;}
  .paths .jt{background-image: url(../assets/img/nav_right.png);margin:0 10px;}
  .paths a{font-size:13px;color:#4A4A4A;}

  .tool{display: flex;align-items: center;color:rgba(1, 56, 139, 0.46);justify-content: space-between;}
  .tool div{display: flex;font-size: 18px;}
  .tool div span{cursor: pointer;}
  .tool div .act{color:rgba(56, 192, 206, 1);font-weight: bold;border-bottom: 1px rgba(56, 192, 206, 1) solid;}

  .goodsList .list{display: grid;margin-top:20px;}
  .goodsList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;font-size: 15px;}
  .goodsList .list .item .zz{background: rgba(0, 0, 0, 0.4);position: absolute;}
  .goodsList .list .item .zz .zzCon{display: flex;flex-direction: column;align-items: center;justify-content: center;}
  .goodsList .list .item .zz p{color:#ffffff;font-size:15px;margin-top:10px;}
  .goodsList .list .item .img{width:100%;background-position: center;background-size: cover;display: flex;justify-content: flex-end;align-items: flex-start;padding:7px 12px;}
  .goodsList .list .item .img .tg{background: rgba(255, 255, 255, 0.7);border-radius: 20px;font-size:12px;padding:2px 16px;color:#4A4A4A;}
  .goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .goodsList .list .item .price div{display: flex;flex-direction: column;}
  .goodsList .list .item .price h4{line-height:1;}
  .goodsList .list .item .price span{line-height:1;color:#000000;font-size:16px;}
  .goodsList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/like.png);background-repeat: no-repeat;background-position: center;}
  .goodsList .list .item .price .heartAct{background-image: url(../assets/img/liked.png);}
  .goodsList .list .item .options{border-top:1px solid #F0F0F0;padding:16px;display: flex;justify-content: space-between;align-items: center;}
  .goodsList .list .item .options .heart{width:21px;height:21px;padding:0px;margin:0px;background-image: url(../assets/img/liked.png);background-repeat: no-repeat;background-position: right center;background-size:100%;}
  .goodsList .list .item .options .edit{padding-left:30px;background-position:left center;background-repeat: no-repeat;background-image: url(../assets/img/cart.png);font-size:15px;background-size:20px;}
  .goodsList .list .item .options .edit:hover{background-image: url(../assets/img/cart_act.png) !important;color:rgba(56, 192, 206, 1);}
  .goodsList .list .item .options .edit_act{padding-left:30px;background-position:left center;background-repeat: no-repeat;background-image: url(../assets/img/cart_act.png) !important;color:#38C0CE;font-size:15px;background-size:20px;font-weight: bold;}
  .pager{display: flex;justify-content: center;align-items: center;margin-top:50px !important;}
  .title{line-height: 80px;font-size:20px;}
  .emptyInfo{text-align: center;color:#888888;font-size:15px;padding:50px 0;border-bottom: 1px solid #F0F0F0;margin-bottom:20px;border-top: 1px solid #F0F0F0;margin-top:40px;}

  .tjList {margin-top: 80px;}
  .tjList .list{display: grid;}
  .tjList .til{font-size: 18px;text-align: center;margin-bottom:40px;}
  .tjList .list .item{cursor: pointer;background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;}
  .tjList .list .item .img{width:100%;background-position: center;background-size: cover;}
  .tjList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
  .tjList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
  .tjList .list .item .price div{width:100%;display: flex;align-items: center;}
  .tjList .list .item .price h4{line-height:1;color:rgba(1, 56, 139, 0.46);text-decoration-line:line-through;text-decoration-style: double;}
  .tjList .list .item .price span{line-height:1;margin-left:5px;}
  .tjList .list .item .price i{flex-shrink: 0;width:30px;height:30px;background-image: url(../assets/img/like.png);background-repeat: no-repeat;background-position: center;}
  .tjList .list .item .price .heartAct{background-image: url(../assets/img/liked.png);}

  @media (max-width: 800px) {
  	body{padding-top:60px;}
  	.pc{display: none !important;}
    .content{margin:0 16px;}
    .goodsList{display: flex;flex-direction: column;align-items: center;}
    .goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;margin-top:30px;}
    .goodsList .list .item .img{width:44.2vw;height:24.25vw;}
    .goodsList .list .item h5{font-size:13px;height:40px;}
    .goodsList .list .item .price{padding: 0px 12px 10px 16px}
    .goodsList .list .item .price h4{font-size:13px;line-height: 1.5;}
    .goodsList .list .item .price span{font-size:12px;}
    .tool{margin-top:10px;height:50px;margin-bottom:10px;width:100%;}
    .tool div{width:100%;justify-content: space-between;}
    .tool div span{line-height:50px;height:48px;margin-right:0px;display: flex;flex-direction: column;align-items: center;}
    .tool div .act::after{content: '';width:30px;background-color:#00C999;height:1px;position: absolute;margin-top:38px;}
    .goodsList .list .item .zz{width:44.2vw;height:24.25vw;}
    .goodsList .list .item .zz .zzBG{height:143.5px;position: absolute;width:44.2vw;background: rgba(0, 0, 0, 0.4);padding:0px;margin:0px;}
    .goodsList .list .item .zz .zzCon{height:24.25vw;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .paths {padding: 20px 0;}
    .emptyInfo{padding:20vw 0;width: 95vw;margin-top: 15px;}
    .more{height:30px;background:rgba(56, 192, 206, 1);color:#ffffff;border-radius: 35px;width: 148px;margin:30px auto;display: flex;justify-content: center;align-items: center;}
    .bottomDiv{height:70px;}
    .tjList{display: flex;flex-direction: column;align-items: center;margin-top:40px;}
    .tjList .til{margin-bottom:20px;font-size:16px;}
    .tjList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
    .tjList .list .item .img{width:44.2vw;height:24.25vw;}
    .tjList .list .item h5{font-size:14px;height:40px;}
    .tjList .list .item .price{padding: 0px 12px 10px 16px}
    .tjList .list .item .price h4{font-size:12px;line-height: 1.5;}
    .tjList .list .item .price span{font-size:15px;}
    .tjList .list .item .price i{width:40px;height:40px;background-size: 50%;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
    .content{width:800px;margin:auto;}
    .goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
    .goodsList .list .item .img{width:191px;height:105px;}
    .goodsList .list .item .price i{width:30px;height:30px;}
    .goodsList .list .item h5{font-size:14px;height:40px;}
    .goodsList .list .item .price h4{font-size:14px;}
    .goodsList .list .item .price span{font-size:14px;}
    .goodsList .list .item .zz{width:191px;height:245px;}
    .goodsList .list .item .zz .zzCon{height:105px;}
    .goodsList .list .item .zz img{height:40px;}
    .goodsList .list .item .options {padding:10px 16px;}
    .bottomDiv{height:70px;}
    .tool{margin-top:0px;height:40px;margin-top:20px;}
    .tool div span{line-height:40px;margin-right:30px;}
    .emptyInfo{padding:50px 0;}
    .tjList .list .item .price i{width:40px;height:40px;background-size: 40%;}
  }
  @media(min-width: 1200px){
  	body{padding-top:80px;}
  	.mobile{display: none !important;}
  	.content{width:1200px;margin:auto;}
  	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
  	.goodsList .list .item .img{width:285px;height:156px;}
  	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
  	.goodsList .list .item h5{height:48px;}
  	.goodsList .list .item .zz{width:285px;height:319px;}
  	.goodsList .list .item .zz .zzCon{height:156px;}
  	.goodsList .list .item .zz img{height:60px;}
    .bottomDiv{height:120px;}
    .tool{margin-top:0px;height:40px;margin-top:40px;}
    .tool div span{line-height:40px;margin-right:50px;}
    .emptyInfo{padding:50px 0;}

    .tjList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
    .tjList .list .item .img{width:285px;height:156px;}
    .tjList .list .item .price i{width:40px;height:40px;background-size: 60%;}
    .tjList .list .item h5{height:48px;}
  }
</style>
